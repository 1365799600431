Ext.namespace('edi.models');

edi.models.setModels({
	USER: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'lastName',
				type: 'string'
			},
			{
				name: 'costDecimals',
				type: 'number'
			},
			{
				name: 'eMail',
				type: 'string'
			},
			{
				name: 'phones',
				type: 'string'
			},
			{
				name: 'login',
				type: 'string'
			},
			{
				name: 'language',
				type: 'string'
			},
			{
				name: 'patronymicName',
				type: 'string'
			},
			{
				name: 'firstName',
				type: 'string'
			},
			{
				name: 'orgs'
				//type: 'object'
			},
			{
				name: 'org'
				//type: 'object'
			},
			{
				name: 'rootProperty',
				type: 'boolean'
			},
			{
				name: 'userData'
				//type: 'object'
			},
			{
				name: 'status',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'emailConfirmed',
				type: 'boolean'
			},
			{
				name: 'passLifetime'
				//type: 'array'
			},
			{
				name: 'currentPassLifetime',
				type: 'string'
			},
			{
				name: 'passChangeDate',
				type: 'number'
			},
			{
				name: 'active',
				type: 'boolean'
			}
		]
	},
	ORGANIZATION_HEADER: {
		fields: [
			{
				name: 'acc',
				type: 'string'
			},
			{
				name: 'country',
				type: 'string'
			},
			{
				name: 'addresses'
				//type: 'array'
			},
			{
				name: 'attributes'
				//type: 'object'
			},
			{
				name: 'faxes',
				type: 'string'
			},
			{
				name: 'phones',
				type: 'string'
			},
			{
				name: 'email',
				type: 'string'
			},
			{
				name: 'orgType',
				type: 'string'
			},
			{
				name: 'iln',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'factor',
				type: 'boolean'
			},
			{
				name: 'headOrg'
				//type: 'object'
			},
			{
				name: 'hasFilials',
				type: 'boolean'
			},
			{
				name: 'retailNetwork'
				//type: 'object'
			},
			{
				name: 'orgData'
				//type: 'object'
			},
			{
				name: 'paymentDebt',
				type: 'boolean'
			}
		]
	},
	ORGANIZATIONS: {
		extend: 'edi.models.ORGANIZATION_HEADER'
	},
	ORGANIZATION: {
		extend: 'edi.models.ORGANIZATION_HEADER',
		fields: [
			{
				name: 'zip',
				type: 'string'
			},
			{
				name: 'billingType',
				type: 'string'
			},
			{
				name: 'visualName',
				type: 'string',
				convert: function (val, record) {
					val = '';
					val += record.get('name');
					return val;
				}
			},
			{
				name: 'accounts'
				//type: 'array'
			},
			{
				name: 'headOrgId',
				type: 'number',
				convert: function (value, record) {
					var headOrg = record ? record.get('headOrg') : null;
					return headOrg && headOrg.id ? headOrg.id : null;
				}
			}
		]
	},
	BANK_ACCOUNT: {
		fields: [
			{
				name: 'bik',
				type: 'string'
			},
			{
				name: 'corrAccNumber',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'number',
				type: 'string'
			},
			{
				name: 'preferredOwnerName',
				type: 'string'
			},
			{
				name: 'mfo',
				type: 'string'
			},
			{
				name: 'swift',
				type: 'string'
			}
		]
	},
	SIMPLE: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			}
		]
	},
	DOCTYPES_TREE: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'leaf',
				type: 'boolean'
			},
			{
				name: 'category_id',
				type: 'number'
			},
			{
				name: 'parent_id',
				type: 'number'
			},
			{
				name: 'checked',
				type: 'boolean'
			}
		]
	},
	SIMPLE_ANY: {
		fields: [
			{
				name: 'id'
			},
			{
				name: 'name'
			}
		]
	},
	USER_AUTOCOMPLETE: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string',
				convert: function (val, record) {
					return edi.renderers.user(record.data);
				}
			}
		]
	},
	CERTIFICATE: {
		fields: [
			{
				name: 'issuer'
				//type: 'object'
			},
			{
				name: 'serial',
				type: 'string'
			},
			{
				name: 'subject'
				//type: 'object'
			},
			{
				name: 'hash',
				type: 'string'
			},
			{
				name: 'dateFrom',
				type: 'date'
			},
			{
				name: 'dateTo',
				type: 'date'
			},
			{
				name: 'dateFromParsed',
				type: 'string'
			},
			{
				name: 'dateToParsed',
				type: 'string'
			},
			{
				name: 'cert'
				//type: 'object'
			},
			{
				name: 'version',
				type: 'string'
			},
			{
				name: 'isValid',
				type: 'boolean'
			},
			{
				name: 'availableDocTypesForSignature'
				//type: 'object'
			},
			{
				name: 'algorithm'
				//type: "object"
			}
		],
		idProperty: 'serial'
	},
	USER_CERTIFICATE: {
		fields: [
			{
				name: 'data'
				//type: 'object'
			},
			{
				name: 'IssuerName',
				mapping: 'data.issuerName',
				type: 'string'
			},
			{
				name: 'SerialNumber',
				mapping: 'data.serialNumber',
				type: 'string'
			},
			{
				name: 'SubjectName',
				mapping: 'data.subjectName',
				type: 'string'
			},
			{
				name: 'Thumbprint',
				mapping: 'data.thumbprint',
				type: 'string'
			},
			{
				name: 'ValidFromDate',
				mapping: 'data.validFromDate',
				type: 'number'
			},
			{
				name: 'ValidToDate',
				mapping: 'data.validToDate',
				type: 'number'
			},
			{
				name: 'Version',
				mapping: 'data.version',
				type: 'string'
			},
			{
				name: 'user',
				mapping: 'data.user'
				//type: 'object'
			},
			{
				name: 'header'
				//type: 'object'
			},
			{
				name: 'id',
				mapping: 'header.id',
				type: 'number'
			},
			{
				name: 'state',
				mapping: 'header.state',
				type: 'string'
			},
			{
				name: 'creationDate',
				mapping: 'header.creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				mapping: 'header.modifyDate',
				type: 'number'
			},
			{
				name: 'org',
				mapping: 'header.fromOrg'
				//type: 'object'
			},
			{
				name: 'algorithm',
				//type: "object",
				mapping: 'data.algorithm'
			},
			{
				name: 'availableDocTypesForSignature',
				//type: 'object',
				mapping: 'data.availableDocTypesForSignature'
			}
		]
	},
	COMPANY_TYPE: {
		fields: [
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'id',
				type: 'string'
			}
		]
	},
	ADDRESS_TYPE: {
		fields: [
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			}
		]
	},
	COUNTRY: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'iso_number',
				type: 'number'
			},
			{
				name: 'iso_number_3',
				type: 'string'
			},
			{
				name: 'iso_2',
				type: 'string'
			},
			{
				name: 'iso_3',
				type: 'string'
			},
			{
				name: 'display_name',
				type: 'string',
				convert: function (v, record) {
					return record.get('iso_number_3')
						? record.get('iso_number_3') + ' - ' + record.get('name')
						: record.get('name');
				}
			}
		]
	},
	REGION: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'display_name',
				type: 'string',
				convert: function (v, record) {
					return record.get('id') ? record.get('id') + ' - ' + record.get('name') : record.get('name');
				}
			}
		]
	},
	FILE_STORAGE: {
		fields: [
			{
				name: 'attrs'
				//type: 'object'
			},
			{
				name: 'byOrg'
				//type: 'object'
			},
			{
				name: 'toOrg'
				//type: 'object'
			},
			{
				name: 'byUser'
				//type: 'object'
			},
			{
				name: 'contentType',
				type: 'string'
			},
			{
				name: 'contentEncoding',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'docName',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'guid',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'processed',
				type: 'string'
			}
		]
	},
	FS_RULE: {
		fields: [
			{
				name: 'contentType',
				type: 'string'
			},
			{
				name: 'markupRuleId',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'active',
				type: 'boolean'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	},
	TRANSFORMATION_INCLUDE: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'filename',
				type: 'string'
			}
		]
	},
	FILE_RESULT: {
		fields: [
			{
				name: 'byModule',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'ediFileStorage'
				//type: 'object'
			},
			{
				name: 'errorMessage',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'processed',
				type: 'string'
			},
			{
				name: 'docName',
				type: 'string'
			},
			{
				name: 'loadBySystem',
				type: 'boolean'
			},
			{
				name: 'attrs'
			}
		]
	},
	BASE_AGREEMENT: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'number',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'owner'
				//type: 'object'
			},
			{
				name: 'validFrom',
				type: 'string'
			},
			{
				name: 'validTo',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'signatureRules'
				//type: 'object'
			},
			{
				name: 'creationDate',
				type: 'string'
			},
			{
				name: 'active',
				type: 'boolean'
			},
			{
				name: 'attributes'
				//type: 'object'
			},
			{
				name: 'perpetual',
				type: 'boolean'
			},
			{
				name: 'unlimited',
				type: 'boolean'
			}
		]
	},
	PERMANENT_POSITION: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'shortName',
				type: 'string'
			},
			{
				name: 'owner'
				//type: 'object'
			},
			{
				name: 'maxCount',
				type: 'number'
			},
			{
				name: 'curentActiveCount',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'role',
				type: 'string'
			}
		]
	},
	WORKER_POSITION: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'permanentPosition'
				//type: 'object'
			},
			{
				name: 'user'
				//type: 'object'
			},
			{
				name: 'active',
				type: 'boolean'
			},
			{
				name: 'validFrom',
				type: 'number'
			},
			{
				name: 'validTo',
				type: 'number'
			}
		]
	},
	COMPLEX_REQUEST: {
		idProperty: 'recordId',
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'recordId',
				convert: function (v, record) {
					return record.get('id') || 0;
				},
				type: 'number'
			},
			{
				name: 'method',
				type: 'string'
			},
			{
				name: 'postData',
				type: 'string'
			},
			{
				name: 'map'
				//type: 'object'
			},
			{
				name: 'defaults'
				//type: 'object'
			},
			{
				name: 'url',
				type: 'string'
			},
			{
				name: 'exclude'
				//type: 'object'
			},
			{
				name: 'include'
				//type: 'object'
			},
			{
				name: 'level',
				type: 'number'
			}
		]
	},
	COMPLEX_REQUEST_RESULT: {
		fields: [
			{
				name: 'uri',
				type: 'string'
			},
			{
				name: 'method',
				type: 'string'
			},
			{
				name: 'result'
				//type: 'object'
			},
			{
				name: 'level',
				type: 'number'
			},
			{
				name: 'error',
				type: 'boolean'
			},
			{
				name: 'time',
				type: 'number'
			}
		]
	},
	COMPLEX_REQUEST_MAP: {
		fields: [
			{
				name: 'field',
				type: 'string'
			},
			{
				name: 'path',
				type: 'string'
			}
		]
	},
	COMPLEX_REQUEST_INCLUDE: {
		fields: [
			{
				name: 'field',
				type: 'string'
			},
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'value'
				//type: 'array'
			}
		]
	}
});
