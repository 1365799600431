/**
 * @author Pavel Pirogov
 * Relations methods
 */
Ext.namespace('edi.relations');

edi.relations = new (function () {
	var relations = [];
	/**
	 * init relations
	 * @param	{Object}	data		relations array
	 * @param	{Function}	callback	callback that will be called after getting all organizations data
	 */
	this.init = function (data, callback) {
		setRelations(data);
		'function' == typeof callback ? callback() : null;
	};
	/**
	 * get user relations
	 * @param	{Object}    [conf]
	 * @returns	{Array} 	array of organizations
	 */
	this.getRelations = function (conf) {
		conf = 'object' == typeof conf ? conf : {};
		var defaults = {
			self: false // include self
		};
		Ext.applyIf(conf, defaults);
		var res = [];
		var isExist = function (org) {
			var exists = false;
			edi.utils.each(res, function (item) {
				var doBreak = false;
				if (item.id == org.id) {
					exists = true;
					doBreak = true;
				}
				return doBreak;
			});
			return exists;
		};
		edi.utils.each(relations, function (relation) {
			if (
				(conf.self || relation.id != edi.core.getUserData().org.id) &&
				!isExist(relation) &&
				relation.orgType != edi.constants.ORGANIZATION_TYPES.FOREIGN_LEGAL_ENTITY
			) {
				res.push(edi.models.createInstance('ORGANIZATION', relation).getData());
			}
		});
		return res;
	};
	/**
	 * get user relations
	 * @param	{Object}    conf
	 * @returns	{Array} 	array of organizations
	 */
	this.getRelationsWithForeign = function (conf) {
		conf = 'object' == typeof conf ? conf : {};
		var defaults = {
			self: false // include self
		};
		Ext.applyIf(conf, defaults);
		var res = [];
		var isExist = function (org) {
			var exists = false;
			edi.utils.each(res, function (item) {
				var doBreak = false;
				if (item.id == org.id) {
					exists = true;
					doBreak = true;
				}
				return doBreak;
			});
			return exists;
		};
		edi.utils.each(relations, function (relation) {
			if ((conf.self || relation.id != edi.core.getUserData().org.id) && !isExist(relation)) {
				res.push(edi.models.createInstance('ORGANIZATION', relation).getData());
			}
		});
		return res;
	};
	/**
	 * Sets relation collection
	 *  @param	{Array}	data	collection of organizations
	 */
	var setRelations = function (data) {
		relations = [];
		for (var i = 0; i < data.length; i++) {
			relations.push(data[i]);
		}
	};
})();
