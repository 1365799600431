export interface SetFieldFocusProps {
	field: ExtComponent;
}

/**
 * Устанавливает фокус на невалидном поле формы.
 * @param {Object} options - Параметры метода.
 * @param {ExtComponent} options.invalidField - Невалидное поле формы, на которое нужно установить фокус.
 */
export const setFieldFocus = function ({ field }: SetFieldFocusProps): void {
	if (!field) return;

	/**
	 * Находим таб содержащий невалидный field и переключаемся на него
	 * Поиск таба происходит по panelType="tabpanel", поэтому нужно передать это в конфиг таба
	 */
	const tab = field.up('panel[panelType="tabpanel"]');

	const tabPanel = tab?.up('[xtype="tabpanel"]');
	if (tabPanel && tab) {
		tabPanel.setActiveTab(tab);
	}

	const form = field.up('form');
	setTimeout(() => {
		field.focus();
		if (form) setScrollTopAfterFocus({ form });
	}, 0);
};

export interface SetScrollTopAfterFocusProps {
	form: ExtComponent;
}

/**
 * Дубликат edi.utils.setScrollTopAfterFocus
 */
export const setScrollTopAfterFocus = function ({ form }: SetScrollTopAfterFocusProps): void {
	const formScrollY = form.getScrollY();
	if (form.scrollable && formScrollY !== form.scrollable.trackingScrollTop) {
		// При скролле колесиком Ext запоминает текущее положение скролла и записывает в trackingScrollTop (см. doOnDomScrollEnd)
		// при фокусе события скролла может не произойти т.к. перед этим мог быть вызван restoreState с updateLayout (см onMandatory у fieldError)
		// и updateDomScrollPosition будет silent (см restoreTimer) и НЕ вызовется doOnDomScrollEnd с изменением trackingScrollTop
		// руками запишем текущее положение скролла формы в trackingScrollTop
		// иначе при любом updateLayout Ext проскроллит форму до места, где был скролл до фокуса
		form.scrollable.trackingScrollTop = form.getScrollY();
	}
};
