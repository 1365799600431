import { utils } from './index';

interface FieldWithCustomValidation {
	field: ExtComponent;
	isValid: () => boolean;
}
export interface GetInvalidFieldProps {
	fields: (ExtComponent | FieldWithCustomValidation)[];
}

/**
 * Находит поле с ошибкой.
 * @param {Object} options - Параметры метода.
 * @param {Array<ExtComponent|{field: ExtComponent, isValid: Function}>} [options.fields=[]]  - Массив компонентов или объект, содержащий компонент и его метод валидации.
 * @param {Object} options.fields.field - ExtComponent.
 * @param {Function} options.fields.isValid - Функция для проверки валидности поля.
 * @returns {Object|undefined} - Поле с ошибкой (ExtComponent) или undefined, если поле с ошибкой не найдено.
 */
export const getInvalidField = function ({ fields = [] }: GetInvalidFieldProps): ExtComponent | undefined {
	const findErrorField = (field: ExtComponent | FieldWithCustomValidation): boolean => {
		if (!field) return false;

		if ('isComponent' in field && field.isComponent && typeof field.isValid === 'function')
			return field.isVisible() && !field.isValid();

		if ('field' in field && 'isValid' in field) {
			const { field: fieldCmp, isValid } = field;
			return typeof isValid === 'function' && !!fieldCmp && !isValid();
		}

		return false;
	};
	const errorField = fields.find(findErrorField);
	return errorField && !('isComponent' in errorField) && 'field' in errorField ? errorField.field : errorField;
};

export interface FindAndFocusInvalidFieldProps extends GetInvalidFieldProps {}

/**
 * Находит и устанавливает фокус на первом невалидном поле формы.
 * @param {Object} options - Параметры метода.
 * @param {Array<ExtComponent|{field: ExtComponent, isValid: Function}>} [options.fields=[]] - Массив компонентов или объект, содержащий компонент и его метод валидации.
 * @returns {Object|undefined} - Первое невалидное поле формы, на которое установлен фокус, или undefined, если невалидных полей нет.
 */
export const findAndFocusInvalidField = function ({
	fields = []
}: FindAndFocusInvalidFieldProps): ExtComponent | undefined {
	const invalidField = getInvalidField({ fields });
	if (invalidField) {
		utils.setFieldFocus({ field: invalidField });
		return invalidField;
	}
};
