/**
 * @author Anatoly Deryshev
 * CryptoPro async functionality
 */
var cryptoPro = new function() {
	var __self = this, pluginObj, initiated, loaded, callMap = {}, responseTypes = {
		ERROR: "error",
		RESULT: "result"
	}, idCounter = 0, DEFAULT = {
		DESTINATION: "nmcades",
		ID: "objid",
		LOAD_TIMEOUT: 20000
	}, constants = {
		CAPICOM_MY_STORE: "My",
		CAPICOM_ROOT_STORE: "Root",
		CAPICOM_LOCAL_MACHINE_STORE: 1,
		CAPICOM_CURRENT_USER_STORE: 2,
		CAPICOM_ACTIVE_DIRECTORY_USER_STORE: 3,
		CAPICOM_SMART_CARD_USER_STORE: 4,
		CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED: 2,
		CADESCOM_BASE64_TO_BINARY: 1,
		CADESCOM_CADES_BES: 1,
		CADESCOM_CADES_X_LONG_TYPE_1: 0x5d,
		CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME: 0,
		CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME: 1,
		CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN: 1
	}, config = {
		DETACHED_SIGN: true,
		CADES_TYPE: constants.CADESCOM_CADES_BES,
		CERTIFICATE_INCLUDE_WHOLE_CHAIN: false,
		USE_SIGN_TIME_ATTR: false,
		USE_SIGN_NAME_ATTR: false
	};
	/**
	 * CryptoPro async api initialisation
	 */
	this.init = function(cfg) {
		__self.init = function() {
			edi.core.logMessage("CryptoPro is already initiated", "warn");
		};
		Object.assign(config, cfg);
		checkPluginLoaded();
	};
	/**
	 * Returns true if plugin is available and initiated
	 * @returns {*}
	 */
	this.isPluginReady = function() {
		var ready = false;
		if (loaded && initiated) {
			ready = true;
		}
		else if (false === loaded) {
			edi.core.logMessage("CryptoPro browser plugin not loaded", "warn");
		}
		else if (loaded) {
			edi.core.logMessage("CryptoPro browser plugin still loading", "warn");
		}
		else if (!initiated) {
			if (false === initiated) {
				edi.core.logMessage("CryptoPro browser plugin loaded but not initiated", "warn");
			}
			else {
				edi.core.logMessage("CryptoPro browser plugin is not yet initiated", "warn");
			}
		}
		return ready;
	};
	/**
	 * get array of certificates
	 * @param    {Function}    callback
	 */
	this.getCertificatesArray = function(callback) {
		if ("function" == typeof callback) {
			asyncCall(
				function* () {
					var certificates = null, res = [], i, v, k, count = 0, retData = createDataContainer();
					try {
						if (typeof edi.sign.checkMinVersion === 'function') {
							const pluginData = yield pluginObj.CreateObject("CAdESCOM.About");
							const CSPVersion = yield pluginData.CSPVersion();
							const MajorVersion = yield CSPVersion.MajorVersion;
							const MinorVersion = yield CSPVersion.MinorVersion;
							const BuildVersion = yield CSPVersion.BuildVersion;
							const currentVersion = [MajorVersion, MinorVersion, BuildVersion].join('.');
							yield edi.sign.checkMinVersion(currentVersion);
						}
						var oStore = yield pluginObj.CreateObject("CAdESCOM.Store");
						yield oStore.Open();
						certificates = yield oStore.Certificates;
						yield oStore.Close();
						if (certificates != null) {
							count = yield certificates.Count;
							for (i = 1; i <= count; i++) {
								var oCert = yield certificates.Item(i), certObj = {};
								certObj[DEFAULT.ID] = oCert[DEFAULT.ID];
								certObj.IssuerName = yield oCert.IssuerName;
								certObj.SubjectName = yield oCert.SubjectName;

								var hasPrivateKey = yield oCert.HasPrivateKey();
								if (hasPrivateKey && oCert.FindPrivateKey) {
									try {
										yield oCert.FindPrivateKey();
										hasPrivateKey = true;
									}
									catch(e) {
										hasPrivateKey = false;
									}
								}

								if (hasPrivateKey && certObj.IssuerName != certObj.SubjectName) {
									let signAllowed = true;
									try {
										let usageInfo = yield oCert.KeyUsage();
										let publicKey = yield oCert.PublicKey();
										if ("undefined" != typeof publicKey.Algorithm) {
											var algorithm = yield publicKey.Algorithm, algFriendlyName, algValue;
											if ("undefined" != typeof algorithm.FriendlyName) {
												algFriendlyName = yield algorithm.FriendlyName;
											}
											if ("undefined" != typeof algorithm.Value) {
												algValue = yield algorithm.Value;
											}
											let publicKeyAlgorithmGetter = function(algFriendlyName, algValue){
												return function(){
													return {
														Algorithm: {
															FriendlyName: algFriendlyName,
															Value: algValue
														}
													};
												};
											};
											certObj.PublicKey = publicKeyAlgorithmGetter(algFriendlyName, algValue);
										}
										let IsNonRepudiationEnabled = !!usageInfo.IsNonRepudiationEnabled
											? yield usageInfo.IsNonRepudiationEnabled
											: null;
										let IsContentCommitmentEnabled = !!usageInfo.IsContentCommitmentEnabled
											? yield usageInfo.IsContentCommitmentEnabled
											: null;
										let IsDigitalSignatureEnabled = (window.isDevelopment && !!usageInfo.IsDigitalSignatureEnabled)
											? yield usageInfo.IsDigitalSignatureEnabled
											: null;
										signAllowed = IsNonRepudiationEnabled
											|| IsContentCommitmentEnabled
											|| IsDigitalSignatureEnabled;
									}
									catch (err) {
										edi.core.handleException(err);
									}
									if (signAllowed) {
										var descriptor;
										for (v in oCert) {
											if (oCert.hasOwnProperty(v) && v != DEFAULT.ID && v != "IssuerName" && v != "SubjectName" && v != "PrivateKey") {
												descriptor = Object.getOwnPropertyDescriptor(oCert, v);
												if ("function" != typeof descriptor.value) {
													certObj[v] = yield oCert[v];
												}
											}
										}
										res.push(certObj);
									}
								}
							}
							retData.data = res;
						}
					}
					catch (err) {
						formatErrorData(retData, "error.cryptopro.certificates.processing", err);
						edi.core.logMessage("Error during reading certificates from store. Error: " + getErrorMessage(err), "warn");
					}
					return retData;
				}
			).then(callback);
		}
	};
	/**
	 * create sign
	 * @param    {String}      dataToSign     data in base64
	 * @param    {Object}      certificate    certificate object
	 * @param    {Function}    callback       callback that will receive result object
	 * @param    {String}      docName        document name that should be written as attribute
	 * @param    {Object}      [options]      additional options
	 */
	this.signCreate = function(dataToSign, certificate, callback, docName, options) {
		var opts = options || {};
		var contentEncoding = opts.hasOwnProperty('contentEncoding')
			? opts.contentEncoding
			: constants.CADESCOM_BASE64_TO_BINARY;
		var detachedSignature = opts.hasOwnProperty('detachedSignature')
			? opts.detachedSignature
			: config.DETACHED_SIGN;

		if ("function" == typeof callback) {
			asyncCall(
				function* () {
					var retData = createDataContainer();
					try {
						var oSigner = yield pluginObj.CreateObject("CAdESCOM.CPSigner");
						if (config.USE_SIGN_TIME_ATTR || config.USE_SIGN_NAME_ATTR) {
							var oSignerAttrs = yield oSigner.AuthenticatedAttributes2;
							if (config.USE_SIGN_TIME_ATTR) {
								var oSigningTimeAttr = yield pluginObj.CreateObject("CAdESCOM.CPAttribute");
								yield oSigningTimeAttr.Name = constants.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME;
								yield oSigningTimeAttr.Value = new Date();
								yield oSignerAttrs.Add(oSigningTimeAttr);
							}
							if (config.USE_SIGN_NAME_ATTR && docName) {
								var oDocumentNameAttr = yield pluginObj.CreateObject("CAdESCOM.CPAttribute");
								yield oDocumentNameAttr.Name = constants.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME;
								yield oDocumentNameAttr.Value = docName;
								yield oSignerAttrs.Add(oDocumentNameAttr);
							}
						}
						yield oSigner.Certificate = certificate;
						if (config.CERTIFICATE_INCLUDE_WHOLE_CHAIN) {
							yield oSigner.Options = constants.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN;
						}
						var oSignedData = yield pluginObj.CreateObject("CAdESCOM.CadesSignedData");
						yield oSignedData.ContentEncoding = contentEncoding;
						yield oSignedData.Content = dataToSign;
						retData.data = yield oSignedData.SignCades(oSigner, config.CADES_TYPE, detachedSignature);
					}
					catch (err) {
						formatErrorData(retData, "error.cryptopro.sign.processing", err);
						edi.core.logMessage("Error during signing. Error: " + getErrorMessage(err), "warn");
					}
					return retData;
				}
			).then(callback, callback);
		}
		else {
			edi.core.logMessage("Error during async signing. Error: no signature callback defined", "warn");
		}
	};
	/**
	 * Creates plugin response data container for main application
	 * @returns {{data: null, error: null}}
	 */
	var createDataContainer = function() {
		return {
			data: null,
			error: null
		};
	};
	/**
	 * Formats error data in response container for main application
	 * @param    {Object}    container
	 * @param    {String}    msg
	 * @param    {Object}    err
	 */
	var formatErrorData = function(container, msg, err) {
		if ("object" != typeof container) {
			container = createDataContainer();
		}
		container.error = {
			typeError: msg,
			additionalData: [
				getErrorMessage(err)
			],
			status: 124
		};
	};
	/**
	 * Returns callback promise register method
	 * @param    {Number}    id     id of the request
	 * @param    {Object}    msg    message object that will be send to plugin
	 * @returns {Function}
	 */
	var createMessageProcessor = function(id, msg) {
		return function(resolve, reject) {
			registerCallbacks(id, resolve, reject);
			window.postMessage(msg, "*");//NOSONAR
		};
	};
	/**
	 * Registers callbacks
	 * @param    {Number}      id         id of the request
	 * @param    {Function}    success    callback that will be called on success
	 * @param    {Function}    failure    callback that will be called on failure
	 */
	var registerCallbacks = function(id, success, failure) {
		id = id ? id : ++idCounter;
		callMap[id] = {
			success: success,
			failure: failure,
			id: id
		};
	};
	/**
	 * Process async response from CryptoPro plugin
	 * @param    {Object}    response    response from plugin
	 */
	var processResponse = function(response) {
		if (isHandlersRegistered(response.data.requestid)) {
			var callback = callMap[response.data.requestid];
			if (responseTypes.ERROR == response.data.type) {
				callback.failure(response);
			}
			else if (responseTypes.RESULT == response.data.type) {
				callback.success(response);
			}
			clearHandlers(response.data.requestid);
		}
	};
	/**
	 * Checks if handlers for such request id are registered
	 * @param    {Number}    id    index of stored handlers
	 * @returns {boolean}
	 */
	var isHandlersRegistered = function(id) {
		var registered = false;
		if (id) {
			registered = !!callMap[id];
		}
		return registered;
	};
	/**
	 * Clears request handlers
	 * @param    {Number}    id    id of the request
	 */
	var clearHandlers = function(id) {
		callMap[id] = null;
		delete callMap[id];
	};
	/**
	 * Converst response from plugin to data ready for async processing
	 * @param    {Object}    data    data object to convert
	 * @returns {*}
	 */
	var responseConverter = function(data) {
		var i, retData, obj = {};
		if (data.retval.type == "object") {
			obj[DEFAULT.ID] = data.retval.value;
			if (typeof data.retval.properties == "object") {
				var props = data.retval.properties;
				for (i = 0; i < props.length; i++) {
					Object.defineProperty(obj, props[i], {
						get: getPropertyAsync.bind(obj, props[i]),
						set: setPropertyAsync.bind(obj, props[i]),
						enumerable: true,
						writeable: true
					});
				}
			}
			if (typeof data.retval.methods == "object") {
				var methods = data.retval.methods;
				for (i = 0; i < methods.length; i++) {
					obj[methods[i]] = callMethodAsync.bind(obj, methods[i]);
				}

			}
			retData = obj;
		}
		else if (data.retval.type == "string") {
			retData = data.retval.value;
		}
		else if (data.retval.type == "number") {
			retData = parseInt(data.retval.value, 10);
		}
		else if (data.retval.type == "boolean") {
			retData = Boolean(data.retval.value);
		}
		else if (data.retval.type == "OK") {
			retData = undefined;
		}
		return retData;
	};
	/**
	 * Formats request message parameters
	 * @param    {Array}    params    array of request parameters
	 */
	var formatRequestParams = function(params) {
		var retData = [], i;
		for (i = 0; i < params.length; i++) {
			if (typeof params[i] == "object") {
				if (params[i] instanceof Date) {
					retData.push({
						type: "string",
						value: params[i].toISOString()
					});
				}
				else {
					retData.push({
						type: typeof params[i],
						value: params[i][DEFAULT.ID]
					});
				}
			}
			else {
				retData.push({
					type: typeof params[i],
					value: params[i]
				});
			}
		}
		return retData;
	};
	/**
	 * Calls async object method
	 * @returns {*}
	 */
	var callMethodAsync = function() {
		var requestId = ++idCounter, msg = {
			destination: DEFAULT.DESTINATION,
			requestid: requestId,
			objid: this[DEFAULT.ID],
			method: arguments[0]
		};
		msg.params = formatRequestParams(Array.prototype.slice.call(arguments, 1));
		return sendPluginRequest(requestId, msg);
	};
	/**
	 * Reads async object property
	 * @returns {*}
	 */
	var getPropertyAsync = function() {
		var requestId = ++idCounter, msg = {
			destination: DEFAULT.DESTINATION,
			requestid: requestId,
			objid: this[DEFAULT.ID],
			get_property: arguments[0]
		};
		return sendPluginRequest(requestId, msg);
	};
	/**
	 * Sets async object property
	 * @returns {*}
	 */
	var setPropertyAsync = function() {
		var requestId = ++idCounter, msg = {
			destination: DEFAULT.DESTINATION,
			requestid: requestId,
			objid: this[DEFAULT.ID],
			set_property: arguments[0]
		};
		msg.params = formatRequestParams(Array.prototype.slice.call(arguments, 1));
		return sendPluginRequest(requestId, msg);
	};
	/**
	 * Sends request to plugin
	 * @param    {Number}      requestId    request identifier
	 * @param    {Object}      msg          request object
	 * @param    {Function}    callback     function that will be called as response processor
	 * @returns {*}
	 */
	var sendPluginRequest = function(requestId, msg, callback) {
		return (new Promise(createMessageProcessor(requestId, msg))).then("function" == typeof callback ? callback : function(result) {
			return responseConverter(result.data);
		}, "function" == typeof callback ? callback : undefined);
	};
	/**
	 * Creates async call to plugin
	 * @param    {Function}    generatorFunc    generator function
	 * @returns {*}
	 */
	var asyncCall = function(generatorFunc) {
		var continueFn = function(verb, arg) {
			var result;
			try {
				result = generator[verb](arg);
			}
			catch (err) {
				return Promise.reject(err);
			}
			if (result.done) {
				return result.value;
			}
			else {
				return Promise.resolve(result.value).then(onFulfilled, onRejected);
			}
		};
		var generator = generatorFunc(Array.prototype.slice.call(arguments, 1));
		var onFulfilled = continueFn.bind(continueFn, "next");
		var onRejected = continueFn.bind(continueFn, "throw");
		return onFulfilled();
	};
	/**
	 * Formats error message
	 * @param    {Object}    e    error object
	 * @returns {*}
	 * @constructor
	 */
	var getErrorMessage = function(e) {
		var err = e.data && e.data.message ? e.data.message : e.message;
		if (!err) {
			err = e;
		}
		else if (e.number) {
			err += " (" + e.number + ")";
		}
		return err;
	};
	/**
	 * Creates base plugin objects
	 * @returns {*}
	 */
	var createPluginObject = function() {
		var requestId = ++idCounter, msg = {
			destination: DEFAULT.DESTINATION,
			requestid: requestId,
			type: "init",
			url: window.document.URL
		}, check = setTimeout(function() {
			initiated = false;
			edi.core.logMessage("CryptoPro browser plugin not initiated within " + (DEFAULT.LOAD_TIMEOUT / 1000) + "s", "warn");
		}, DEFAULT.LOAD_TIMEOUT);
		sendPluginRequest(requestId, msg, function(result) {
			if (result && result.data && "undefined" != typeof result.data.value) {
				pluginObj = {};
				pluginObj[DEFAULT.ID] = result.data.value;
				pluginObj.CreateObject = callMethodAsync.bind(pluginObj, "CreateObject");
				initiated = true;
				clearTimeout(check);
				edi.core.logMessage("CryptoPro browser plugin object initiated", "info");
			}
			else {
				initiated = false;
			}
			return pluginObj;
		});
	};
	/**
	 * Sends echo request to ensure that plugin is loaded
	 */
	var checkPluginLoaded = function() {
		var listener = function(event) {
			if ("string" == typeof event.data && event.data.match("cadesplugin_loaded")) {
				window.removeEventListener("message", listener);
				edi.core.logMessage("CryptoPro browser plugin loaded", "info");
				loaded = true;
				clearTimeout(check);
				createPluginObject();
			}
		}, check = setTimeout(function() {
			loaded = false;
			window.removeEventListener("message", listener);
			edi.core.logMessage("CryptoPro browser plugin not responded within " + (DEFAULT.LOAD_TIMEOUT / 1000) + "s", "warn");
		}, DEFAULT.LOAD_TIMEOUT);
		window.addEventListener("message", listener);

		window.postMessage("cadesplugin_echo_request", "*");
	};

	window.addEventListener("message", function(event) {
		if (event.source == window) {
			if (event.data && event.data.tabid) {//Check that this is answer from plugin
				if (event.data.data && event.data.data.requestid) {
					processResponse(event.data);
				}
			}
		}
	}, false);
}();
window.cryptoPro = cryptoPro;