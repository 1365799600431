import { createOrgSelector, ORG_SELECTOR_CLS } from '@Components/orgSelector/OrgSelector';

const createCompanySelector = function (cfg) {
	return createOrgSelector(
		Ext.merge(
			{
				cls: ORG_SELECTOR_CLS.oldCP
			},
			cfg
		)
	);
};

export { createCompanySelector };
