import { createCheckLabel } from '@Components/checkLabel/CheckLabel';
import { createHiddenField, createPasswordField } from '@UIkit/components/fields';
import { createContainer, createFieldBlock, createModalForm } from '@UIkit/components/panels';
import { createLabel } from '@Components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';

const defaultRegRules = {
	LATIN_CAPITAL_AND_LOWERCASE: /^(?=.*[a-z])(?=.*[A-Z])(?!.*[а-яА-Я])/,
	MIN_SYMBOLS: /.{10,}/,
	HAVE_NUMBER: /\d+/,
	HAVE_SPECIAL_CHARACTER: /[!@#$%]+/
};
const MAX_PASSWORD_LENGTH = 20;

const getPasswordRequirements = (requirements) => {
	return Object.assign(
		{
			LATIN_CAPITAL_AND_LOWERCASE: createCheckLabel({ text: edi.i18n.getMessage('password.only.latin') }),
			MIN_SYMBOLS: createCheckLabel({ text: edi.i18n.getMessage('password.min.symbols', [10]) }),
			HAVE_NUMBER: createCheckLabel({ text: edi.i18n.getMessage('password.have.number') }),
			HAVE_SPECIAL_CHARACTER: createCheckLabel({ text: edi.i18n.getMessage('password.have.special.character') }),
			PASSWORDS_MATCH: createCheckLabel({ text: edi.i18n.getMessage('passwords.match') })
		},
		requirements
	);
};

const validateRequirement = function (requirement, valid) {
	requirement?.setActive(valid);
};

const isAllRequirementsActive = function (requirements = []) {
	return !requirements.some((requirement) => requirement?.isActive() === false);
};

const createChangePasswordField = function (config = {}) {
	const {
		regRules: confRegRules = {},
		requirements: confRequirements = {},
		onPasswordSave = (value) => {},
		containerConfig: confContainerConfig = {},
		oldPasswordConf = {},
		newPasswordConf = {},
		confirmNewPasswordConf = {},
		onlyButton
	} = config;

	const regRules = Object.assign(Ext.clone(defaultRegRules), confRegRules);
	let isPasswordFormValid = false;

	const createRequirementsPanel = () => {
		const requirements = getPasswordRequirements(confRequirements);
		const requirementsFields = Object.values(requirements);
		const validatePasswords = () => {
			const oldPasswordValue = oldPasswordField.getValue();
			const newPasswordValue = newPasswordField.getValue();
			const confirmNewPasswordValue = confirmNewPasswordField.getValue();
			for (let rule in regRules) {
				validateRequirement(requirements[rule], !!newPasswordValue.match(regRules[rule]));
			}
			//пароли совпадают
			validateRequirement(
				requirements['PASSWORDS_MATCH'],
				newPasswordValue.length && newPasswordValue === confirmNewPasswordValue
			);

			const allFieldsFilled = !!oldPasswordValue && !!newPasswordValue && !!confirmNewPasswordValue;
			isPasswordFormValid = isAllRequirementsActive(requirementsFields) && allFieldsFilled;
		};
		const oldPasswordField = createPasswordField(
			Object.assign(
				{
					name: 'password.old',
					listeners: {
						change: validatePasswords
					}
				},
				oldPasswordConf
			)
		);
		const newPasswordField = createPasswordField(
			Object.assign(
				{
					name: 'password.new',
					maxLength: MAX_PASSWORD_LENGTH,
					listeners: {
						change: validatePasswords
					}
				},
				newPasswordConf
			)
		);
		const confirmNewPasswordField = createPasswordField(
			Object.assign(
				{
					name: 'password.confirmNew',
					maxLength: MAX_PASSWORD_LENGTH,
					listeners: {
						change: validatePasswords
					}
				},
				confirmNewPasswordConf
			)
		);
		const passwordPanel = createModalForm({
			bodyPadding: '16 24',
			layout: {
				type: 'grid',
				gap: 16
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('column.password.old'),
					items: [oldPasswordField]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('column.password.new'),
					items: [newPasswordField]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('column.password.new2'),
					items: [confirmNewPasswordField]
				})
			]
		});
		const requirementsPanel = createContainer({
			items: requirementsFields
		});
		passwordPanel.add(requirementsPanel);
		return passwordPanel;
	};

	const containerConfig = Object.assign(
		{
			columnWidth: 0.9
		},
		confContainerConfig
	);

	const passwordChangeBtn = createButton({
		text: edi.i18n.getMessage('password.change'),
		cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
		width: 200,
		handler: () => {
			const passwordForm = createRequirementsPanel();
			const modal = createModalPanel({
				title: edi.i18n.getMessage('change.password.title'),
				width: MODAL_SIZE.widthSmall,
				items: passwordForm,
				closeButtonOptions: {
					text: edi.i18n.getMessage('btn.cancel')
				},
				buttonsBefore: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.save'),
						handler: function () {
							if (isPasswordFormValid) {
								const values = edi.utils.collectFormValues(passwordForm);
								if (typeof onPasswordSave === 'function') {
									onPasswordSave(values);
								}
								modal.close();
							}
						}
					})
				]
			});
			modal.show();
		}
	});

	return onlyButton
		? passwordChangeBtn
		: createContainer({
				layout: 'column',
				columnWidth: 0.9,
				items: [
					createLabel({
						text: edi.i18n.getMessage('column.password'),
						cls: 'edi-fieldset-label',
						columnWidth: 0.4
					}),
					createContainer({
						columnWidth: 0.6,
						items: passwordChangeBtn
					})
				]
		  });
};

const createChangePasswordFieldWithGeneration = function (config = {}) {
	const {
		regRules: confRegRules = {},
		requirements: confRequirements = {},
		onPasswordSave = (value) => {},
		name = 'password',
		containerConfig: confContainerConfig = {},
		changePasswordBtnConfig = {},
		newPasswordConf = {}
	} = config;

	const regRules = Object.assign({}, defaultRegRules, confRegRules);
	let isPasswordFormValid = false;
	const createChangePasswordPanel = () => {
		const requirements = getPasswordRequirements(
			Object.assign(
				{
					PASSWORDS_MATCH: null
				},
				confRequirements
			)
		);
		const requirementsFields = Object.values(requirements);
		const newPasswordField = createPasswordField(
			Object.assign(
				{
					name: name,
					maxLength: MAX_PASSWORD_LENGTH,
					listeners: {
						change: (field) => {
							const passwordValue = field.getValue();
							for (let rule in regRules) {
								validateRequirement(requirements[rule], !!passwordValue.match(regRules[rule]));
							}
							isPasswordFormValid = isAllRequirementsActive(requirementsFields);
						}
					}
				},
				newPasswordConf
			)
		);
		const passwordForm = createModalForm({
			bodyPadding: '16 24',
			layout: {
				type: 'grid',
				gap: 16
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('column.password.new'),
					layout: {
						type: 'grid',
						gap: 8,
						area: [[11, 1]]
					},
					items: [
						newPasswordField,
						createButton({
							cls: [BUTTON_CLS.light, BUTTON_CLS.small],
							glyph: edi.constants.ICONS.ROTATE_RIGHT,
							tooltip: edi.i18n.getMessage('btn.generate'),
							margin: '8 0 0 0',
							handler: function () {
								newPasswordField.setValue(edi.utils.passwordGenerator.generate());
							}
						})
					]
				})
			]
		});
		const requirementsPanel = createContainer({
			items: requirementsFields
		});
		passwordForm.add(requirementsPanel);
		return passwordForm;
	};

	const newPasswordField = createHiddenField({
		name: name
	});
	const changePasswordBtn = createButton(
		Object.assign(
			{
				text: edi.i18n.getMessage('password.change'),
				cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
				columnWidth: 0.6,
				handler: () => {
					const passwordForm = createChangePasswordPanel();
					const modal = createModalPanel({
						title: edi.i18n.getMessage('change.password.title'),
						width: MODAL_SIZE.widthSmall,
						closeButtonOptions: {
							text: edi.i18n.getMessage('btn.cancel')
						},
						items: passwordForm,
						buttonsBefore: [
							createButton({
								cls: BUTTON_CLS.primary,
								text: edi.i18n.getMessage('form.btn.save'),
								handler: function () {
									if (isPasswordFormValid) {
										const values = edi.utils.collectFormValues(passwordForm);
										newPasswordField.setValue(values[name]);
										if (typeof onPasswordSave === 'function') {
											onPasswordSave(values[name]);
										}
										modal.close();
									}
								}
							})
						]
					});
					modal.show();
				}
			},
			changePasswordBtnConfig
		)
	);
	const containerConfig = Object.assign(
		{
			layout: {
				type: 'grid',
				gap: 0,
				area: [[6, 4]]
			}
		},
		confContainerConfig
	);

	return createContainer({
		...containerConfig,
		items: [
			createLabel({
				text: edi.i18n.getMessage('column.password')
			}),
			changePasswordBtn,
			newPasswordField
		]
	});
};

export { createChangePasswordField, createChangePasswordFieldWithGeneration };
