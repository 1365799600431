import { setFieldFocus, SetFieldFocusProps, setScrollTopAfterFocus, SetScrollTopAfterFocusProps } from './formFocus';
import {
	findAndFocusInvalidField,
	FindAndFocusInvalidFieldProps,
	getInvalidField,
	GetInvalidFieldProps
} from './formValidation';

interface Utils {
	[key: string]: Function;
	setFieldFocus: (props: SetFieldFocusProps) => void;
	getInvalidField: (props: GetInvalidFieldProps) => ExtComponent | undefined;
	findAndFocusInvalidField: (props: FindAndFocusInvalidFieldProps) => ExtComponent | undefined;
	setScrollTopAfterFocus: (props: SetScrollTopAfterFocusProps) => void;
}

export const utils: Utils = {
	setFieldFocus,
	getInvalidField,
	findAndFocusInvalidField,
	setScrollTopAfterFocus
};
