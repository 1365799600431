/**
 * @author Vsevolod Liapin
 * Methods for document templates
 */
Ext.namespace('edi.error');

edi.error = new (function () {
	this.xTemplates = {};

	this.formatErrorTemplate = function (str, values) {
		var replateValues = {},
			regex = /{xTemplate:(\w+)}/gm,
			xTemplateName;
		while ((xTemplateName = regex.exec(str)) !== null) {
			if (xTemplateName.index === regex.lastIndex) {
				regex.lastIndex++;
			}

			if (Array.isArray(xTemplateName) && xTemplateName.length > 1) {
				xTemplateName = xTemplateName[1];
				if (edi.error.xTemplates.hasOwnProperty(xTemplateName)) {
					var tpl = new Ext.XTemplate(edi.error.xTemplates[xTemplateName]);
					if (tpl) {
						replateValues['xTemplate:' + xTemplateName] = tpl.apply(values);
					}
				}
			}
		}

		return edi.utils.formatString(str, Object.keys(replateValues).length > 0 ? replateValues : values, true);
	};
})();
