/**
 * @author Anatoly Deryshev
 * cryptoPro functionality
 */
var cryptoPro = new function() {
	var __self = this, isInstalled, cadesObjectCreared = false, pluginObject, pluginObject2, HTML_ELEMENT_ID = "cadesplugin_object", HTML_ELEMENT_ID_2 = "webClassFactory",
		isIE = ("Microsoft Internet Explorer" == navigator.appName) || navigator.userAgent.match(/Trident\/./i);

	var constants = {
		CAPICOM_MY_STORE: "My",
		CAPICOM_ROOT_STORE: "Root",
		CAPICOM_LOCAL_MACHINE_STORE: 1,
		CAPICOM_CURRENT_USER_STORE: 2,
		CAPICOM_ACTIVE_DIRECTORY_USER_STORE: 3,
		CAPICOM_SMART_CARD_USER_STORE: 4,
		CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED: 2,
		CADESCOM_BASE64_TO_BINARY: 1,
		CADESCOM_CADES_BES: 1,
		CADESCOM_CADES_X_LONG_TYPE_1: 0x5d,
		CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME: 0,
		CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME: 1,
		CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN: 1
	}, config = {
		DETACHED_SIGN: true,
		CADES_TYPE: constants.CADESCOM_CADES_BES,
		CERTIFICATE_INCLUDE_WHOLE_CHAIN: false,
		USE_SIGN_TIME_ATTR: false,
		USE_SIGN_NAME_ATTR: false
	};
	/**
	 * init method
	 */
	this.init = function(cfg) {
		__self.init = function() {
			edi.core.logMessage("CryptoPro is already initiated", "warn");
		};
		Object.assign(config, cfg);
		this.isPluginReady();
	};
	/**
	 * check is plugin installed and prepare html element if needed
	 */
	this.isPluginReady = function() {
		if ("undefined" == typeof isInstalled) {
			isInstalled = false;
			if (isIE) {
				try {
					if (new ActiveXObject("CAdESCOM.CPSigner")) {
						isInstalled = true;
					}
				}
				catch (err) {
					edi.core.logMessage("CryptoPro browser plugin is not installed or disabled - " + getErrorMessage(err), "warn");
				}
			}
			else {
				var mimetype = navigator.mimeTypes["application/x-cades"];
				if (mimetype) {
					if (mimetype.enabledPlugin) {
						isInstalled = true;
					}
					else {
						edi.core.logMessage("CryptoPro browser plugin is disabled", "warn");
					}
				}
				else {
					edi.core.logMessage("CryptoPro browser plugin is not installed", "warn");
				}
			}
			if (isInstalled && isIE) {
				cadesObjectCreared = true;
			}
			else if (isInstalled) {
				isInstalled = prepareNPAPIPluginObject();
			}
		}
		return isInstalled;
	};
	/**
	 * get array of certificates
	 * @param    {Function}    callback    callback that will receive certificates
	 * @returns    {Array}
	 */
	this.getCertificatesArray = function(callback) {
		var data = getCertificates();
		if (data.count) {
			data = processCertificates(data);
		}
		"function" == typeof callback ? callback(data) : null;
		return data;
	};
	/**
	 * create sign
	 * @param    {String}      dataToSign     data in base64
	 * @param    {Object}      certificate    certificate object
	 * @param    {Function}    callback       callback that will receive result object
	 * @param    {String}      docName        document name that should be written as attribute
	 * @param    {Object}      [options]      additional options
	 * @returns    {String}    sign
	 */
	this.signCreate = function(dataToSign, certificate, callback, docName, options) {
		var opts = options || {};
		var contentEncoding = opts.hasOwnProperty('contentEncoding')
			? opts.contentEncoding
			: constants.CADESCOM_BASE64_TO_BINARY;
		var detachedSignature = opts.hasOwnProperty('detachedSignature')
			? opts.detachedSignature
			: config.DETACHED_SIGN;
		var retData = createDataContainer();
		try {
			var oSigner = createObject("CAdESCOM.CPSigner");
			if (config.USE_SIGN_TIME_ATTR || config.USE_SIGN_NAME_ATTR) {
				if (config.USE_SIGN_TIME_ATTR) {
					var oSigningTimeAttr = createObject("CAdESCOM.CPAttribute");
					oSigningTimeAttr.Name = constants.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME;
					var signDate = new Date();
					oSigningTimeAttr.Value = isIE && "function" === typeof Date.prototype.getVarDate ? signDate.getVarDate() : signDate;
					oSigner.AuthenticatedAttributes2.Add(oSigningTimeAttr);
				}
				if (config.USE_SIGN_NAME_ATTR && docName) {
					var oDocumentNameAttr = createObject("CAdESCOM.CPAttribute");
					oDocumentNameAttr.Name = constants.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME;
					oDocumentNameAttr.Value = docName;
					oSigner.AuthenticatedAttributes2.Add(oDocumentNameAttr);
				}
			}
			oSigner.Certificate = certificate;
			if (config.CERTIFICATE_INCLUDE_WHOLE_CHAIN) {
				oSigner.Options = constants.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN;
			}
			var oSignedData = createObject("CAdESCOM.CadesSignedData");
			oSignedData.ContentEncoding = contentEncoding;
			oSignedData.Content = dataToSign;
			retData.data = oSignedData.SignCades(oSigner, config.CADES_TYPE, detachedSignature);
		}
		catch (err) {
			formatErrorData(retData, "error.cryptopro.sign.processing", err);
			edi.core.logMessage("Error during signing. Error: " + getErrorMessage(err), "warn");
		}
		"function" == typeof callback ? callback(retData) : null;
		return retData;
	};
	/**
	 * Creates plugin response data container for main application
	 * @returns {{data: null, error: null}}
	 */
	var createDataContainer = function() {
		return {
			data: null,
			error: null
		};
	};
	/**
	 * Formats error data in response container for main application
	 * @param    {Object}    container
	 * @param    {String}    msg
	 * @param    {Object}    err
	 */
	var formatErrorData = function(container, msg, err) {
		if ("object" != typeof container) {
			container = createDataContainer();
		}
		container.error = {
			typeError: msg,
			additionalData: [
				getErrorMessage(err)
			],
			status: 124
		};
	};
	/**
	 * Checks if certificate could be used for signing
	 * @param cert
	 * @returns {boolean}
	 */
	var checkCertSignAllowed = function(cert) {
		var signAllowed = true;
		try {
			var usageInfo = cert.KeyUsage() || {};
			signAllowed = usageInfo.IsNonRepudiationEnabled
				|| usageInfo.IsContentCommitment
				|| (window.isDevelopment && usageInfo.IsDigitalSignatureEnabled);
		}
		catch (err) {
			edi.core.handleException(getErrorMessage(err));
		}
		return !!signAllowed;
	};
	/**
	 * Creates object required for sign process
	 * @param    {String}    name    name of the object to create
	 * @returns  {*}
	 */
	var createObject = function(name) {
		var obj;
		try {
			if (isIE) {
				try {//For plugins with version 2.0.12538 and higher
					obj = pluginObject2.CreateObject(name);
				}
				catch (e) {
					obj = new ActiveXObject(name);
				}
			}
			else {
				obj = pluginObject.CreateObject(name);
			}
		}
		catch (err) {
			edi.core.logMessage("Error during creation of plugin object - " + getErrorMessage(err), "warn");
		}
		return obj;
	};
	/**
	 * Formats error message
	 * @param e
	 * @returns {*}
	 * @constructor
	 */
	var getErrorMessage = function(e) {
		var err = e.message;
		if (!err) {
			err = e;
		}
		else if (e.number) {
			err += " (" + e.number + ")";
		}
		return err;
	};
	/**
	 * Reads certificates
	 * @returns {*[]}
	 * @constructor
	 */
	var getCertificates = function() {
		var retData = createDataContainer();
		try {
			var oStore = createObject("CAdESCOM.Store");
			oStore.Open();
			retData.data = oStore.Certificates;
			oStore.Close();
			retData.count = retData.data.Count;
		}
		catch (err) {
			formatErrorData(retData, "error.cryptopro.certificates.processing", err);
			edi.core.logMessage("Error during reading certificates from store. Error: " + getErrorMessage(err), "warn");
		}
		return retData;
	};
	/**
	 * Process result from getCertificates
	 * @param    {Object}    data    object containing number of found certificates and special object describing certificate collection
	 * @returns {Array}
	 */
	var processCertificates = function(data) {
		var res = createDataContainer();
		res.data = [];
		if (data && data.count) {
			for (var i = 1; i <= data.count; i++) {
				var oCert = data.data.Item(i);

				var hasPrivateKey = oCert.HasPrivateKey();
				if (hasPrivateKey && oCert.FindPrivateKey) {
					try {
						oCert.FindPrivateKey();
						hasPrivateKey = true;
					}
					catch(e) {
						hasPrivateKey = false;
					}
				}

				if (hasPrivateKey && oCert.IssuerName !== oCert.SubjectName && checkCertSignAllowed(oCert)) {
					res.data.push(oCert);
				}
			}
		}
		return res;
	};
	/**
	 * prepare NPAPI CADES plugin object
	 * @returns    {Boolean}
	 */
	var prepareNPAPIPluginObject = function() {
		if (!cadesObjectCreared) {
			cadesObjectCreared = true;
			var elem = document.createElement('object'), bodyElm = document.getElementsByTagName("body")[0];
			elem.setAttribute("id", HTML_ELEMENT_ID);
			elem.setAttribute("type", "application/x-cades");
			elem.setAttribute("style", "visibility:hidden");
			bodyElm.appendChild(elem);
			pluginObject = elem;
			if (isIE) {
				var elem2 = document.createElement('object');
				elem2.setAttribute("id", HTML_ELEMENT_ID_2);
				elem2.setAttribute("classid", "clsid:B04C8637-10BD-484E-B0DA-B8A039F60024");
				elem2.setAttribute("style", "visibility: hidden");
				bodyElm.appendChild(elem2);
				pluginObject2 = elem2;
			}
		}
		return cadesObjectCreared;
	};
}();
window.cryptoPro = cryptoPro;