/**
 * @author Pavel Pirogov, Vsevolod Liapin
 * Methods for data renderers
 */
Ext.namespace('edi.renderers');

edi.renderers = {
	utils: {},
	/**
	 * generate renderer method that uses template string and map of property paths in record data
	 * @param template
	 * @param map
	 * @param tooltipTemplate
	 * @returns {Function}
	 */
	generate: function (template, map, tooltipTemplate) {
		return function (value, column, record) {
			var values = {};
			var data = record.getData();
			for (var i in map) {
				if (map.hasOwnProperty(i)) {
					values[i] = edi.utils.safeString(edi.utils.getObjectProperty(data, map[i]));
				}
			}
			if (tooltipTemplate) {
				column.tdAttr =
					'data-qtip="' +
					edi.utils.replaceQuotes(edi.utils.formatString(String(tooltipTemplate), values)) +
					'"';
			}
			return edi.utils.formatString(String(template), values);
		};
	},
	certificateSubject: function (value, metaData, record) {
		var retVal = value.lastname
			? value.lastname + (value.firstname ? ' ' + value.firstname : '')
			: value.commonName;
		value.middlename && value.lastname ? (retVal += ' ' + value.middlename) : '';
		value.position ? (retVal += ', ' + value.position) : null;
		value.organization ? (retVal += ', ' + value.organization) : null;
		value.organizationUnit ? (retVal += '(' + value.organizationUnit + ')') : null;
		value.inn ? (retVal += `, ${edi.i18n.getMessage('company.inn.short')} ${value.inn}`) : null;
		value.innle ? (retVal += `, ${edi.i18n.getMessage('company.innle.short')} ${value.innle}`) : null;
		value.state ? (retVal += ', ' + value.state) : null;
		value.location ? (retVal += ', ' + value.location) : null;
		var serial = record && (record.get('serial') || record.get('SerialNumber'));
		serial ? (retVal += ', №' + serial) : null;
		if (metaData) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
		}
		return retVal;
	},
	certificateSubjectQtip: function (subject) {
		let items = [
			{
				title: edi.i18n.getMessage('company.innle.short'),
				value: subject.innle
			},
			{
				title: edi.i18n.getMessage('company.inn.short'),
				value: subject.inn
			},
			{
				title: edi.i18n.getMessage('company.ogrn.short'),
				value: subject.ogrn
			},
			{
				title: edi.i18n.getMessage('company.ogrnip.short'),
				value: subject.ogrnip
			},
			{
				title: edi.i18n.getMessage('organization'),
				value: subject.organization
					? subject.organization + (subject.organizationUnit ? `(${subject.organizationUnit})` : '')
					: null
			},
			{
				title: edi.i18n.getMessage('column.address'),
				value: subject.street
			},
			{
				title: edi.i18n.getMessage('column.city.name'),
				value: subject.location
			},
			{
				title: edi.i18n.getMessage('column.country'),
				value: subject.countryCode && edi.utils.getCountryFULLByISO(subject.countryCode)?.name
			},
			{
				title: edi.i18n.getMessage('full.name.abbr'),
				value: [subject.lastname, subject.firstname, subject.middlename].filter((it) => !!it).join(' ')
			},
			{
				title: edi.i18n.getMessage('certificate.common_name'),
				value: subject.commonName
			}
		];

		let str = items
			.filter((it) => !!it.value)
			.map((it) => `${it.title} = ${it.value}`)
			.map((it, index) => (index === 0 ? it : `<br/>${it}`))
			.join(', ');

		return edi.utils.replaceQuotes(str);
	},
	certificateIssuer: function (value, metaData) {
		var retVal = value.lastname
			? value.lastname + (value.firstname ? ' ' + value.firstname : '')
			: value.commonName;
		value.middlename && value.lastname ? (retVal += ' ' + value.middlename) : '';
		value.position ? (retVal += ', ' + value.position) : null;
		value.organization ? (retVal += ', ' + value.organization) : null;
		value.organizationUnit ? (retVal += '(' + value.organizationUnit + ')') : null;
		value.inn ? (retVal += `, ${edi.i18n.getMessage('company.inn.short')} ${value.inn}`) : null;
		value.innle ? (retVal += `, ${edi.i18n.getMessage('company.innle.short')} ${value.innle}`) : null;
		value.state ? (retVal += ', ' + value.state) : null;
		value.location ? (retVal += ', ' + value.location) : null;
		if (metaData) {
			metaData.tdAttr = 'data-qtip="' + edi.utils.safeString(retVal) + '"';
		}
		return retVal;
	},
	certificateIssuerQtip: function (issuer) {
		let items = [
			{
				title: edi.i18n.getMessage('company.innle.short'),
				value: issuer.innle
			},
			{
				title: edi.i18n.getMessage('company.inn.short'),
				value: issuer.inn
			},
			{
				title: edi.i18n.getMessage('certificate.email'),
				value: issuer.email
			},
			{
				title: edi.i18n.getMessage('company.ogrn.short'),
				value: issuer.ogrn
			},
			{
				title: edi.i18n.getMessage('column.country'),
				value: issuer.countryCode && edi.utils.getCountryFULLByISO(issuer.countryCode)?.name
			},
			{
				title: edi.i18n.getMessage('column.city.name'),
				value: issuer.location
			},
			{
				title: edi.i18n.getMessage('company.address'),
				value: issuer.street
			},
			{
				title: edi.i18n.getMessage('organization'),
				value: issuer.organization
					? issuer.organization + (issuer.organizationUnit ? `(${issuer.organizationUnit})` : '')
					: null
			},
			{
				title: edi.i18n.getMessage('certificate.common_name'),
				value: issuer.commonName
			}
		];

		let str = items
			.filter((it) => !!it.value)
			.map((it) => `${it.title} = ${it.value}`)
			.map((it, index) => (index === 0 ? it : `<br/>${it}`))
			.join(', ');

		return edi.utils.replaceQuotes(str);
	},
	/**
	 * Renders contents of the footer, after translations load
	 */
	renderFooterContents: function (layout) {
		var html = '';
		if (layout.footer && layout.footer.body) {
			html +=
				"<a href='" +
				edi.i18n.getMessage('login.form.footer.help.desk.addr') +
				"' target='_blank' class='edi-login-footer-link'><span>" +
				edi.i18n.getMessage('login.form.footer.help.desk') +
				'</span></a>';
			html +=
				"<a href='" +
				edi.i18n.getMessage('login.form.footer.knowledge.base.addr') +
				"' target='_blank' class='edi-login-footer-link'><span>" +
				edi.i18n.getMessage('login.form.footer.knowledge.base') +
				'</span></a>';
			html +=
				"<a href='mailto:" +
				edi.i18n.getMessage('login.form.footer.email') +
				"' class='edi-login-footer-link edi-login-footer-contact-email'><span>" +
				edi.i18n.getMessage('login.form.footer.email') +
				'</span></a>';
			html +=
				"<a href='callto:" +
				edi.i18n.getMessage('login.form.footer.email') +
				"' class='edi-login-footer-link edi-login-footer-contact-phone'><span>" +
				edi.i18n.getMessage('login.form.footer.phone') +
				'</span></a>';
			layout.footer.body.setHtml(html);
		}
	},
	renderHeaderContents: function (layout) {}
};
