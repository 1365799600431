Ext.namespace('edi.renderers');
Ext.merge(edi.renderers, {
	massInvitationStatus: function (value, meta) {
		const key = 'mass.invitation.status.';
		return edi.renderers.baseStateRenderer(value, edi.i18n.getMessage(key + value), meta);
	},
	_invitationStatus(invitationData, direction = null) {
		let state = invitationData.state;
		let statusKey = 'documents.status.';
		if (
			state === edi.constants.INVITATIONS.STATE.COMPLETED ||
			state === edi.constants.INVITATIONS.STATE.SENT ||
			state === edi.constants.INVITATIONS.STATE.REJECTED ||
			state === edi.constants.INVITATIONS.STATE.REVOKE
		) {
			statusKey += 'invitation.';
		}
		statusKey += state;

		let dir = direction
			? direction
			: edi.utils.getDocumentDirection(invitationData.ediDocument.toOrg, invitationData.ediDocument.fromOrg);

		if (state === edi.constants.STATE.SENT && dir === edi.constants.DIRECTIONS.INCOMING) {
			statusKey += `.${dir}`;
		}

		return { state, statusKey };
	},
	employeesStatus(employeesData) {
		let state = employeesData.status;
		let statusKey = 'documents.status.';
		if (
			state === edi.constants.ORG_PROFILE.EMPLOYEES.STATE.NOT_CONFIRMED ||
			state === edi.constants.ORG_PROFILE.EMPLOYEES.STATE.CONFIRMED ||
			state === edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DEACTIVATED ||
			state === edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DELETED
		) {
			statusKey += 'employees.';
		}
		statusKey += state;

		return { state, statusKey };
	},
	renderExecutorForApproval: function (executors) {
		var getExecutorName = function (executor) {
			if (executor.employee) {
				let employeeValues = edi.renderers.getUserInfo(executor.employee);
				return `${employeeValues.lastName || ''} ${employeeValues.firstName || ''} ${
					employeeValues.middleName || ''
				}`;
			}
			if (executor.subdivision) {
				return edi.utils.getObjectProperty(executor, 'subdivision.name');
			}
		};

		if (executors.length === 1) {
			var executor = executors[0];
			return getExecutorName(executor);
		} else {
			var name = '';
			executors.forEach(function (executor, index) {
				name += index > 0 ? ', ' + getExecutorName(executor) : getExecutorName(executor);
			});
			return name;
		}
	},
	getLinkPicture: function () {
		return edi.i18n.getMessage('login.form.footer.link.picture');
	},
	openLinkPicture: function () {
		const url = edi.renderers.getLinkPicture();
		window.open(url, '_blank');
	},
	renderFooterContents: function (layout) {
		if (layout.footer && layout.footer.body) {
			const currentYear = new Date().getFullYear();
			let html = Ext.DomHelper.markup({
				cls: 'ui-login-footer',
				role: 'presentation',
				cn: [
					{
						cls: 'ui-login-footer-text',
						tag: 'span',
						html: '©&nbsp;'
					},
					{
						tag: 'a',
						cls: 'ui-login-footer-text',
						href: edi.renderers.getLinkPicture(),
						target: '_blank',
						html: 'Ediweb,'
					},
					{
						cls: 'ui-login-footer-text',
						tag: 'span',
						html: `&nbsp;1999–${currentYear}`
					}
				]
			});

			layout.footer.body.setHtml(html);
		}
	},
	/**
	 * Renders contents of the header, after translations load
	 */
	renderHeaderContents: function (layout) {
		if (layout.header && layout.header.body) {
			let html = Ext.DomHelper.markup({
				cls: 'ui-login-header',
				role: 'presentation',
				cn: [
					{
						tag: 'a',
						cls: 'ui-login-header-text',
						href: edi.i18n.getMessage('login.form.footer.help.desk.addr'),
						target: '_blank',
						html: edi.i18n.getMessage('login.form.free.support')
					},
					{
						tag: 'a',
						href: edi.i18n.getMessage('login.form.footer.link.picture'),
						target: '_blank',
						cn: [
							{
								tag: 'span',
								cls: 'ui-login-header-logo'
							}
						]
					},
					{
						tag: 'a',
						cls: 'ui-login-header-text',
						href: `tel: ${edi.i18n.getMessage('ediweb.login.form.footer.phone').replace(/\s/g, '')}`,
						html: edi.i18n.getMessage('ediweb.login.form.footer.phone')
					}
				]
			});

			layout.header.body.setHtml(html);
		}
	},
	getUserInfo: function (record) {
		let recData = (record?.getData ? record.getData() : record) || {};
		let isEmployeeMD = !!recData.user;
		return {
			firstName: isEmployeeMD ? recData.user.FirstName : recData.firstName,
			middleName: isEmployeeMD ? recData.user.MiddleName : recData.middleName,
			lastName: isEmployeeMD ? recData.user.LastName : recData.lastName,
			email: isEmployeeMD ? recData.user.Email : recData.email
		};
	},
	getProductLogo: function (product) {
		if (product) {
			let productsData = edi.stores.initAllProductList().data.items?.find(function (item) {
				return item.get('name') === product;
			});

			const cls = 'invitations-products';

			return Ext.DomHelper.markup({
				cls,
				cn: [
					{
						tag: 'span',
						cls: `${cls}-logo ${cls}-logo-${product}`
					},
					productsData.get('name') !== 'ediweb'
						? {
								tag: 'span',
								cls: `${cls}-name`,
								html: productsData.get('label')
						  }
						: null
				]
			});
		}
	}
});

if (edi.renderers.utils && edi.renderers.utils.modifyStateTitleKeys) {
	edi.renderers.utils.modifyStateTitleKeys.push(function (getData, direction, doctype, attributes, modifyValues) {
		var annulStatus = edi.utils.getAttributeByName(attributes, 'ANNUL_STATUS'),
			opposingAnnulStatus = edi.utils.getAttributeByName(attributes, 'OPPOSING_ANNUL_STATUS'),
			isAnnulStatCreated =
				annulStatus === edi.constants.STATE.CREATED || opposingAnnulStatus === edi.constants.STATE.CREATED;

		if (isAnnulStatCreated) {
			modifyValues.styleSfx = 'CREATED_';
		}
		if (modifyValues.retValue === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL_BY_RECEIVER) {
			modifyValues.addition = direction;
			modifyValues.styleSfx = direction + '_';
		}

		if (
			annulStatus === 'ACTIVE' &&
			modifyValues.retValue === edi.constants.APPROVAL_DOCUMENTS.STATE.SENT &&
			edi.constants.FREE_DOCUMENT_TYPES.some((it) => it === doctype)
		) {
			modifyValues.retValue = 'ANNUL';
			modifyValues.addition = 'ACTIVE';
		}
	});

	edi.renderers.utils.modifyStateTitleKeys.push(function (getData, direction, doctype, attributes, modifyValues) {
		if (
			modifyValues.retValue === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL_BY_RECEIVER &&
			Ext.Array.contains(edi.constants.DSF_WITH_APPROVE, doctype)
		) {
			let actualStatus = edi.utils.getAttributeByName(attributes, 'ACTUAL_STATUS');
			if (direction === edi.constants.DIRECTIONS.OUTGOING) {
				if (actualStatus === edi.constants.STATE.DRAFT || actualStatus === edi.constants.STATE.SENDER_REVIEW) {
					modifyValues.addition = 'INCOMING';
				} else {
					modifyValues.addition = 'OUTGOING';
				}
			} else {
				modifyValues.addition = 'INCOMING';
			}
		}
	});

	edi.renderers.utils.modifyStateTitleKeys.push(function (getData, direction, doctype, attributes, modifyValues) {
		if (
			modifyValues.retValue === edi.constants.STATE.REVOKED &&
			doctype === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY
		) {
			modifyValues.styleSfx = doctype + '_';
		}
	});
}
