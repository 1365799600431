Ext.define('edi.ws.WebSocket', {
	url: '',
	protocols: undefined,
	queryParams: {},
	autoReconnect: true,
	reconnectThrottleTimeout: 3 * 1000, //delay before reconnection
	autoReconnectMaxAttempts: 0, //0=infinity
	adaptiveReconnectCfg: {
		n1: 5,
		n1ReconnectThrottleTimeout: 10 * 1000, //is applied when n1 <= __autoReconnectAttempts < n2
		n2: 20,
		n2ReconnectThrottleTimeout: 10 * 1000 //is applied when __autoReconnectAttempts > n2
	},

	enableCheckConnection: false,
	pingMessage: 'ping',
	pongMessage: 'pong',
	pingTimeout: 5 * 1000,

	state: null,
	_states: {
		CONNECTING: 'CONNECTING',
		CONNECTED: 'CONNECTED',
		CLOSED: 'CLOSED'
	},
	// пользовательский хэндлер
	onopen() {},
	// пользовательский хэндлер
	onmessage() {},
	// пользовательский хэндлер
	onerror() {},
	// пользовательский хэндлер
	onclose() {},
	// пользовательский хэндлер
	onMaxReconnect() {},
	// пользовательский хэндлер
	onStateChange() {},

	constructor(cfg) {
		this.createSocket(cfg);
	},

	/**
	 * Rebuilds WebSocket and bind handlers which is always stored in this.originalWebSocket
	 * @param	{Object}	[cfg]
	 */
	createSocket(cfg) {
		let me = this;
		Ext.merge(me, cfg);
		//чистим старый сокет если был создан, что бы события не срабатывали и закрываем его
		let ws = me.originalWebSocket;
		if (ws) {
			let empty = () => {};
			ws.onopen = empty;
			ws.onmessage = empty;
			ws.onerror = empty;
			ws.onclose = empty;
			ws.close();
			me.originalWebSocket = null;
		}
		clearTimeout(me._pingTimer);
		//создаем новый сокет взамен старого (если он был)
		try {
			me.logConnection(`WS: Try to connect to ${this.compileUrl()}`);
			ws = new WebSocket(me.compileUrl(), me.protocols);
			me.originalWebSocket = ws;
			ws.onopen = me._onOpen.bind(me);
			ws.onmessage = me._onMessageReceive.bind(me);
			ws.onerror = me._onError.bind(me);
			ws.onclose = me._onClose.bind(me);
			me.state = me._states.CONNECTING;
			me.logConnection(`WS: Connecting to ${this.compileUrl()}`);
			me.onStateChange(me, me.state);
		} catch (err) {
			me.reconnect();
		}
	},

	/**
	 * Logging connection state changing
	 */
	logConnection: function (txt) {
		edi.core.logMessage(txt, 'warn');
	},

	/**
	 * Prepare relative this.url for usage in constructor
	 * @returns	{String}
	 */
	compileUrl() {
		let me = this;

		let loc = window.location;
		let protocol = loc.protocol === 'https:' ? 'wss://' : 'ws://';
		let prefix = me.urlPrefix || edi.constants.DEFAULT.WS_PREFIX;
		let relativePath = me.url || '';
		let url = `${protocol}${loc.host}${prefix}${relativePath}`;

		let params = me.queryParams || {};
		if (params.authType === null) {
			delete params.authType;
		} else {
			params.authType = params.authType || edi.constants.AUTH_TYPE;
		}

		return edi.utils.compileURL(url, params);
	},

	/**
	 * Internal handler for onopen event, starts auto check
	 * @private
	 */
	_onOpen() {
		let me = this;
		me.__autoReconnectAttempts = 0;

		clearTimeout(me._pingTimer);
		if (me.enableCheckConnection === true) {
			me._pingTimer = setTimeout(me.checkConnection.bind(me), me.pingTimeout);
		}
		me.state = me._states.CONNECTED;
		me.logConnection(`WS: Connection established ${this.compileUrl()}`);
		me.onStateChange(me, me.state);
		me.onopen.apply(me, arguments);
	},

	/**
	 * Internal handler for onmessage event, filters ping-pong messages
	 * @private
	 */
	_onMessageReceive(message) {
		let me = this;
		me.__lastMessageTime = Date.now().valueOf();

		if (message.data === me.pongMessage) {
		} else if (message.data === me.pingMessage) {
			me.send(me.pongMessage);
		} else {
			me.onmessage.apply(me, arguments);
		}
	},

	/**
	 * Internal handler for onerror event, avoid errors while reconnecting
	 * @private
	 */
	_onError(e) {
		let me = this;
		if (e?.code === 'ECONNREFUSED' && !me.reconnect()) {
			me.state = me._states.CLOSED;
			me.logConnection(`WS: Connection closed ${this.compileUrl()}`);
			me.onStateChange(me, me.state);
			me.onerror.apply(me, arguments);
		}
	},

	closeStatuses: [1000, 1001],

	/**
	 * Internal handler for onclose event, avoid errors while reconnecting
	 * @private
	 */
	_onClose(e) {
		let me = this;
		let code = e.code;
		// пробуем реконнект и если он не прошел то переходим в состояние "закрыто"
		if (!me.reconnect()) {
			me.state = me._states.CLOSED;
			me.logConnection(`WS: Connection closed ${this.compileUrl()}`);
			me.onStateChange(me, me.state);
			me.onclose.apply(me, arguments);
		}
	},

	/**
	 * Checks infinite reconnect is enabled
	 * @returns	{boolean}	true=enabled
	 * @private
	 */
	_isInfiniteReconnectsEnabled: function () {
		return this.autoReconnectMaxAttempts === 0;
	},

	/**
	 * Gets delay before reconnects based on reconnect attempts count
	 * @returns	{Number}	delay in ms
	 * @private
	 */
	_getReconnectDelay: function () {
		const me = this;
		if (me._isInfiniteReconnectsEnabled()) {
			const cfg = me.adaptiveReconnectCfg;
			if (me.__autoReconnectAttempts < cfg.n1) {
				return me.reconnectThrottleTimeout;
			} else if (me.__autoReconnectAttempts >= cfg.n1 && me.__autoReconnectAttempts < cfg.n2) {
				return cfg.n1ReconnectThrottleTimeout;
			} else if (me.__autoReconnectAttempts >= cfg.n2) {
				return cfg.n2ReconnectThrottleTimeout;
			} else {
				return me.reconnectThrottleTimeout;
			}
		} else {
			return me.reconnectThrottleTimeout;
		}
	},

	/**
	 * Copy of Ext.Function.createThrottled with newInterval setting
	 * @param	{Function}	fn
	 * @param	{Number}	interval
	 * @param	{Object}	[scope]
	 * @returns	{{
	 *     setNewInterval: function(number): void,
	 *     throttledFn: function(): void
	 * }}
	 * @private
	 */
	_createThrottled: function (fn, interval, scope) {
		var lastCallTime = 0,
			elapsed,
			lastArgs,
			timerId,
			execute = function () {
				fn.apply(scope, lastArgs);
				lastCallTime = Ext.now();
				lastArgs = timerId = null;
			};
		execute.$origFn = fn.$origFn || fn;
		execute.$skipTimerCheck = execute.$origFn.$skipTimerCheck;
		return {
			setNewInterval: function (newInterval) {
				if (Number.isFinite(newInterval) && newInterval > 0 && interval !== newInterval) {
					interval = newInterval;
				}
			},
			throttledFn: function () {
				// Use scope of last call unless the creator specified a scope
				if (!scope) {
					scope = this;
				}
				elapsed = Ext.now() - lastCallTime;
				lastArgs = [...arguments];
				// If this is the first invocation, or the throttle interval has been reached,
				// clear any pending invocation, and call the target function now.
				if (elapsed >= interval) {
					Ext.undefer(timerId);
					execute();
				}
				// Throttle interval has not yet been reached. Only set the timer to fire
				// if not already set.
				else if (!timerId) {
					timerId = Ext.defer(execute, interval - elapsed);
				}
			}
		};
	},

	/**
	 * Tries to reconnect (recreate new socket) if it is available
	 * @param	{Object}	[options]
	 * @param	{Boolean}	[options.immediate]		true=reconnect without delay
	 * @return	{boolean}	true=reconnecting, false=not available
	 */
	reconnect(options) {
		let me = this;

		if (!me.__autoReconnectAttempts) {
			me.__autoReconnectAttempts = 0;
		}
		if (!me.autoReconnectMaxAttempts) {
			me.autoReconnectMaxAttempts = 0;
		}

		let allowToReconnect =
			options?.force === true ||
			(me.autoReconnect === true &&
				(me._isInfiniteReconnectsEnabled() || me.__autoReconnectAttempts < me.autoReconnectMaxAttempts));

		if (!allowToReconnect && me.__autoReconnectAttempts >= me.autoReconnectMaxAttempts) {
			me.logConnection(`WS: Maximum of reconnect attempts is reached ${this.compileUrl()}`);
			me.onMaxReconnect(me);
		}

		if (allowToReconnect) {
			if (options?.immediate === true) {
				me._reconnect(options);
			} else {
				const delay = me._getReconnectDelay();
				if (!me._reconnectThrottled) {
					const { setNewInterval, throttledFn } = me._createThrottled(me._reconnect, delay, me);
					me._setNewThrottleInterval = setNewInterval;
					me._reconnectThrottled = throttledFn;
				}
				me._setNewThrottleInterval(delay);
				me._reconnectThrottled();
			}
		}

		return allowToReconnect;
	},

	/**
	 * Reconnects (recreate new socket) if it is available
	 * @param	{{immediate?: Boolean}}	[options]
	 * @param	{Boolean}	[options.force]		true=reset attempts count and reconnect
	 * @private
	 */
	_reconnect(options) {
		let me = this;

		if (options?.force === true || me._isInfiniteReconnectsEnabled()) {
			me.__autoReconnectAttempts++;
			me.createSocket();
		} else {
			if (me.__autoReconnectAttempts < me.autoReconnectMaxAttempts) {
				me.__autoReconnectAttempts++;
				me.createSocket();
			}
		}
	},

	/**
	 * Returns true when socket is ready for sending/receiving
	 * @returns	{boolean}	true=ready
	 */
	isReady() {
		return this.originalWebSocket?.readyState === 1;
	},

	/**
	 * Tries to send message
	 * @param	{String}	message
	 * @returns	{boolean}	true=successfully sent, false=failed
	 */
	send(message) {
		let me = this;
		if (!me.originalWebSocket) {
			edi.core.logMessage('edi.ws: cannot send message because socket not exists', 'error');
			return false;
		} else if (!me.isReady()) {
			edi.core.logMessage('edi.ws: cannot send message because socket not opened', 'error');
			return false;
		} else {
			try {
				me.originalWebSocket.send(message);
				return true;
			} catch (err) {
				edi.core.logMessage(err, 'error');
				return false;
			}
		}
	},

	/**
	 * Send ping and check lastMessageTime by timer
	 */
	checkConnection() {
		let me = this;
		me.send(me.pingMessage);

		if (!me.__lastMessageTime) {
			me.__lastMessageTime = Date.now().valueOf();
		}
		let msPastFromLastMessage = Date.now().valueOf() - me.__lastMessageTime;
		if (msPastFromLastMessage <= me.pingTimeout * 3) {
			if (me.enableCheckConnection === true) {
				me._pingTimer = setTimeout(me.checkConnection.bind(me), me.pingTimeout);
			}
		} else {
			me.reconnect({ force: true });
		}
	}
});

const createWebSocket = function (cfg) {
	return Ext.create('edi.ws.WebSocket', cfg);
};

export { createWebSocket };
