import { createProxyConfig } from '@Components/storeComponents';

Ext.namespace('edi.filters');
edi.filters = new (function () {
	this.config = {};
	/**
	 * Creates in memory filter processing
	 * @param filterForm
	 * @param grid
	 * @param createFilters
	 * @param additionalValues
	 * @param config
	 */
	this.createMemoryGridFilter = function (filterForm, grid, createFilters, additionalValues, config) {
		config = config || {};
		var gridFilter = {
				filterForm: filterForm,
				grid: grid,
				createFilters: createFilters,
				searchTimeout: null
			},
			defaultCreateFilter = function (values) {
				var filters = [],
					i;
				for (i in values) {
					if (values.hasOwnProperty(i)) {
						filters.push({
							property: i,
							value: values[i],
							anyMatch: true
						});
					}
				}
				return filters;
			},
			autoFilterCleanUp = function (filters) {
				var retArr = [];
				for (var i = 0; i < filters.length; i++) {
					if (filters[i].property !== 'filterFormAutoSearchCheckbox') {
						retArr.push(filters[i]);
					}
				}
				return retArr;
			};
		gridFilter.filter = function () {
			if (gridFilter.searchTimeout) {
				clearTimeout(gridFilter.searchTimeout);
			}
			gridFilter.searchTimeout = setTimeout(function () {
				if (gridFilter.filterForm.isValid()) {
					var values = gridFilter.filterForm.getValues();
					if (!filterForm.disableChips) {
						if (typeof gridFilter.filterForm.processHeaderChips === 'function') {
							gridFilter.filterForm.processHeaderChips(gridFilter.filterForm, values);
						}
					}
					if (additionalValues) {
						for (var i in additionalValues) {
							if (additionalValues.hasOwnProperty(i) && !values[i]) {
								values[i] = additionalValues[i];
							}
						}
					}
					var store = gridFilter.grid.getStore();
					var filters = autoFilterCleanUp(
						'function' == typeof gridFilter.createFilters
							? gridFilter.createFilters(values, gridFilter)
							: defaultCreateFilter(values)
					);
					if (filters.length) {
						store.clearFilter(true);
						store.filter(filters);
					} else {
						store.clearFilter();
					}
				}
			}, edi.constants.DEFAULT.FILTER.FIRE_SEARCH_DELAY);
		};
		return gridFilter;
	};
	/**
	 * Creates filter processor, that initiates search process
	 * @param url
	 * @param filterForm
	 * @param grid
	 * @param createArgs
	 * @param additionalValues
	 * @param config
	 * @returns {{oldRequest: null, filterForm: *, grid: *, createArgs: *, url: *, searchTimeout: null, proxyConfig}}
	 */
	this.createGridFilter = function (url, filterForm, grid, createArgs, additionalValues, config) {
		config = config || {};
		var gridFilter = {
			oldRequest: null,
			filterForm: filterForm,
			grid: grid,
			createArgs: createArgs,
			url: url,
			searchTimeout: null,
			proxyConfig: config.proxyConfig,
			onRequestStart: config.onRequestStart
		};

		let saveAndRestoreIsEnabled =
			edi.constants.RESTORE_FILTER_VALUES_ENABLED &&
			config?.persistence?.enabled === true &&
			edi.core.getExtraData('user.saveFilter') === 'true';
		let filtersSaveConfName = `filters.${config?.persistence?.name || 'modulename_gridname'}`;
		if (saveAndRestoreIsEnabled) {
			edi.filters.restoreFilterFormValues(gridFilter.filterForm, filtersSaveConfName);
		} else if (typeof gridFilter.filterForm.setFormDefaults === 'function') {
			gridFilter.filterForm.setFormDefaults();
		}

		gridFilter.filter = function () {
			if (gridFilter.searchTimeout) {
				clearTimeout(gridFilter.searchTimeout);
			}
			gridFilter.searchTimeout = setTimeout(function () {
				if (!gridFilter.filterForm.isDestroyed && gridFilter.filterForm.isValid()) {
					var values = gridFilter.filterForm.getValues();
					if (!filterForm.disableChips) {
						if (typeof gridFilter.filterForm.processHeaderChips === 'function') {
							gridFilter.filterForm.processHeaderChips(gridFilter.filterForm, values);
						}
					}
					if (additionalValues) {
						for (var i in additionalValues) {
							if (additionalValues.hasOwnProperty(i) && !values[i]) {
								values[i] = additionalValues[i];
							}
						}
					}
					var store = gridFilter.grid.getStore();
					var oldProxy = store.getProxy();
					var url = 'function' == typeof gridFilter.url ? gridFilter.url(values) : gridFilter.url;
					if (gridFilter.oldRequest) {
						edi.rest.abortRequest(oldProxy.url);
						gridFilter.oldRequest = null;
					}
					var args =
						'function' == typeof gridFilter.createArgs ? gridFilter.createArgs(values, gridFilter) : values;
					if (args) {
						delete args.filterFormAutoSearchCheckbox;
						url = edi.utils.compileURL(url, args);
					}

					if (saveAndRestoreIsEnabled && filtersSaveConfName) {
						let filterValues = Ext.clone(edi.filters.getValueWithRange(gridFilter.filterForm));
						edi.utils.clearEmptyValues(filterValues);
						let oldValues = JSON.stringify(edi.utils.getData(filtersSaveConfName) || {});
						let newValues = JSON.stringify(filterValues || {});
						if (newValues !== oldValues) {
							edi.utils.setData(filtersSaveConfName, newValues);
						}
					}

					gridFilter.oldRequest = String(url);
					var proxyConfig = Object.assign({}, oldProxy.initialConfig, {
						type: 'ajax',
						url: url
					});
					if (config.preventExceptions) {
						proxyConfig.listeners = Object.assign({}, proxyConfig.listeners, {
							exception: function () {
								//do nothing
							}
						});
					}

					if (gridFilter.proxyConfig) {
						Ext.applyIf(proxyConfig, gridFilter.proxyConfig);
					}
					store.setProxy(createProxyConfig(proxyConfig));
					if (1 != store.currentPage) {
						gridFilter.grid.pagingBar.moveFirst();
					} else {
						store.load(function () {
							gridFilter.oldRequest = null;
						});
					}
					if ('function' == typeof gridFilter.onRequestStart) {
						gridFilter.onRequestStart(args);
					}
				}
			}, edi.constants.DEFAULT.FILTER.FIRE_SEARCH_DELAY);
		};
		return gridFilter;
	};

	/**
	 * Set values to filter's form
	 * @param	{Object}	form
	 * @param	{Object}	values
	 */
	this.defaultRestoreFilterValuesMethod = function (form, values) {
		let ranges = values.ranges;
		for (let key in ranges) {
			if (ranges.hasOwnProperty(key)) {
				const oldDateRangeField = form.down('datefieldranged[name=' + key + ']')?.up('edi-date-range');
				if (typeof oldDateRangeField?.setRange === 'function') {
					oldDateRangeField.setRange(ranges[key]);
				}
				const newDateRangeField = form.down('datefield[name=' + key + ']')?.up('daterangefield');
				if (typeof newDateRangeField?.activatePeriod === 'function') {
					newDateRangeField.activatePeriod(ranges[key]);
				}
			}
		}
		let inputs = form.query('field');
		inputs.forEach((inp) => {
			if (inp.name && values.hasOwnProperty(inp.name)) {
				if (inp.xtype === 'datefieldranged' || inp.xtype === 'datefield') {
					inp.setValue(new Date(+values[inp.name]));
				} else {
					inp.setValue(values[inp.name]);
				}
			}
		});
	};

	/**
	 * Set values on filter's form from saved in extra_info by config name
	 * @param	{Object}	form
	 * @param	{String}	configName
	 * @param	{Function}	setValuesFn
	 */
	this.restoreFilterFormValues = function (form, configName, setValuesFn) {
		if (!form || !configName) {
			return;
		}

		let values = edi.utils.getData(configName);
		if (typeof values === 'string' && values.length > 0) {
			values = Ext.decode(values, true) || {};

			let setValues =
				typeof setValuesFn === 'function' ? setValuesFn : edi.filters.defaultRestoreFilterValuesMethod;
			setValues(form, values);
		} else if (typeof form.setFormDefaults === 'function') {
			form.setFormDefaults();
		}
	};

	/**
	 * Get the value to store filters based on range of dataRange elements
	 */
	this.getValueWithRange = function (form) {
		const formValues = form.getValues();
		edi.utils.clearEmptyValues(formValues);
		const ranges = {};
		const oldDateRanges = form.query('edi-date-range');
		oldDateRanges.forEach((dateRange) => {
			if (dateRange.getActiveRange()) {
				let inputs = dateRange.query('datefieldranged');
				ranges[inputs[0].name] = dateRange.getActiveRange();
				inputs.forEach((inp) => {
					delete formValues[inp.name];
				});
			}
		});
		const newDateRanges = form.query('daterangefield');
		newDateRanges.forEach((dateRange) => {
			if (dateRange.hasSelectedPeriod && dateRange.getCurrentPeriod()) {
				let inputs = dateRange.query('datefield');
				ranges[inputs[0].name] = dateRange.getCurrentPeriod();
				inputs.forEach((inp) => {
					delete formValues[inp.name];
				});
			}
		});
		formValues.ranges = ranges;
		return formValues;
	};
})();
