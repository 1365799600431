/**
 * Global events managers
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.events');
/**
 * Module events manager
 */
edi.events.module = new Ext.util.Observable();
//edi.events.module.addEvents({
//	"beforeClassLoad": true,
//	"classLoad": true,
//	"classUnLoad": true,
//	"moduleInit": true,
//	"moduleRemove": true,
//	"moduleDestroy": true,
//	"moduleActivated": true
//});
/**
 * Modules events manager
 */
edi.events.modules = new Ext.util.Observable();
//edi.events.modules.addEvents({
//	"modulesLoad": true,
//	"rendered": true
//});

/**
 * Invitation events
 * @type {Ext.util.Observable}
 */
edi.events.invitation = new Ext.util.Observable();
//edi.events.invitation.addEvents({
//	"change": true
//});
/**
 * Certificate events
 * @type {Ext.util.Observable}
 */
edi.events.certificate = new Ext.util.Observable();
//edi.events.certificate.addEvents({
//	"change": true
//});
/**
 * File storage events
 * @type {Ext.util.Observable}
 */
edi.events.fileStorage = new Ext.util.Observable();
//edi.events.fileStorage.addEvents({
//	"change": true
//});

/**
 * Notification timer events
 * @type {Ext.util.Observable}
 */
edi.events.notificationTimer = new Ext.util.Observable();
//edi.events.notificationTimer.addEvents({
//	"update": true
//});
/**
 * Fs rule events
 * @type {Ext.util.Observable}
 */
edi.events.fsRule = new Ext.util.Observable();
//edi.events.fsRule.addEvents({
//	"change": true
//});
/**
 * Archive objects events
 * @type {Ext.util.Observable}
 */
edi.events.archiveObjects = new Ext.util.Observable();
//edi.events.archiveObjects.addEvents({
//	"change": true
//});
/**
 * Number document generation events
 * @type {Ext.util.Observable}
 */
edi.events.numberDocGen = new Ext.util.Observable();
//edi.events.numberDocGen.addEvents({
//	"change": true
//});
/**
 * Catalog events
 * @type {Ext.util.Observable}
 */
edi.events.catalog = new Ext.util.Observable();
//edi.events.catalog.addEvents({
//	"change": true
//});
/**
 * Route events
 * @type {Ext.util.Observable}
 */
edi.events.route = new Ext.util.Observable();
//edi.events.route.addEvents({
//	"change": true
//});

/**
 * Documents events
 */
edi.events.documents = new Ext.util.Observable();
//edi.events.documents.addEvents({
//	"create": true,
//	"change": true,
//	"sign": true,
//	"delete": true,
//	"reject": true
//});

/**
 * Document packages events
 */
edi.events.documentPackages = new Ext.util.Observable();
//edi.events.documentPackages.addEvents({
//	"create": true,
//	"change": true,
//	"delete": true
//});

/**
 * Core events
 */
edi.events.login = new Ext.util.Observable();
//edi.events.login.addEvents({
//	"userLoad": true,
//	"userOrganizationLoad": true,
//	"userChange": true
//});

edi.events.readingFromEhd = new Ext.util.Observable();
//edi.events.readingFromEhd.addEvents({
//	"change": true
//});

edi.events.powerOfAttorney = new Ext.util.Observable();
