import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createModalForm } from '@UIkit/components/panels';
import { createLabel } from '@UIkit/components/fields';
import { createGrid } from '@UIkit/components/grid';
import { showMessageBox } from '@UIkit/components/modal/MessageBox';

const convertIso3ToIso2 = (value) => {
	if (!value) {
		return value;
	}
	const countryStore = edi.stores.initValidCountryFullStore();
	const record = countryStore?.findRecord(`iso_3`, value);
	return record?.getData()?.iso_2 || value;
};

const createCurrencyStore = function (onload) {
	const store = edi.stores.data.okv_currencies.map((okv_currency) => ({
		id: okv_currency.charCode,
		name: `${okv_currency.charCode} - ${okv_currency.code} - ${okv_currency.name}`
	}));
	return edi.stores.createInlineStore(store, 'SIMPLE', onload, {
		addEmptyRecord: true
	});
};

const documentsModal = function (config, data, moduleData, action) {
	let buttons = [];

	let okBtn = createButton(
		Ext.applyIf(config.okButtonConfig || {}, {
			cls: BUTTON_CLS.primary,
			text: edi.i18n.getMessage('button.ok'),
			glyph: edi.constants.ICONS.APPROVE,
			handler: function () {
				modal.close();
				if ('function' == typeof action) {
					action();
				}
			}
		})
	);

	buttons.push(okBtn);

	if (config.confirm) {
		buttons.push(
			createButton({
				cls: BUTTON_CLS.secondary,
				text: edi.i18n.getMessage('btn.cancel'),
				handler: function () {
					modal.close();
				}
			})
		);
	}

	let defaultConf = {
		title: edi.i18n.getMessage(config.title),
		width: MODAL_SIZE.widthMedium || config.width,
		maxHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
		items: createModalForm({
			items: [
				createLabel({
					typography: 'body-short_01',
					html: edi.i18n.getMessage(config.text)
				}),
				createGrid({
					store: edi.stores.createMemoryStore(data, config.model),
					gridConfig: {
						cls: 'ui-documents-list',
						maxHeight: 500,
						disablePaging: true,
						columns: config.columns,
						hideSettingsButton: true
					}
				})
			]
		}),
		buttons: buttons,
		listeners: {
			close: function () {
				moduleData.tab.setLoading(false);
			}
		}
	};

	Ext.apply(defaultConf, config);

	let modal = showMessageBox(defaultConf);
	return modal;
};

const isDSFV3 = function (recordData) {
	const bpName = edi.utils.getAttributeByName(recordData.attributes, 'bpName');
	const type = recordData.type;
	return (
		edi.constants.REJECT_DSFV3.some((it) => it === type) &&
		!(bpName && edi.constants.REJECT_PUSH_BP_NAMES.some((it) => it === bpName))
	);
};

export { convertIso3ToIso2, createCurrencyStore, documentsModal, isDSFV3 };
