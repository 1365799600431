/**
 * Class for UI permissions processing
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.permissions');

edi.permissions = new (function () {
	var permissions = {},
		uiPermissions = {},
		__self = this,
		permissionsLoaded = false;
	/**
	 * Permissions initialisation method
	 * @param callback
	 */
	this.init = function (callback) {
		loadPermissions(callback);
		afterInit();
	};
	/**
	 * Sets UI permissions map
	 * @param    {Object}    permissions    permissions map
	 * @param    {Object}    extendMap      permission map to extend or create with passed params if not present
	 */
	this.setUiPermissions = function (permissions, extendMap) {
		var i, j;
		Object.assign(uiPermissions, permissions);
		if (extendMap) {
			for (i in extendMap) {
				if (extendMap.hasOwnProperty(i)) {
					if (!uiPermissions.hasOwnProperty(i)) {
						//Whole section absent
						uiPermissions[i] = extendMap[i];
					} else if (Array.isArray(extendMap[i])) {
						//List of groups as collection (default AND)
						uiPermissions[i] = [...new Set([...uiPermissions[i], ...extendMap[i]])];
					} else {
						//Iteration over AND/OR props
						for (j in extendMap[i]) {
							if (extendMap[i].hasOwnProperty(j)) {
								if (!uiPermissions[i][j]) {
									//Some part absent
									uiPermissions[i][j] = extendMap[i][j];
								} else {
									//AND or OR merging
									uiPermissions[i][j] = [...new Set([...uiPermissions[i][j], ...extendMap[i][j]])];
								}
							}
						}
					}
				}
			}
		}
	};
	/**
	 * Report true if permissions are empty
	 * @returns {boolean}
	 */
	this.isEmpty = function () {
		return Object.keys(permissions).length === 0;
	};
	/**
	 * Report true if permissions are loaded
	 * @returns {boolean}
	 */
	this.isLoaded = function () {
		return permissionsLoaded;
	};
	/**
	 * Function that checks if user has all passed permissions
	 * @param    {Array|Object}    names    collection of permission names or object containing OR/AND properties, that holds collections of permissions
	 */
	this.hasPermissions = function (names) {
		var granted = false,
			grantedAnd,
			grantedOr,
			i;

		if (names && names.length) {
			granted = true;
			for (i = 0; i < names.length; i++) {
				if (!this.hasPermission(names[i])) {
					granted = false;
					break;
				}
			}
		} else if (names) {
			if (names.AND && names.AND.length) {
				grantedAnd = true;
				for (i = 0; i < names.AND.length; i++) {
					if (!this.hasPermission(names.AND[i])) {
						grantedAnd = false;
						break;
					}
				}
			}
			if (names.OR && names.OR.length && ('undefined' == typeof grantedAnd || true === grantedAnd)) {
				grantedOr = false;
				for (i = 0; i < names.OR.length; i++) {
					if (this.hasPermission(names.OR[i])) {
						grantedOr = true;
						break;
					}
				}
			}
			if ('undefined' != typeof grantedAnd && 'undefined' != typeof grantedOr) {
				granted = grantedAnd && grantedOr;
			} else {
				granted =
					'undefined' != typeof grantedAnd ? grantedAnd : 'undefined' != typeof grantedOr ? grantedOr : false;
			}
		}
		return granted;
	};
	/**
	 * function that check permission present
	 * @param    {String}    name    name of the UI permission to check
	 * @returns  {boolean}
	 */
	this.hasPermission = function (name) {
		var names = getUiPermissionMap(name),
			granted,
			grantedAnd,
			grantedOr,
			i,
			data;
		if ('object' == typeof names) {
			if ((names.AND && names.AND.length) || names.length) {
				data = names.length ? names : names.AND;
				grantedAnd = true;
				for (i = 0; i < data.length; i++) {
					if (!hasPermission(data[i])) {
						grantedAnd = false;
						break;
					}
				}
			}
			if (names.OR && names.OR.length && ('undefined' == typeof grantedAnd || true === grantedAnd)) {
				grantedOr = false;
				for (i = 0; i < names.OR.length; i++) {
					if (hasPermission(names.OR[i])) {
						grantedOr = true;
						break;
					}
				}
			}
			if ('undefined' != typeof grantedAnd && 'undefined' != typeof grantedOr) {
				granted = grantedAnd && grantedOr;
			} else {
				granted =
					'undefined' != typeof grantedAnd ? grantedAnd : 'undefined' != typeof grantedOr ? grantedOr : false;
			}
		} else {
			granted = hasPermission(names);
		}
		return granted;
	};
	/**
	 * Internal function for permissions check
	 * param    {String}    name    name of the backend permission group to check
	 * @returns {boolean}
	 */
	var hasPermission = function (name) {
		var has = false;
		if (name) {
			has = !!permissions[name];
		}
		return has;
	};
	/**
	 * Gets UI permission map if present or return same permission name if no mapping defined
	 * @param    {String}    name    name of the UI permission
	 * @returns  {Object|Array|String}
	 */
	var getUiPermissionMap = function (name) {
		return uiPermissions[name] || name;
	};
	/**
	 * Loads current user permissions from backend
	 * @param    {Function}    callback    method that will be called after permissions loaded
	 */
	var loadPermissions = function (callback) {
		var success = function (data) {
				if (data && data.hasOwnProperty(edi.constants.LIST_ROOT_PROPERTY)) {
					for (var i = 0; i < data[edi.constants.LIST_ROOT_PROPERTY].length; i++) {
						permissions[data[edi.constants.LIST_ROOT_PROPERTY][i]] = true;
					}
					edi.core.logMessage('Permissions loaded', 'info');
					permissionsLoaded = true;
				} else {
					failure();
				}
			},
			failure = function () {
				edi.core.handleException('Permissions did not loaded properly');
			};
		edi.rest.sendRequest(edi.rest.services.USER.PERMISSIONS.GET, 'GET', null, success, failure, callback);
	};
	/**
	 * After init method, that do not allow to initialise permissions more than once
	 */
	var afterInit = function () {
		__self.init = function () {
			edi.core.handleException('Permissions already initialised!');
		};
	};
})();
