import { createCombo, createField, createFields, createFile, createLabel, createTextField } from '@Components/fields';
import { createLabel as createLabelNew, createLabelForDetails } from '@UIkit/components/fields';
import { createContainer } from '@Components/miscComponents';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm } from '@UIkit/components/panels';
import { createDownloadButton, createSaveButton } from '@Components/buttons';
import { PTYPE_APPEND_ICONBUTTON } from '@UIkit/plugins/AppendSibling/AppendIconButton';
import { PAS_CLS } from '@UIkit/plugins/AppendSibling/AppendSibling';

/**
 * Created by Дарья on 09.03.2017.
 */
Ext.namespace('edi.methods');
Object.assign(edi.methods, {
	fileStorage: {
		fullDetails: function (objectData) {
			if (edi.permissions.hasPermission('CLIENT_FILE_STORAGE')) {
				var form,
					modal,
					result = objectData.get('processed');
				form = createModalForm({
					items: [
						createField({
							title: edi.i18n.getMessage('column.by.module'),
							input: createLabel({
								name: 'docName',
								columnWidth: 0.6,
								text: objectData.get('byModule'),
								valueLabel: true
							})
						}),
						createField({
							title: edi.i18n.getMessage('column.result'),
							input: createLabel({
								name: 'docName',
								columnWidth: 0.6,
								text: edi.renderers.fileResult(result),
								valueLabel: true
							})
						}),
						result === edi.constants.FILE_STORAGE.PROCESS_STATE.PROCESSED_WITH_ERROR
							? createLabel({
									text: edi.i18n.getMessage('column.error.message')
							  })
							: undefined,
						result === edi.constants.FILE_STORAGE.PROCESS_STATE.PROCESSED_WITH_ERROR
							? createContainer({
									padding: '10 0 0 0',
									html: edi.renderers.complexErrorObject(objectData.get('errorMessage'))
							  })
							: undefined
					]
				});
				modal = createModalPanel({
					title: edi.i18n.getMessage('column.message'),
					width: MODAL_SIZE.widthSmall,
					items: [form]
				});
				modal.show();
				form.isValid();
			}
		},
		details: function (objectData) {
			let buttons = [];
			if (edi.permissions.hasPermission('CLIENT_FILE_STORAGE')) {
				buttons.push(
					createDownloadButton(function () {
						modal.close();
						edi.rest.downloadFile(
							edi.utils.formatString(
								edi.rest.services.FILE_STORAGE.GET,
								{
									fileStorageId: objectData.id
								},
								false
							),
							objectData.id
						);
					})
				);
				var columns = edi.columns.get('file_results');
				columns.push(
					createActionsColumnConfig({
						flex: 1,
						items: [
							{
								glyph: edi.constants.ICONS.VISIBILITY,
								isActionDisabled: function (view, rowIndex, colIndex, item, record) {
									return null == record.get('errorMessage') || '' == record.get('errorMessage');
								},
								handler: function (grid, rowIndex) {
									var record = grid.getStore().getAt(rowIndex);
									edi.methods.fileStorage.fullDetails(record);
								}
							},
							{
								glyph: edi.constants.ICONS.SEND,
								isActionDisabled: function (view, rowIndex, colIndex, item, record) {
									return (
										edi.constants.FILE_STORAGE.FILE_STATE.PROCESSED_WITH_ERROR !==
											record.get('processed') ||
										(objectData.byUser.id != edi.core.getUserData().id &&
											!record.get('loadBySystem'))
									);
								},
								handler: function (grid, rowIndex) {
									edi.core.confirm(null, 'file.results.reprocess.confirmation', function () {
										modal.setLoading();
										var record = grid.getStore().getAt(rowIndex);
										var recordData = record.getData();
										edi.rest.sendRequest(
											edi.utils.formatString(edi.rest.services.FILE_STORAGE.RESULTS.PUT, {
												fileStorageResultId: recordData.id
											}),
											'PUT',
											null,
											null,
											null,
											function () {
												modal.setLoading(false);
												grid.getStore().reload();
												edi.events.fileStorage.fireEvent('change', recordData);
											}
										);
									});
								}
							}
						],
						width: 40
					})
				);
				var grid = createGrid({
					enableTextSelection: true,
					proxyConfig: {
						type: 'ajax',
						url: edi.utils.compileURL(edi.rest.services.FILE_STORAGE.RESULTS.GET, {
							id: objectData.id
						})
					},
					storeConfig: {
						model: edi.models.getModel('FILE_RESULT'),
						sortOnLoad: true,
						sorters: {
							property: 'modifyDate',
							direction: 'DESC'
						},
						autoLoad: true
					},
					gridConfig: {
						columns: columns,
						minHeight: 300
					}
				});

				var panelDoc = createModalForm({
					items: createFields(
						[
							{
								type: 'label',
								name: 'docName',
								title: edi.i18n.getMessage('column.name'),
								valueLabel: false
							},
							{
								type: 'label',
								name: 'docType',
								title: edi.i18n.getMessage('column.doc.type'),
								valueLabel: false
							},
							{
								type: 'label',
								name: 'contentType',
								title: edi.i18n.getMessage('column.content.type'),
								valueLabel: false
							}
						],
						objectData,
						{
							useFieldLabel: true
						},
						{
							cls: 'nowrap'
						}
					)
				});

				var modal = createModalPanel({
					width: MODAL_SIZE.widthLarge,
					title: edi.i18n.getMessage('file.details.title') + ' ' + objectData.id,
					items: [panelDoc, grid],
					buttonsBefore: buttons
				});
				modal.show();
			}
		},
		createOrUpdate: function (objectData) {
			if (!edi.permissions.hasPermission('CLIENT_FILE_STORAGE')) {
				return;
			}
			var documentTypes = [],
				form,
				modal,
				isEdit = !!objectData;

			var attachment = createFieldBlock({
				title: edi.i18n.getMessage('file.attachment'),
				items: [
					createFile(
						{},
						{
							name: 'attachment',
							allowBlank: isEdit,
							buttonText: edi.i18n.getMessage('upload.button'),
							listeners: {
								change: function () {
									form.isValid(); //Force form validation, cos in some cases it is not happening on value change
								}
							}
						}
					)
				]
			});
			var continueLoading = function () {
				form = createModalForm({
					items: [
						createFieldBlock({
							title: edi.i18n.getMessage('column.doc.name'),
							items: [
								createTextField({
									name: 'docName',
									valueSrc: objectData
								})
							]
						}),
						attachment,
						createFieldBlock({
							title: edi.i18n.getMessage('column.doc.type'),
							items: [
								createCombo({
									name: 'docType',
									disabled: isEdit,
									store: edi.stores.createMemoryStore(documentTypes, 'SIMPLE', true),
									valueSrc: objectData
								})
							]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('column.content.encoding'),
							items: [
								createCombo({
									name: 'contentEncoding',
									autoValue: isEdit ? undefined : 'auto',
									valueSrc: isEdit ? objectData : undefined,
									store: edi.stores.createMemoryStore(
										[
											{
												id: 'auto',
												name: edi.i18n.getMessage('autodetect')
											},
											{
												id: 'utf-8',
												name: 'UTF-8 Юникод'
											},
											{
												id: 'windows-1251',
												name: 'CP-1251 Windows'
											}
										],
										'SIMPLE',
										null
									)
								})
							]
						})
					]
				});

				modal = createModalPanel({
					title: edi.i18n.getMessage(isEdit ? 'file.edit.title' : 'file.create.title'),
					items: [form],
					width: MODAL_SIZE.widthMedium,
					buttonsBefore: [
						createSaveButton(
							function () {
								modal.setLoading();
								var fields = edi.utils.getFormFields(form);
								var tmp = fields.attachment.getValue().split('/');
								tmp = tmp[tmp.length - 1].split('\\');
								var fileName = tmp[tmp.length - 1];
								var docName = fields.docName.getValue();
								if (!docName && fileName) {
									docName = fileName;
								}
								fields.docName.setValue(edi.utils.base64.encode(docName));
								for (var i in fields) {
									if (fields.hasOwnProperty(i)) {
										var val = fields[i].getValue();
										if (i === 'docType' || i === 'contentEncoding') {
											val = fields[i].getSubmitValue();
										}
										fields[i].setDisabled(!val || val === 'auto');
									}
								}
								var url = edi.rest.services.FILE_STORAGE.POST;
								if (isEdit) {
									url = edi.rest.services.FILE_STORAGE.PUT;
								}
								edi.core.submitUploadForm(
									form,
									edi.utils.formatString(
										url,
										{
											fileStorageId: isEdit ? objectData.id : null
										},
										false
									),
									'loading.text',
									function () {
										edi.events.fileStorage.fireEvent('change');
										modal.setLoading(false);
										modal.close();
									},
									function () {
										edi.core.logMessage('Error saving fileStorage', 'warn');
										edi.core.showError('file.upload.failed');
										modal.setLoading(false);
										modal.close();
									}
								);
							},
							{
								bindToForm: form
							}
						)
					]
				});
				modal.show();
				form.isValid();
			};
			var activeModule = edi.modulesHandler.getActiveModule();
			activeModule.tab.setLoading();
			edi.rest.sendRequest(
				edi.rest.services.FILE_STORAGE.TYPES.GET,
				'GET',
				null,
				function (responseData) {
					var items = responseData.items;
					for (var i = 0; i < items.length; i++) {
						documentTypes.push({
							id: items[i],
							name: items[i]
						});
					}
				},
				null,
				function () {
					activeModule.tab.setLoading(false);
					continueLoading();
				}
			);
		},
		/**
		 * Removes files
		 * @param grid
		 */
		remove: function (grid) {
			if (edi.permissions.hasPermission('CLIENT_FILE_STORAGE')) {
				var moduleData = edi.modulesHandler.getActiveModule();

				var deleteHandler = function (record, grid, callback, failure) {
					return function () {
						var url = edi.utils.formatString(edi.rest.services.FILE_STORAGE.DELETE, {
							fileStorageId: record.get('id')
						});
						edi.rest.sendRequest(
							url,
							'DELETE',
							undefined,
							function () {
								grid.getSelectionModel().deselect(record);
								callback && 'function' == typeof callback ? callback() : null;
							},
							function () {
								edi.core.logMessage(
									'Error occurred during removal of file ' + record.get('id'),
									'warn'
								);
								failure && 'function' == typeof failure ? failure(record) : null;
								callback && 'function' == typeof callback ? callback() : null;
							}
						);
					};
				};

				edi.document.actions.documentGridActionProcess({
					grid: grid,
					moduleData: moduleData,
					action: edi.constants.DOCUMENT_ACTIONS.DELETE,
					confirmAction: true,
					createList: function (items) {
						return edi.document.actions.createItemsList(items, {
							id: {
								title: 'column.id'
							},
							docName: {
								title: 'column.doc.name'
							},
							docType: {
								title: 'column.doc.type'
							}
						});
					},
					actionCheckCallback: function (record) {
						var status = record.get('processed');
						return record.get('byUser').id == edi.core.getUserData().id && status !== 'Y' && status !== 'P';
					},
					completeEvent: {
						object: 'fileStorage',
						event: 'change'
					},
					notProcessedMsg: 'configuration.files.that.were.not.processed',
					notReadyMsg: 'configuration.files.that.will.not.be.processed',
					confirmQuestionActionPrefix: 'configuration.files.action.',
					confirmQuestion: 'configuration.files.action.confirm.question',
					noValidDocumentsMsg: 'configuration.no.valid.files.selected',
					handler: deleteHandler,
					loadingMessage: 'configuration.files.delete.started',
					confirmTitle: 'configuration.files.delete'
				});
			}
		}
	},
	certificate: {
		details: function (recordData, buttons, showUserData) {
			buttons = buttons ? buttons : [];

			var modal = createModalPanel({
				title: edi.i18n.getMessage('certificate.title'),
				width: MODAL_SIZE.widthMedium,
				items: createModalForm({
					items: [
						createLabelNew({
							title: edi.i18n.getMessage('column.serial.number'),
							name: 'SerialNumber',
							text: recordData?.SerialNumber
						}),
						createLabelNew({
							title: edi.i18n.getMessage('column.thumbprint'),
							name: 'Thumbprint',
							text: recordData?.Thumbprint
						}),
						createLabelNew({
							title: edi.i18n.getMessage('column.issuer.name'),
							name: 'IssuerName',
							text: edi.methods.certificate.formatIssuerForDetails(recordData.IssuerName)
						}),
						createLabelNew({
							title: edi.i18n.getMessage('column.subject.name'),
							name: 'SubjectName',
							text: edi.methods.certificate.formatSubjectForDetails(recordData.SubjectName)
						}),
						createLabelNew({
							title: edi.i18n.getMessage('column.valid.from.date'),
							name: 'ValidFromDate',
							text: edi.utils.formatDate(recordData?.ValidFromDate, edi.constants.DATE_FORMAT.FNS)
						}),
						createLabelNew({
							title: edi.i18n.getMessage('column.valid.to.date'),
							name: 'ValidToDate',
							text: edi.utils.formatDate(recordData?.ValidToDate, edi.constants.DATE_FORMAT.FNS)
						}),
						showUserData
							? createLabelNew({
									title: edi.i18n.getMessage('column.user'),
									name: 'user',
									text: edi.renderers.user(recordData.user)
							  })
							: null,
						showUserData
							? createLabelNew({
									title: edi.i18n.getMessage('column.org'),
									name: 'org',
									text: edi.renderers.organization(recordData.org)
							  })
							: null,
						recordData.ValidToDate &&
						edi.utils.getCountDaysFromToday(recordData.ValidToDate) <=
							edi.constants.CERTIFICATE.EXPIRING_MAX_DAYS
							? createLabelNew({
									title: ' ',
									text: edi.i18n.getMessage('certificate.expiring.days.info.text', {
										days: edi.utils.getCountDaysFromToday(recordData.ValidToDate)
									}),
									color: '--color-error'
							  })
							: null,
						createLabelForDetails({
							title: edi.i18n.getMessage('power.of.attorney.external.number'),
							text: recordData.poaInfo?.doverRegNumber
						}),
						createLabelForDetails({
							title: edi.i18n.getMessage('power.of.attorney.state'),
							html: edi.renderers.poaStateRenderer(recordData.poaInfo?.poaState)
						}),
						createLabelForDetails({
							title: edi.i18n.getMessage('power.of.attorney.validity.dates'),
							date: [recordData.poaInfo?.dataVidDover, recordData.poaInfo?.dataKonDover],
							dateFormat: edi.constants.DATE_FORMAT.FNS
						}),
						createLabelForDetails({
							title: edi.i18n.getMessage('power.of.attorney.power.select'),
							text: Array.isArray(recordData.poaInfo?.svPoln)
								? recordData.poaInfo.svPoln.map((svPoln) => svPoln.soderzhPoln).join(', ')
								: recordData.poaInfo?.svPoln?.prSovmPoln
						})
					]
				}),
				buttonsBefore: buttons
			});
			modal.show();
			return modal;
		},
		formatSubjectForDetails(subjectString) {
			let subject = edi.utils.certificateObject(subjectString, { returnAllValues: true });
			let items = [
				subject.organization
					? subject.organization + (subject.organizationUnit ? `(${subject.organizationUnit})` : '')
					: null,
				{
					title: edi.i18n.getMessage('company.innle.short'),
					value: subject.innle
				},
				{
					title: edi.i18n.getMessage('company.inn.short'),
					value: subject.inn
				},
				{
					title: edi.i18n.getMessage('company.ogrn.short'),
					value: subject.ogrn
				},
				{
					title: edi.i18n.getMessage('company.ogrnip.short'),
					value: subject.ogrnip
				},
				[subject.lastname, subject.firstname, subject.middlename].filter((it) => !!it).join(' '),
				subject.position,
				subject.state,
				subject.location,
				subject.street,
				subject.email
			];

			let str = items
				.filter((it) => (typeof it === 'string' ? !!it : !!it?.value))
				.map((it) => (it.title ? `${it.title} = ${it.value}` : it))
				.join(', ');

			return str;
		},
		formatIssuerForDetails(issuerString) {
			let issuer = edi.utils.certificateObject(issuerString, { returnAllValues: true });
			let items = [
				issuer.commonName,
				issuer.organization
					? issuer.organization + (issuer.organizationUnit ? `(${issuer.organizationUnit})` : '')
					: null,
				{
					title: edi.i18n.getMessage('company.inn.short'),
					value: issuer.inn
				},
				{
					title: edi.i18n.getMessage('company.innle.short'),
					value: issuer.innle
				},
				{
					title: edi.i18n.getMessage('company.ogrn.short'),
					value: issuer.ogrn
				},
				issuer.state,
				issuer.location,
				issuer.street,
				issuer.email
			];

			let str = items
				.filter((it) => (typeof it === 'string' ? !!it : !!it?.value))
				.map((it) => (it.title ? `${it.title} = ${it.value}` : it))
				.join(', ');

			return str;
		}
	},
	validators: {
		amount10: function (value) {
			return parseFloat(value) > 0 && String(value).length <= 10
				? true
				: edi.i18n.getMessage('invalid.field.value');
		},
		amount10WithZero: function (value) {
			return value === '' || (parseFloat(value) >= 0 && String(value).length <= 10)
				? true
				: edi.i18n.getMessage('invalid.field.value');
		},
		amount14: function (value) {
			return parseFloat(value) > 0 && String(value).length <= 14
				? true
				: edi.i18n.getMessage('invalid.field.value');
		},
		amount15: function (value) {
			return parseFloat(value) > 0 && String(value).length <= 15
				? true
				: edi.i18n.getMessage('invalid.field.value');
		},
		amount15WithZero: function (value) {
			return value === '' || (parseFloat(value) >= 0 && String(value).length <= 15)
				? true
				: edi.i18n.getMessage('invalid.field.value');
		},
		positiveZero: function (val) {
			return val === '' || parseFloat(val) >= 0 ? true : edi.i18n.getMessage('invalid.field.value');
		},
		positiveNonZero: function (val) {
			return val === '' || parseFloat(val) > 0 ? true : edi.i18n.getMessage('invalid.field.value');
		},
		password: function (value) {
			var mask = edi.constants.PASSWORD.VALIDATORS,
				valid = !!value && value.length >= edi.constants.PASSWORD.MIN_LENGTH,
				i;
			if (valid) {
				for (i = 0; i < mask.length; i++) {
					if (!mask[i].test(value)) {
						valid = false;
						break;
					}
				}
			}
			return (
				!value ||
				(valid ? true : edi.i18n.getMessage('controller.user.crd.password.is.not.strong.enough.error'))
			);
		},
		/**
		 * Creates FNS Number N(m.k) validator function
		 * Формат числового значения указывается в виде N(m.к), где:
		 * Из приказа ФНС №ММВ-7-15/155@
		 * m - максимальное количество знаков в числе, включая знак (для отрицательного числа), целую и дробную часть числа
		 * без разделяющей десятичной точки, к - максимальное число знаков дробной части числа.
		 * Если число знаков дробной части числа равно 0 (то есть число целое), то формат числового значения имеет вид N(m).
		 * @param	{String}	validatorName
		 * @param	{Number}	m
		 * @param	{Number}	k
		 * @param	{Boolean}	[onlyPositive]
		 * @param	{Boolean}	[onlyPoint]
		 * @returns	{Function}	validator config
		 */
		createNumberValidator: (validatorName, m, k, onlyPositive, onlyPoint) => ({
			[validatorName]: function (value) {
				const getCustomDecimalTranslation = function (total, fraction) {
					let defaultTranslate = edi.i18n.getMessage('invalid.number.decimal.custom', { total, fraction });
					const totalTextWord = edi.i18n.decline(total, 'charactersRodPadezh');
					const fractionTextWord = edi.i18n.decline(fraction, 'charactersRodPadezh');
					return edi.i18n.customTranslation(
						'custom.invalid.number.decimal.custom',
						{ total, fraction, totalTextWord, fractionTextWord },
						defaultTranslate
					);
				};
				Ext.form.field.VTypes[validatorName + 'Text'] = getCustomDecimalTranslation(m, k);

				let val = value !== null && value !== undefined ? String(value) : '';

				if (onlyPositive === true && /-/.test(val)) {
					return false;
				}

				//разделитель только точка
				if (onlyPoint === true && /,/.test(val)) {
					return false;
				}
				//проверим что ввели только числа . и ,
				if (/([^-\d.,])/.test(val)) {
					return false;
				}

				//проверим что начинается с числа и -числа
				if (/^\d|^-\d/.test(val) !== true) {
					return false;
				}

				//проверяем, что в числе максимум один десятичный разделитель
				let countOfSeparators = (val.match(/[.,]/g) || []).length;
				if (countOfSeparators > 1) {
					return false;
				}

				//десятичный разделитель не учитывается  в подсчете символов
				let separatorPos = val.match(/[.,]/)?.index;
				let countOfSymbols = val.length;
				if (Ext.isNumber(separatorPos)) {
					countOfSymbols--;
				}
				//проверим макс количество символов
				if (countOfSymbols > m) {
					return false;
				}

				//проверим макс количество символов после разделителя
				let decimalSymbolsCount = Ext.isNumber(separatorPos) ? val.length - separatorPos - 1 : 0;
				return decimalSymbolsCount <= k;
			},
			[validatorName + 'Text']: `Invalid N(${m}.${k}) number format`
		})
	},
	filiations: {
		/**
		 * Groups organizations by filiations
		 * @param      orgs                      An array of  objects or store records
		 * @return     {Array}
		 */
		sortOrgsByFiliations: function (orgs) {
			if (Array.isArray(orgs) && orgs.length === 1) {
				return orgs;
			}

			var getOrgData = function (org) {
				return 'function' == typeof org.getData ? org.getData() : org;
			};

			var getOrgFiliations = function (orgId) {
				return orgs.filter(function (org) {
					var data = getOrgData(org),
						isFiliation = data.headOrgId == orgId;
					if (isFiliation) {
						processedOrgsMap[data.id] = true;
					}

					return isFiliation;
				});
			};

			var getHeadOrg = function (headOrgId) {
				return orgs.find(function (org) {
					var data = getOrgData(org);
					return data.id == headOrgId;
				});
			};

			var sortedOrgs = [],
				processedOrgsMap = {};

			//Iterate through all headOrgs, find their filials and insert them after head org
			for (var i = 0; i < orgs.length; i++) {
				var src = orgs[i],
					org = getOrgData(src),
					filiations = [];

				//Skip org if it's already processed
				//Or if it is some org filiation and head org is in this array too (cause filiation wil be processed with the head org)
				if (processedOrgsMap[org.id] || (org.headOrgId && !!getHeadOrg(org.headOrgId))) {
					continue;
				}

				if (org.hasFilials) {
					filiations = getOrgFiliations(org.id);

					//If org has filials, but they aren't our partners, we don't need to mark org as headOrg
					if (!filiations.length) {
						if ('function' === typeof src.getData) {
							src.beginEdit();
							src.set('hasFilials', false);
							src.endEdit();
						} else {
							src.hasFilials = false;
						}
					}
				}
				processedOrgsMap[org.id] = true;

				sortedOrgs.push(src);
				if (filiations.length) {
					sortedOrgs = sortedOrgs.concat(filiations);
				}
			}
			return sortedOrgs;
		}
	},
	/**
	 * Convert object values as {prop1: ['val1', 'val2'], prop2: ['val3', 'val4']} to array of objects:
	 * [{prop1: 'val1', prop2: 'val3'}, {prop1: 'val2', prop2: 'val4'}]
	 * @param      {Object}     values        object of values
	 * @return       {Object}
	 */
	convertValuesFromMultipleFields: function (values) {
		var getPropNames = function (obj) {
			var names = {},
				arrayLength = 0;
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					names[key] = null;

					if (!arrayLength && Array.isArray(obj[key])) {
						arrayLength = obj[key].length;
					}
				}
			}
			return [names, arrayLength];
		};

		var copyArrayValues = function (props, objValues, indx) {
			var items = {};
			for (var name in props) {
				if (props.hasOwnProperty(name)) {
					if (Array.isArray(objValues[name])) {
						items[name] = objValues[name][indx];
					} else if (Ext.isObject(objValues[name])) {
						items[name] = copyArrayValues(getPropNames(objValues[name])[0], objValues[name], indx);
					}
				}
			}
			return items;
		};

		var valueProps = getPropNames(values);
		var arrayValues = [];

		while (arrayValues.length != valueProps[1]) {
			arrayValues.push(copyArrayValues(valueProps[0], values, arrayValues.length));
		}
		return arrayValues.length > 0 ? arrayValues : [values];
	},
	header: {
		getLatestOrgAccount: function (accounts) {
			accounts = Array.isArray(accounts) ? Ext.clone(accounts) : [];
			let latest = accounts.find((acc) => acc.priority === true || acc.priority === 'true');
			if (!latest) {
				latest = accounts.sort((a, b) => (a.modifyDate > b.modifyDate ? -1 : 1))[0];
			}
			return latest;
		},
		bankAccountInfo: function (userOrg, orgValues) {
			let latest = edi.methods.header.getLatestOrgAccount(userOrg.accounts);

			orgValues.bank_acc = latest?.number;
			orgValues.bank_id = latest?.bik;
			orgValues.bank_corr_acc = latest?.corrAccNumber;
			orgValues.bank_name = latest?.preferredOwnerName;
		}
	},
	notice: {
		createWindowInfo: function (conf) {
			var config = conf || {},
				titleComp,
				mainTextComp,
				additionalTextComp,
				leftSideIcoComp,
				buttonToolbarCont;

			var defaultConfig = {
				icoStyle: null,
				title: '',
				text: '',
				additional: '',
				buttons: [],
				width: edi.constants.NOTICE.WINDOW.WIDTH.BASIC,
				y: 60
			};

			Ext.merge(defaultConfig, config);

			if (defaultConfig.title) {
				titleComp = Ext.create('Ext.Component', {
					cls: 'edi-rightside-title',
					html: defaultConfig.title
				});
				delete defaultConfig.title;
			}
			if (defaultConfig.text) {
				mainTextComp = Ext.create('Ext.Component', {
					cls: 'edi-rightside-main-text',
					html: defaultConfig.text
				});
				delete defaultConfig.text;
			}
			if (defaultConfig.additional) {
				additionalTextComp = Ext.create('Ext.Component', {
					cls: 'edi-rightside-main-text-additional',
					html: defaultConfig.additional
				});
				delete defaultConfig.additional;
			}

			if (defaultConfig.buttons && Array.isArray(defaultConfig.buttons) && defaultConfig.buttons.length) {
				buttonToolbarCont = Ext.create('Ext.container.Container', {
					cls: 'edi-bottom-toolbar',
					width: '100%',
					layout: {
						type: 'hbox',
						pack: 'end'
					},
					items: defaultConfig.buttons
				});
				delete defaultConfig.buttons;
			}

			if (defaultConfig && defaultConfig.icoStyle) {
				if (
					'object' === typeof defaultConfig.icoStyle &&
					!(defaultConfig.icoStyle.color && defaultConfig.icoStyle.glyph)
				) {
					delete defaultConfig.icoStyle;
				} else if ('string' === typeof defaultConfig.icoStyle) {
					if (edi.constants.NOTICE.WINDOW.STYLES.DEFAULT.some((it) => it === defaultConfig.icoStyle)) {
						defaultConfig.icoStyle = edi.constants.NOTICE.WINDOW.STYLE[defaultConfig.icoStyle];
					} else {
						delete defaultConfig.icoStyle;
					}
				}

				if (defaultConfig.icoStyle) {
					leftSideIcoComp = Ext.create('Ext.Container', {
						cls: 'edi-leftside-ico',
						width: 30,
						style: {
							color: defaultConfig.icoStyle.color
						},
						items: [
							Ext.create('Ext.Img', {
								glyph: defaultConfig.icoStyle.glyph
							})
						]
					});
					delete defaultConfig.icoStyle;
				}
			}

			var baseConfig = {
				cls: 'edi-window-notice',
				renderTo: Ext.getBody(),
				padding: '5 5 5 5',
				hidden: true,
				layout: {
					type: 'vbox'
				},
				listeners: {
					afterrender: function (win) {
						win.show();
					},
					show: function (win) {
						win.addCls('edi-window-notice-show'); // EW override
					}
				},
				items: [
					Ext.create('Ext.container.Container', {
						cls: 'edi-window-notice-body',
						width: '100%',
						layout: {
							type: 'column',
							column: 3
						},
						items: [
							leftSideIcoComp ? leftSideIcoComp : null,
							Ext.create('Ext.container.Container', {
								columnWidth: 1,
								cls: leftSideIcoComp ? 'edi-rightside-main-container' : 'edi-main-container',
								layout: {
									type: 'vbox',
									align: 'stretch',
									pack: 'start'
								},
								items: [
									titleComp ? titleComp : null,
									mainTextComp ? mainTextComp : null,
									additionalTextComp ? additionalTextComp : null
								]
							}),
							Ext.create('Ext.container.Container', {
								cls: 'edi-rightside-vertical-toolbar',
								width: 30,
								margin: 0,
								items: [
									{
										xtype: 'button',
										glyph: edi.constants.ICONS.CLOSE,
										handler: function () {
											var win = windowNotice;
											// EW begin
											win.addCls('edi-window-notice-hide');

											const timer = setTimeout(() => {
												win.destroy();
												clearTimeout(timer);
											}, 500);
											// EW end
										}
									}
								]
							})
						]
					}),
					buttonToolbarCont ? buttonToolbarCont : null
				]
			};

			Ext.mergeIf(baseConfig, defaultConfig);
			var windowNotice = Ext.create('Ext.container.Container', baseConfig);
		}
	},
	gridSelModules: function (model, selected, actionItems, topBar, totalsLabel, isAvailableAction) {
		topBar.suspendLayouts();

		const getSelectedRowWithAvailableAction = (btn) => {
			return selected.filter(function (select) {
				return isAvailableAction(btn.rulesData.id, select);
			});
		};

		for (var i = 0; i < actionItems.length; i++) {
			var btn = actionItems[i],
				tooltip = btn.rulesData.name + ' (' + selected.length + ')',
				isDisabled =
					selected.length === 0 ? (btn.rulesData.notDisabled ? !btn.rulesData.notDisabled : true) : false;

			if (isAvailableAction) {
				var selectRow = getSelectedRowWithAvailableAction(btn);

				tooltip = btn.rulesData.name + ' (' + selectRow.length + ')';
				isDisabled = !selectRow.length;
			}

			if (btn.rulesData.id === 'CREATE' || btn.rulesData.id === 'ADD') {
				btn.setTooltip(btn.rulesData.name);
				btn.setDisabled(!!selected.length);
			} else {
				btn.setTooltip(tooltip);
				if (btn.rulesData.notDisabled !== true) {
					btn.setDisabled(isDisabled);
					const btnMenu = btn.getMenu();
					if (btnMenu) {
						let countDisabledMenuItems = 0;
						const menuItems = btnMenu.getRefItems() || [];
						menuItems.forEach((item) => {
							const availableSelectedRows = isAvailableAction
								? getSelectedRowWithAvailableAction(item)
								: selected;
							const isItemDisabled = !availableSelectedRows.length;

							if (item.rulesData.notDisabled !== true) {
								item.setDisabled(isItemDisabled);
								if (isItemDisabled) {
									countDisabledMenuItems++;
								}
							}
							item.setTooltip(item.rulesData.name + ' (' + availableSelectedRows.length + ')');
						});
						btn.setDisabled(countDisabledMenuItems === menuItems.length);
					}
				}
			}
		}
		if (totalsLabel) {
			totalsLabel.setText(
				edi.i18n.getMessage('records.selected.totals', {
					selectedRows: edi.renderers.currencyWithSeparators(selected.length)
				})
			);
		}
		topBar.resumeLayouts();
	},
	beforeLoadModules: {
		initRealTimeMessage: function (callback) {
			edi.realtime.messages.getRealTimeMessage(callback);
		},
		selectCurrentOrgByIln: function (callback) {
			const getOrgData = function (org) {
				return 'function' == typeof org.getData ? org.getData() : org;
			};

			const continueLoading = function (orgs) {
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);
				const userOrgIln = urlParams.get('userOrgIln');
				const userOrgByIln = orgs.find(function (org) {
					const data = getOrgData(org);
					return data.iln == userOrgIln;
				});
				const userData = edi.core.getUserData();
				const userOrgId = userData?.org?.id;
				const userOrgIdByIln = userOrgByIln?.id;
				if (userOrgIdByIln && userOrgId != userOrgIdByIln) {
					localStorage.removeItem('extraData');
					const setCurrentOrganization = function () {
						return edi.login.setCurrentOrganization(
							userOrgIdByIln,
							function () {
								document.location.reload();
							},
							callback,
							true
						);
					};
					edi.core.setExtraData('organization.id', userOrgIdByIln, setCurrentOrganization);
				} else {
					callback();
				}
			};
			edi.login.getOrganizations(continueLoading);
		},
		paymentDebitor: function (callback) {
			const userData = edi.core.getUserData();
			if (userData?.org?.paymentDebt && edi.constants.CHECK_PAYMENT_DEBT) {
				edi.core.showWarnWithoutClose('error.organization.has.payment.debt.warning.text');
			} else {
				callback();
			}
		}
	}
});

const createHelpElementPlugin = function ({ url, target = '_blank', refEl = 'textEl', tooltip }) {
	return {
		ptype: PTYPE_APPEND_ICONBUTTON,
		refEl,
		btnConfig: {
			glyph: edi.constants.ICONS.HELP_OUTLINE,
			tooltip,
			handler: function () {
				window.open(url, target);
			}
		}
	};
};

// При подключении плагина к компоненту поле ввода field, в config нужно прокинуть свойство isField: true
// для настройки выравнивания кнопки i относительно поля ввода
const createInfoElementPlugin = function (config) {
	return Ext.merge(
		{
			ptype: PTYPE_APPEND_ICONBUTTON,
			alignItems: config.isField ? 'start' : 'center',
			btnConfig: {
				cls: config.isField ? [PAS_CLS.field] : ''
			},
			hintConfig: {
				popupAlign: 'b'
			}
		},
		config
	);
};

export { createHelpElementPlugin, createInfoElementPlugin };
