/**
 * Converters processor for data models
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.converters');

edi.converters = new (function () {
	var __self = this,
		map = {};
	/**
	 * Sets conversion map properties
	 * @param    {Object}     conversionMap    map of model properties relation
	 * @param    {Boolean}    replace          true to replace map instead of merge
	 */
	this.setMap = function (conversionMap, replace) {
		if (replace) {
			map = conversionMap || {};
		} else {
			Ext.merge(map, conversionMap);
		}
	};
	/**
	 * Returns conversion map by name or whole map in no name specified
	 * @param    {String}    name    name of the map or empty for whole object return
	 */
	this.getMap = function (name) {
		var retData;
		if (name && map[name]) {
			retData = Ext.clone(map[name]);
		} else if (!name) {
			retData = Ext.clone(map);
		}
		return retData;
	};
	/**
	 * convert data by map
	 * @param    {Object}    data       data to be converted
	 * @param    {String}    mapName    name of the converters map
	 * @param    {Object}    map        map with fieldNames as destination format and values are fieldNames for source format or functions that will be fired to set right value
	 * @returns    {Object}
	 */
	this.convert = function (data, mapName, map) {
		var res = {};
		if (!map && mapName) {
			map = this.getMap(mapName);
		}
		if (data && map) {
			for (var i in map) {
				if (map.hasOwnProperty(i) && data[map[i]]) {
					if ('function' == typeof map[i]) {
						res[i] = map[i](data);
					} else {
						res[i] = edi.utils.getObjectProperty(data, map[i]);
					}
				}
			}
		}
		return res;
	};
	/**
	 * Registers converters as edi.converters method
	 * @param    {Object}    converters    map of conversion methods
	 */
	this.registerConverters = function (converters) {
		for (var i in converters) {
			if (converters.hasOwnProperty(i) && 'function' == typeof converters[i]) {
				__self[i] = createConverter(converters[i]);
			}
		}
	};
	/**
	 * Generates converter that will be called in scope of edi.converters
	 * @param    {Function}    fn    externally defined convertor fn
	 * @returns {Function}
	 */
	var createConverter = function (fn) {
		return function () {
			return fn.apply(__self, arguments);
		};
	};
})();
