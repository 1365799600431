/**
 * Configuration for core
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.constants');
Object.assign(edi.constants, {
	LOCALIZATION_PACKAGE_NAME: 'Coreplat',
	THEME: 'EDI',
	SKIP_LOGIN: false,
	SHOW_HEADER: true,
	INFO_FOOTER: false,
	HIDE_HEADER_BUTTONS: false,
	LEFT_NAVIGATION_V2: true,
	LEFT_NAVIGATION_CLOSE_ALL: false,
	FILTERS_COLLAPSED: true, //true will override FILTERS_COLLAPSIBLE also to true
	FILTERS_COLLAPSIBLE: true,
	ACTION_ICON_WIDTH: 40,
	MODULES_CLOSE_FIRST: true,
	MODULES_TAB_BAR: true,
	BREAD_CRUMB: false,
	COLUMN_CONFIG_SAVE_ENABLED: true,
	CHECK_PAYMENT_DEBT: true,
	INIT_LOCALIZATION_GROUPS: ['initMini', 'uikit'],
	CERTIFICATE: {
		ALLOW_ACTIVE: true,
		ONLY_ACTIVE: false,
		NO_ACTIVE_ERROR: 'error.no.active.certificate',
		ACTIVE_SEARCH_PARAMS: {
			STORE: 'Thumbprint',
			DB: 'thumbprint'
		},
		EXPIRING_MAX_DAYS: 14
	},
	CURRENCY_STACK_SEPARATOR: ' ',
	CURRENCY_STACK_LENGTH: 3,
	SKU: {
		BOX: 0,
		PALLET: 1,
		CONTAINER: 2
	},
	ALLOW_ALL_CERTIFICATES: true,
	ORGANIZATION_TYPES: {
		PERSON: 'PERSON',
		INDIVIDUAL: 'INDIVIDUAL',
		LEGAL_ENTITY: 'LEGAL_ENTITY',
		FOREIGN_LEGAL_ENTITY: 'FOREIGN_LEGAL_ENTITY',
		UNDEFINED: 'UNDEFINED'
	},
	ADDRESS_TYPES: {
		REAL: 'REAL',
		LEGAL: 'LEGAL',
		POSTAL: 'POSTAL',
		OTHER: 'OTHER'
	},
	CREATE_ACTIONS: {
		DEFAULT: {
			//Default create actions for all document types, if no rules for exact type
			OPEN_ROOT: false,
			OPEN_DETAILS: true,
			CLOSE_CURRENT: true
		}
	},
	EDIT_ACTIONS: {
		DEFAULT: {
			//Default create actions for all document types, if no rules for exact type
			OPEN_ROOT: false,
			OPEN_DETAILS: false,
			CLOSE_CURRENT: true
		}
	},
	FILE_STORAGE: {
		PROCESS_STATE: {
			DRAFT: 'DRAFT',
			PROCESSED: 'PROCESSED',
			NO_PROCESSOR: 'NO_PROCESSOR',
			REPROCESSING: 'REPROCESSING',
			PROCESSED_WITH_ERROR: 'PROCESSED_WITH_ERROR'
		},
		FILE_STATE: {
			DRAFT: 'DRAFT',
			WAIT_PROCESSING: 'WAIT_PROCESSING',
			PROCESSED: 'PROCESSED',
			REPROCESSING: 'REPROCESSING',
			PROCESSED_WITH_ERROR: 'PROCESSED_WITH_ERROR'
		}
	},
	REPORT_FORMATS: {
		PDF: 'PDF',
		XLS: 'XLS',
		XLSX: 'XLSX',
		DOCX: 'DOCX',
		XML: 'XML',
		CSV: 'CSV',
		RTF: 'RTF'
	},
	PRINT_TEMPLATE_TYPES: {
		PRINTED_FORM: 'PRINTED_FORM',
		TRANSFER_PROTOCOL: 'TRANSFER_PROTOCOL',
		UNEXPECTED: 'UNEXPECTED'
	},
	PRINT_STATUS: {
		NONE: 'NONE',
		ORDERED: 'ORDERED',
		READY: 'READY',
		COMPLETED: 'COMPLETED',
		WAIT_DECISION: 'WAIT_DECISION'
	},
	REPORT_STATE: {
		ORDERED: 'ORDERED',
		COMPLETED: 'COMPLETED',
		GENERATING_ERROR: 'GENERATING_ERROR',
		WAIT_DECISION: 'WAIT_DECISION'
	},
	PRINT_STATUS_CHECK_DELAY: 1000,
	MODULE_NAME: {
		CORE: 'CORE'
	},
	STORAGE_COLLECTION: {
		TYPES: [
			'edi_storage_archive',
			'edi_storage',
			'edi_storage_test',
			'ecd_storage',
			'edi_roaming_in',
			'edi_roaming_out'
		],
		TIME_INTERVALS: ['weekly', 'daily', 'hourly']
	},
	EHD: {
		PROCESSOR_NAME: [
			'EdiGlobalStorageModernProcessor',
			'EdiCommonApiRequestTpEhdProcessor',
			'EdiInstallationPackageProcessor',
			'EdiMonitoringClientGlobalStorageProcessor',
			'EdiRoseuInboundMessagesEhdProcessor'
		]
	},
	DOCUMENT_TYPES: {
		EDI_USER_CONFIRM_EMAIL_REQUEST: 'EDI_USER_CONFIRM_EMAIL_REQUEST',
		EDI_USER_CERTIFICATE: 'EDI_USER_CERTIFICATE'
	},
	DOCUMENT_ACTIONS: {
		CREATE: 'CREATE',
		READ: 'READ',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
		REPROCESS: 'REPROCESS',
		REPROCESS_MANUALLY: 'REPROCESS_MANUALLY',
		CHANGE_PRIORITY: 'CHANGE_PRIORITY',
		OTHER_ACTIONS: 'OTHER_ACTIONS'
	},
	DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP: {
		CREATE: 'CREATE',
		READ: 'ACCEPT',
		EDIT: 'EDIT',
		DELETE: 'DELETE'
	},
	ERROR_MARKERS: {
		VALID: 'VALID',
		DUBLICATE: 'DUBLICATE',
		DEFAULT: 'DEFAULT',
		DEFAULT_FILE_STORAGE_MARKER: 'DEFAULT_FILE_STORAGE_MARKER'
	},
	REFERENCE_ACTIONS: {
		SET_TO_REPROCESS: 'SET_TO_REPROCESS',
		SET_TO_IGNORED: 'SET_TO_IGNORED'
	},
	REFERENCE_HANDLERS: {
		GET_CERTIFICATE_DETAILS: 'GET_CERTIFICATE_DETAILS',
		GET_SOURCE_FILE_STORAGE: 'GET_SOURCE_FILE_STORAGE'
	}
});

/**
 * Список Типов Организаций используется при создании Организации
 */
edi.constants.CREATE_ORGANIZATION_TYPES = [
	edi.constants.ORGANIZATION_TYPES.INDIVIDUAL,
	edi.constants.ORGANIZATION_TYPES.LEGAL_ENTITY,
	edi.constants.ORGANIZATION_TYPES.FOREIGN_LEGAL_ENTITY
];

edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS.push(function (action) {
	var icon = '';
	if (edi.constants.DOCUMENT_ACTIONS.REPROCESS == action) {
		icon = edi.constants.ICONS.REPEAT;
	} else if (edi.constants.DOCUMENT_ACTIONS.CHANGE_PRIORITY == action) {
		icon = edi.constants.ICONS.FLAG;
	} else if (edi.constants.REFERENCE_ACTIONS.SET_TO_REPROCESS == action) {
		icon = edi.constants.ICONS.SEND;
	} else if (edi.constants.REFERENCE_ACTIONS.SET_TO_IGNORED == action) {
		icon = edi.constants.ICONS.WARNING;
	}
	return icon;
});
edi.constants.CRYPTOPRO = {
	WARNINGS: {
		ALGORITHM: {
			ENABLED: true,
			OID: [
				'1.2.643.2.2.19', //UI could detect only key export algorithm
				'1.2.643.2.2.3'
			],
			DATE: new Date(2019, 12, 31, 23, 59, 59).getTime(),
			COOKIE: 'certificate_warn',
			WARN_TIME: edi.constants.DAY_IN_MS,
			MSG: 'user.certificate.algorithm.validation.warning.2001'
		},
		MIN_VERSION: {
			ENABLED: true,
			VALUE: '5.0.12000',
			MSG: 'error.cryptopro.installed.old.version'
		}
	}
};
Object.assign(edi.constants.STATUS, {
	DATA_NOT_FOUND: '100',
	USER_NOT_FOUND: '101',
	ORGANIZATION_NOT_FOUND: '102',
	CURRENT_ORGANIZATION_NOT_FOUND: '104',
	GROUP_NOT_FOUND: '105',
	CANT_CREATE_DOCUMENT: '106',
	CANT_CHANGE_DOCUMENT_STATE: '107',
	CANT_MODIFY_DOCUMENT: '108',
	DOCUMENT_NOT_FOUND: '109',
	LINKED_DOCUMENT_NOT_FOUND: '110',
	VALIDATION_FAILED: '111',
	ACCESS_DENIED: '112',
	CREATE_LINKED_DOCUMENTS_ERROR: '113',
	FILE_UPLOAD_ERROR: '114'
});
edi.constants.DEFAULT_PRINT_FORMATS = [edi.constants.REPORT_FORMATS.PDF, edi.constants.REPORT_FORMATS.XLS];

edi.constants.WEB_UPLOAD_DOCTYPES = [];

edi.constants.NOTICE = {
	WINDOW: {
		WIDTH: {
			BASIC: 360,
			WIDE: 480,
			MAX: 720
		},
		STYLES: {
			DEFAULT: ['info', 'error', 'success', 'notice']
		},
		STYLE: {
			info: {
				color: '#3d7dca',
				glyph: edi.constants.ICONS.INFO_OUTLINE
			},
			error: {
				color: '#e82000',
				glyph: edi.constants.ICONS.REJECT
			},
			success: {
				color: '#00afab',
				glyph: edi.constants.ICONS.CHECK_CIRCLE
			},
			notice: {
				color: '#8e9295',
				glyph: edi.constants.ICONS.CALENDAR_CLOCK
			}
		}
	}
};

edi.constants.FIELD_MASK = {
	SNILS: '000-000-000 00'
};

edi.constants.DEFAULT.COMPONENTS_SETTINGS.USER_ORG_COMBO = {};

/**
 * При загрузке Партнеров, убираем все организации у которых тип FOREIGN_LEGAL_ENTITY, если true,
 */
edi.constants.PARTNER_ORGANIZATIONS_CLEAR_FOREIGN_LEGAL_ENTITY = true;

/**
 * При добавлении в эту константу не забывать вносить логику отправки с сам документ
 */
edi.constants.DOCS_WITH_BLOCK = [];

/**
 * Инициялизация методов до загрузки модулей
 */
edi.constants.BEFORE_LOAD_MODULES = edi.constants.BEFORE_LOAD_MODULES || [];
edi.constants.BEFORE_LOAD_MODULES.push((callback) => edi.methods.beforeLoadModules.paymentDebitor(callback));
