import { createCombo, createAutocomplete } from '@UIkit/components/fields';

/**
 * Creates combobox with permission group tags
 * @param	{String}	name
 * @param	{any}		[value]
 * @param	{Number}	[columnWidth]
 * @param	{String}	[fieldLabel]
 * @returns	{Object}	Ext.form.ComboBox instance
 */
const createPermissionGroupTagCombo = function (name, value, columnWidth, fieldLabel) {
	var items = [
		{
			id: null,
			name: edi.i18n.getMessage('permission.group.tag.NONE')
		}
	];
	for (var i in edi.constants.PERMISSION_GROUP_TAG) {
		if (edi.constants.PERMISSION_GROUP_TAG.hasOwnProperty(i)) {
			items.push({
				id: i,
				name: edi.i18n.getMessage('permission.group.tag.' + i)
			});
		}
	}
	return createCombo({
		name: name,
		value: value ? value : undefined,
		columnWidth: columnWidth || 0.75,
		fieldLabel,
		store: edi.stores.createInlineStore(items)
	});
};

export { createPermissionGroupTagCombo, createAutocomplete };
