/**
 * Created by Дарья on 21.03.2017.
 */

Ext.namespace('edi.navigation');

edi.navigation = new (function () {
	var __self = this;

	this.observer = new Ext.util.Observable();
	//this.observer.addEvents({
	//	"hidefloatingmenu": true
	//});

	this.navigationConfigs = {};
	/**
	 * Create navigation menu items array by config
	 * @param    config     Config with navigation items
	 * @return   {Array}    Navigation menu
	 */
	var createBranch = function (config) {
		/**
		 * Creates navigation menu array by config (without permission check)
		 * @param     conf      Navigation conf
		 * @return    {Array}   Navigation items array
		 */
		var readConfig = function (conf) {
			var result = [],
				i,
				item;
			for (i in conf) {
				if (conf.hasOwnProperty(i)) {
					item = Ext.clone(conf[i]);
					if (!item) {
						continue;
					}
					item.internalId = i;

					//trying to create children branch
					if (item.children) {
						item.children = createBranch(item.children);
					}
					if (item.hasOwnProperty('modName') && !item.hasOwnProperty('title')) {
						item.title = 'navigation.' + item.modName;
					}
					result.push(item);
				}
			}
			result.sort(function (a, b) {
				if (a.hasOwnProperty('order') && b.hasOwnProperty('order')) {
					return a.order - b.order;
				} else {
					return a.hasOwnProperty('order') ? -1 : 1;
				}
			});

			return result;
		};

		/**
		 * Filters navigation array by module & navigation permissions checking
		 * @param      items      Navigation items array
		 * @return     {Array}    Filtered array
		 */
		var filterByPermissions = function (items) {
			var result = [],
				item;
			for (var i = 0; i < items.length; i++) {
				item = items[i];
				if (item) {
					var hasChildren = item.hasOwnProperty('children');
					if (!hasChildren || (hasChildren && item.children.length)) {
						//check permissions
						var module = item.modName ? edi.core.getModule(item.modName) : null,
							navigationPermissions = edi.utils.getObjectProperty(item, 'permissions'),
							hasNavigationPermissions = navigationPermissions
								? edi.permissions.hasPermissions(navigationPermissions)
								: null,
							hasModulePermissions = module
								? !module.priorityNavPermissions && edi.permissions.hasPermissions(module.permissions)
								: null;

						if ((!navigationPermissions && !module) || hasNavigationPermissions || hasModulePermissions) {
							result.push(item);
						}
					}
				}
			}
			return result;
		};

		var resultArray = readConfig(config);
		return filterByPermissions(resultArray);
	};

	/**
	 * Add new navigation config (consider to extended configs)
	 * @param     config      Navigation config
	 */
	this.addNavigationConfig = function (config) {
		var selfItems = edi.utils.getObjectProperty(config, 'items'),
			extended = edi.utils.getObjectProperty(config, 'extend', true),
			i,
			parentConf;

		//If we don't extend any navigation, we don't need any merge with parent items
		if (!extended.length) {
			__self.navigationConfigs[config.name] = selfItems;
			return;
		}

		var success = function () {
			i++;
		};
		//Load all parent configs
		for (i = 0; i < extended.length; ) {
			parentConf = __self.navigationConfigs[extended[i]];
			if (!parentConf) {
				edi.loading.processor.loadScript({
					path: 'js/' + extended[i] + '/navigation.js',
					success: success
				});
			} else {
				i++;
			}
		}
		var resultConf = {};
		for (i = 0; i < extended.length; i++) {
			parentConf = __self.navigationConfigs[extended[i]];
			Ext.Object.merge(resultConf, parentConf);
		}
		Ext.Object.merge(resultConf, selfItems);
		__self.navigationConfigs[config.name] = resultConf;
	};

	/**
	 * Gets navigation config from map by name and sets it as navigation
	 * @param     {String}     navigationName     Config name
	 */
	this.loadNavigationConfig = function (navigationName) {
		if (!__self.navigationConfigs.hasOwnProperty(navigationName)) {
			edi.core.logMessage('Navigation config [ ' + navigationName + " ] hadn't loaded!");
			return;
		}
		var navigationArray = createBranch(__self.navigationConfigs[navigationName]);
		var navPanel = edi.core.getNavigationPanel();
		navPanel.renderNavigation(navigationArray);
	};

	/**
	 * Render navigation menu into navigation panel
	 * @param    {Function}     callback      A callback function
	 */
	this.renderNavigation = function (callback) {
		edi.navigation.loadNavigationConfig(edi.constants.NAVIGATION_NAME);
		edi.core.logMessage('Navigation rendered', 'info');
		if ('function' == typeof callback) {
			callback();
		}
	};
})();
