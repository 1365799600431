import { createTab } from '@Components/panels';

/**
 * Singleton class for handling UI modules classes loading and module objects initialization
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.modulesHandler');
Ext.namespace('edi.modules');
edi.modulesHandler = new (function () {
	var modules = [],
		modulesByName = {},
		classes = {},
		__self = this,
		activeModule,
		processingStates = {
			REGISTRATION: 'registration', //Module initial registration in handler
			LAYOUT_INIT: 'layout_initialisation', //Module tab creation
			LOADING: 'loading', //Module loading started
			LOADED: 'loaded', //Module processing after loading
			INIT: 'initialisation', //Module contents rendering in progress
			ERROR: 'error', //Error during module processing/loading/rendering
			READY: 'ready' //Rendering done without not handled errors
		};
	/**
	 * Initiates new module
	 * @param    {Object}      modData            module data object
	 * @param    {Function}    onAfterInit        callback that will be called after module initialisation
	 */
	this.loadModule = function (modData, onAfterInit) {
		if (modData && modData.name) {
			if (!modData.permissions || edi.permissions.hasPermissions(modData.permissions)) {
				if (checkModuleRegistered(modData)) {
					activateModule(modData, onAfterInit);
				} else if (checkClassRegistered(modData)) {
					initiateModule(modData, onAfterInit);
				} else {
					if (edi.events.module.fireEvent('beforeClassLoad', modData)) {
						processLoading(modData, onAfterInit);
					}
				}
			} else {
				edi.core.showError('error.permissions.module', onAfterInit);
			}
		} else {
			edi.core.showError('error.module.not.defined', onAfterInit);
		}
	};
	/**
	 * Removes module and unloads class, if it was last module
	 * @param    {Object}    module        module data object
	 */
	this.removeModule = function (module) {
		module.tab.close();
		return module;
	};
	/**
	 * removes all modules except first one
	 * @param    {Function}    callback    function to call after finish
	 * @param    {Boolean}    alsoFirst        also remove first
	 */
	this.removeAllModules = function (callback, alsoFirst) {
		var modulesToRemove = [],
			isFirst = true,
			i;
		for (i = 0; i < modules.length; i++) {
			if (alsoFirst || !isFirst) {
				modulesToRemove.push(modules[i]);
			}
			isFirst = false;
		}
		for (i = 0; i < modulesToRemove.length; i++) {
			modulesToRemove[i] = this.removeModule(modulesToRemove[i]);
		}
		'function' == typeof callback ? callback() : null;
	};
	/**
	 * Returns currently active module
	 * @returns {*}
	 */
	this.getActiveModule = function () {
		return activeModule;
	};
	/**
	 * Returns module handler module object found by module script tag id
	 * @param    {String}    name        name of the module
	 * @return    {Object}                found module handler module object
	 */
	this.getModuleByName = function (name) {
		var module = null;
		if (name) {
			module = modulesByName[name] || null;
		}
		return module;
	};
	/**
	 * Sets active module variable
	 * @param    {Object}    module    module object or undefined if no more active modules present
	 */
	var setActiveModule = function (module) {
		if (activeModule !== module) {
			activeModule = module;
			if (module) {
				edi.events.module.fireEvent('moduleActivated', activeModule);
			} else {
				edi.core.logMessage('Active module registration cleared', 'info');
			}
		}
	};
	/**
	 * Gets module initial properties
	 * @param    {Object}    modData      module data object
	 * @param    {Function}  onAfterInit  function that will be called after state restore
	 * @return    {Object}                internal module handler module object
	 */
	var getModuleInitialConfig = function (modData, onAfterInit) {
		return {
			tab: null,
			modName: modData.modName,
			isEditModule: modData.isEditModule,
			isMain: modData.isMain,
			openNearCurrent: modData.openNearCurrent,
			glyph: modData.glyph,
			icon: modData.icon,
			iconCls: modData.iconCls,
			title: modData.title ? modData.title : modData.modName,
			name: modData.name,
			menuId: modData.highlightMenuId || modData.menuId,
			objectId: modData.objectId,
			folder: modData.folder ? modData.folder : '',
			scriptId: modData.scriptId ? modData.scriptId : edi.core.getId(),
			initiated: false,
			initData: modData,
			instance: null,
			onDestroy: null,
			onAfterInit: onAfterInit ? onAfterInit : null,
			getModuleTitle: modData.getModuleTitle
		};
	};
	/**
	 * Checks if we have module already registered in handler
	 * @param    {Object}    modData        module data object
	 * @return    {Boolean}                true if module registered, false if not
	 */
	var checkModuleRegistered = function (modData) {
		var registered = false;
		if (modulesByName[modData.name]) {
			registered = true;
		} else {
			for (var i = 0; i < modules.length; i++) {
				if (
					modData.modName === modules[i].modName &&
					modData.objectId === modules[i].objectId &&
					modules[i].initData.id === modData.id &&
					modData.menuId === modules[i].menuId
				) {
					registered = true;
					break;
				}
			}
		}
		return registered;
	};
	/**
	 * Checks if we have class already registered
	 * @param    {Object}    modData        module data object
	 * @return    {Boolean}                true if class registered, false if not
	 */
	var checkClassRegistered = function (modData) {
		var registered = false;
		if (classes[modData.modName]) {
			registered = true;
		}
		return registered;
	};
	/**
	 * Shows already initiated module
	 * @param    {Object}    modData            module data object
	 * @param    {Function}    onAfterInit        callback that will be called after module initialisation - for activation used only internally by state restore
	 */
	var activateModule = function (modData, onAfterInit) {
		var module = __self.getModuleByName(modData.name);
		if (module) {
			edi.core.getTabPanel().setActiveTab(module.tab);
		}
		if ('function' == typeof onAfterInit) {
			onAfterInit('function' == typeof module.instance?.onActive ? module.instance.onActive : undefined);
		}
	};
	/**
	 * Removes module info
	 * @param    {Object}    module
	 */
	var removeModule = function (module) {
		var name = module.modName;
		var objClass = classes[name];
		for (var i = 0; i < modules.length; i++) {
			if (module.name === modules[i].name) {
				modules.splice(i, 1);
				break;
			}
		}
		edi.events.module.fireEvent('moduleRemove', module);
		objClass.count--;
		delete modulesByName[module.name];
		if (objClass.isFromBundle === true) {
			return;
		}
		if (!objClass.count) {
			edi.loading.processor.removeScript(objClass.id);
			edi.events.module.fireEvent('classUnLoad', objClass);
			edi.modules[name] = null;
			classes[name] = null;
			delete edi.modules[name];
			delete classes[name];
		}
	};
	/**
	 * Initiates module removal prehandling and control
	 * @param    {Object}    module
	 * @returns {boolean}
	 */
	var prepareModuleClose = function (module) {
		var continueDestroy = true;
		var moduleState = module.isChanged;
		var startClosingModule = function () {
			module.isChanged = false;
			if ('function' == typeof module.onDestroy) {
				continueDestroy = module.onDestroy();
			} else {
				continueDestroy = true;
			}
			if (false !== continueDestroy) {
				module.tab.destroy();
				removeModule(module);
			} else {
				module.isChanged = moduleState;
			}
		};
		if (module.isChanged) {
			continueDestroy = false;
			var closeTitle = module && module.closeTitle ? module.closeTitle : 'confirm.changed.close.title';
			var closeText = module && module.closeText ? module.closeText : 'confirm.changed.close.text';
			edi.core.confirm(closeTitle, closeText, startClosingModule);
		} else {
			startClosingModule();
		}
		return false !== continueDestroy;
	};
	/**
	 * Creates module tab and adds it to tab panel
	 * @param    {Object}    module        module handler module object
	 */
	var initModuleLayout = function (module) {
		setModuleProcessingState(processingStates.LAYOUT_INIT);
		let tabPanel = edi.core.getTabPanel();

		let moduleTitle = edi.i18n.getMessage(module.title);
		module.tab = createTab({
			icon: module.icon || undefined,
			iconCls: module.iconCls || undefined,
			glyph: module.glyph ? module.glyph : module.isEditModule ? edi.constants.ICONS.EDIT : undefined,
			title: moduleTitle,
			tooltip: moduleTitle,
			layout: 'border',
			border: 0,
			cls: 'edi-module-tab',
			bodyCls: 'edi-module-tab-body',
			beforeclose: function () {
				return prepareModuleClose(module);
			},
			activate: function () {
				setActiveModule(module);
			},
			closable: !(tabPanel.items.length === 0 && !edi.constants.MODULES_CLOSE_FIRST)
		});
		module.tab.module = module;

		let currentActiveTab = tabPanel.getActiveTab();
		if (currentActiveTab && (module.isEditModule || module.openNearCurrent)) {
			let currentTabIndex = tabPanel.items.indexMap[currentActiveTab.id];
			tabPanel.insert(currentTabIndex + 1, module.tab);
		} else {
			tabPanel.add(module.tab);
		}

		if (module.tab.glyph || module.tab.icon || module.tab.iconCls) {
			module.tab.tab.addCls('edi-module-tab-button-with-icon');
		} else {
			module.tab.tab.addCls('edi-module-tab-button-without-icon');
		}

		if (module.isMain) {
			module.tab.tab.addCls('edi-module-tab-button-main');
		}

		tabPanel.setActiveTab(module.tab);

		setActiveModule(module); //Force activation processing due to bug EXTJS-8121
		module.tab.setLoading(edi.i18n.getMessage('loading.text'));
		edi.core.logMessage('Initiated tab layout for ' + module.name + ' module');
	};
	/**
	 * Registers new module in the modules scope
	 * @param    {Object}    modData            module data object
	 * @param    {Function}    onAfterInit        callback that will be called after module initialisation
	 * @return    {Object}                    module handler internal module object
	 */
	var registerNewModule = function (modData, onAfterInit) {
		setModuleProcessingState(processingStates.REGISTRATION);
		var module = getModuleInitialConfig(modData, onAfterInit);
		modules.push(module);
		modulesByName[modData.name] = module;
		if (classes[module.modName]) {
			classes[module.modName].count++;
		}
		return module;
	};
	/**
	 * Initiates new module instance from already loaded class
	 * @param    {Object}    modData            module data object
	 * @param    {Function}    onAfterInit        callback that will be called after module initialisation
	 */
	var initiateModule = function (modData, onAfterInit) {
		modData.scriptId = classes[modData.modName].id;
		var module = registerNewModule(modData, onAfterInit);
		if (classes[module.modName].loaded) {
			initModuleLayout(module);
			processLoaded(module);
		} else {
			classes[module.modName].onLoad.push(function () {
				initModuleLayout(module);
				processLoaded(module);
			});
		}
	};
	/**
	 * Adds script tag to header and sets event that will be called on module script load to process module initialization
	 * @param    {Object}    modData            module data object
	 * @param    {Function}    onAfterInit        callback that will be called after module initialisation
	 */
	var processLoading = function (modData, onAfterInit) {
		var module = registerNewModule(modData, onAfterInit),
			className = module.modName;
		initModuleLayout(module);
		var loaded = function () {
			processClassLoaded.call(__self, className);
		};
		setModuleProcessingState(processingStates.LOADING);
		var isFromBundle = true;
		/*if (edi.modules[modData.modName]) {
			isFromBundle = true;
		}*/
		var cfg = edi.loading.processor.loadScript(
			{
				path: getModulePath(module),
				success: loaded,
				failure: loaded
			},
			isFromBundle
		);
		classes[className] = {
			name: className,
			count: 1,
			loaded: false,
			isFromBundle: isFromBundle,
			id: cfg.scriptId,
			onLoad: [
				function () {
					setModuleProcessingState(processingStates.LOADED);
					processLoaded.call(__self, module);
				}
			]
		};
	};
	/**
	 * Initiates module loading procedures in order of initiation
	 * @param    {String}    name    name of the module class
	 */
	var processClassLoaded = function (name) {
		var classObj = classes[name],
			i;
		classObj.loaded = true;
		edi.events.module.fireEvent('classLoad', classObj);
		for (i = 0; i < classObj.onLoad.length; i++) {
			if ('function' == typeof classObj.onLoad[i]) {
				classObj.onLoad[i]();
			}
		}
		classObj.onload = [];
	};
	/**
	 * Callback that processes loaded module and starts module initialization
	 * @param    {Object}    module        module handler module object
	 */
	var processLoaded = function (module) {
		if (edi.modules[module.modName]) {
			try {
				module.instance = new edi.modules[module.modName]();
				//т.к. модули после открытия выполняют асинхронные задачи (получают данные от бэка)
				//перед тем как там появится содержимое, то для отслеживания этого процесса используем Promise
				//который разрешится, когда модуль выполнит initCallback
				//На нем можно вызвать moduleRenderedPromise.then(module => {}) из любого места и сколько угодно раз
				//так же есть просто флаг initiated
				let resolveRenderedPromise;
				module.moduleRenderedPromise = new Promise((resolve) => {
					resolveRenderedPromise = resolve;
				});
				module.initiated = false;
				setModuleProcessingState(processingStates.INIT);
				module.onDestroy = module.instance.init(module, function () {
					'function' == typeof module.instance.onRender ? module.instance.onRender() : null;
					module.initiated = true;
					resolveRenderedPromise(module);
					module.tab.setLoading(false);
					setModuleProcessingState(processingStates.READY);
				});
				if ('function' == typeof module.onAfterInit) {
					module.onAfterInit();
				}
				delete module.onAfterInit;
				edi.events.module.fireEvent('moduleInit', module);
			} catch (e) {
				setModuleProcessingState(processingStates.ERROR);
				if ('function' == typeof module.onAfterInit) {
					module.onAfterInit();
				}
				edi.core.showError(Ext.String.format(edi.i18n.getMessage('error.module.init'), module.name));
				edi.core.handleException('Error during module ' + module.name + ' initialization!');
				edi.core.handleException(e);
				module = __self.removeModule(module);
			}
		} else {
			setModuleProcessingState(processingStates.ERROR);
			if ('function' == typeof module.onAfterInit) {
				module.onAfterInit();
			}
			edi.core.showError(Ext.String.format(edi.i18n.getMessage('error.module.load'), getModulePath(module)));
			edi.core.handleException('Error loading module class - ' + getModulePath(module) + '!');
			module = __self.removeModule(module);
		}
	};
	/**
	 * Generates correct path to the module source
	 * @param    {Object}    module    module handler module object
	 */
	var getModulePath = function (module) {
		return edi.constants.JS_PATH + (module.folder ? module.folder + '/' : 'modules/') + module.modName + '.js';
	};
	/**
	 * Event handler that executed before class loading
	 * @param    {Object}    modData        module initial config object
	 */
	var beforeClassLoad = function (modData) {
		edi.core.logMessage('Called beforeClassLoad for class ' + modData.modName);
	};
	/**
	 * Event handler that executed when class loaded
	 * @param    {Object}    classData        class initial config object
	 */
	var classLoad = function (classData) {
		edi.core.logMessage('Loaded class ' + classData.name);
	};
	/**
	 * Event handler that executed after class unloading
	 * @param    {Object}    classData        class initial config object
	 */
	var classUnLoad = function (classData) {
		edi.core.logMessage('Unloaded class ' + classData.name);
	};
	/**
	 * Event handler that executed after module initialization
	 * @param    {Object}    modData        module initial config object
	 */
	var moduleInit = function (modData) {
		edi.core.logMessage('Initiated module ' + modData.name);
	};
	/**
	 * Event handler that executed after module activation
	 * @param    {Object}    modData        module initial config object
	 */
	var moduleActivated = function (modData) {
		edi.core.logMessage('Registered active module ' + modData.name, 'info');
	};
	/**
	 * Event handler that executed after module removing
	 * @param    {Object}    modData        module initial config object
	 */
	var moduleRemove = function (modData) {
		edi.core.logMessage('Removed module ' + modData.name, 'info');
		if (!modules.length) {
			setActiveModule();
		}
	};
	/**
	 * Sets module processing state to document body
	 * @param state
	 */
	var setModuleProcessingState = function (state) {
		var stateObj = {};
		stateObj[edi.constants.CURRENT_MODULE_STATE] = state;
		Ext.getBody().set(stateObj);
	};
	edi.events.module.on('beforeClassLoad', beforeClassLoad);
	edi.events.module.on('classLoad', classLoad);
	edi.events.module.on('classUnLoad', classUnLoad);
	edi.events.module.on('moduleInit', moduleInit);
	edi.events.module.on('moduleActivated', moduleActivated);
	edi.events.module.on('moduleRemove', moduleRemove);
})();
