/**
 * @author Anatoly Deryshev
 * Shared stores configuration for core
 */
Ext.namespace('edi.stores');
new (function () {
	var onBeforeInit = edi.stores.onBeforeInit;
	edi.stores.onBeforeInit = function () {
		'function' == typeof onBeforeInit ? onBeforeInit() : null;
		Object.assign(edi.stores.data, {
			languages: [
				{
					id: 'RUSSIAN',
					name: 'Русский'
				},
				{
					id: 'ENGLISH',
					name: 'English'
				},
				{
					id: 'JAPAN',
					name: '日本語'
				},
				{
					id: 'ESTONIAN',
					name: 'Eesti'
				},
				{
					id: 'LATVIAN',
					name: 'Latviski'
				},
				{
					id: 'LITHUANIAN',
					name: 'Lietuviškai'
				},
				{
					id: 'UKRAINIAN',
					name: 'Українська'
				}
			]
		});
		Object.assign(edi.stores.dataLoaders, {
			countries: function (callback) {
				var lang =
					edi.utils.getObjectProperty(edi.core.getUserData(), 'language', false) ||
					edi.constants.DEFAULT.LANGUAGE;
				lang = edi.constants.LANGUAGES[lang].CODE;
				var success = function (data) {
					if (data.items && data.items.length) {
						edi.stores.data.countries = [];
						edi.stores.data.countries_full = [];
						edi.stores.data.orgCountries = [];
						for (var i = 0; i < data.items.length; i++) {
							if (data.items[i].iso_2) {
								var item = data.items[i];
								var isoNumber = String(item.iso_number);
								if (isoNumber.length == 2) {
									isoNumber = '0' + isoNumber;
								} else if (isoNumber.length == 1) {
									isoNumber = '00' + isoNumber;
								}
								edi.stores.data.countries_full.push({
									id: item.id,
									name: item['name_' + lang] ? item['name_' + lang] : item['name_en'],
									iso_number: item.iso_number,
									iso_number_3: isoNumber,
									iso_2: item.iso_2,
									iso_3: item.iso_3
								});
								edi.stores.data.countries.push({
									id: data.items[i].iso_2,
									name: data.items[i]['name_' + lang]
										? data.items[i]['name_' + lang]
										: data.items[i]['name_en']
								});
							}
						}

						var name;
						for (i in edi.constants.COUNTRIES) {
							if (edi.constants.COUNTRIES.hasOwnProperty(i)) {
								if (i !== 'OTHER') {
									for (var j = 0; j < edi.stores.data.countries_full.length; j++) {
										if (edi.stores.data.countries_full[j].iso_3 == i) {
											name = edi.stores.data.countries_full[j].name;
											break;
										}
									}
								} else {
									name = edi.i18n.getMessage('country.other');
								}
								edi.stores.data.orgCountries.push({
									id: i,
									name: name
								});
							}
						}
					}
					edi.core.logMessage('Countries loaded', 'info');
				};
				var failure = function () {
					edi.core.handleException('Countries did not loaded properly');
				};
				edi.rest.sendRequest(edi.rest.services.COUNTRIES.GET, 'GET', null, success, failure, callback);
			},
			regions: function (callback) {
				var success = function (data) {
					if (data.regions && data.regions.length) {
						edi.stores.data.regions = data.regions;
					}
					edi.core.logMessage('Regions loaded', 'info');
				};
				var failure = function () {
					edi.core.handleException('Regions did not loaded properly');
				};
				edi.rest.sendRequest(edi.rest.services.REGIONS.GET, 'GET', null, success, failure, callback);
			}
		});
		Object.assign(edi.stores.dataLoaders, {
			certificateActive: function (callback) {
				var success = function (data) {
					if (data.success && data.hasOwnProperty('data')) {
						edi.constants.CERTIFICATE.ONLY_ACTIVE = data.data;
					}
				};
				edi.rest.sendRequest(
					edi.rest.services.USER.SELF.CERTIFICATE.ACTIVE_MODE.GET,
					'GET',
					null,
					success,
					undefined,
					callback
				);
			}
		});
		edi.stores.createInitStoreMethods([
			{
				methodName: 'initYesOrNoStore',
				storeName: 'yesOrNoStore',
				constructor: function (onload) {
					var data = [
						{
							id: true,
							name: edi.i18n.getMessage('answer.yes')
						},
						{
							id: false,
							name: edi.i18n.getMessage('answer.no')
						}
					];
					return edi.stores.createInlineStore(data, 'SIMPLE', onload, {
						addEmptyRecord: true
					});
				}
			},
			{
				methodName: 'initRegionsStore',
				storeName: 'regionsStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.regions, 'REGION', onload, {
						addEmptyRecord: true
					});
				},
				autoInit: true
			},
			{
				methodName: 'initLanguagesStore',
				storeName: 'languagesStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.languages, 'SIMPLE', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initCountryStore',
				storeName: 'countryStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.countries, 'SIMPLE', onload, {
						addEmptyRecord: true
					});
				},
				autoInit: true
			},
			{
				methodName: 'initOrgCountryStore',
				storeName: 'orgCountryStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.orgCountries, 'SIMPLE', onload, {
						addEmptyRecord: true
					});
				},
				autoInit: true
			},
			{
				methodName: 'initOrgCountryStoreISO2',
				storeName: 'orgCountryStoreISO2',
				constructor: function (onload) {
					var orgCountriesISO2 = edi.stores.data.orgCountries.map(function (country) {
						var fullCountries = edi.stores.data.countries_full.filter(function (fullCountry) {
							return fullCountry.iso_3 === country.id;
						});
						if (fullCountries.length > 0) {
							return {
								id: fullCountries[0].iso_2,
								name: fullCountries[0].name
							};
						} else {
							return {
								id: 'OTHER',
								name: edi.i18n.getMessage('country.other')
							};
						}
					});

					return edi.stores.createInlineStore(orgCountriesISO2, 'SIMPLE', onload, {
						addEmptyRecord: true
					});
				},
				autoInit: true
			},
			{
				methodName: 'initOrgCountryStoreWithoutEmpty',
				storeName: 'orgCountryStoreWithoutEmpty',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.orgCountries, 'SIMPLE', onload);
				},
				autoInit: true
			},
			{
				methodName: 'initCountryFullStore',
				storeName: 'countryFullStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.stores.data.countries_full, 'COUNTRY', onload, {
						addEmptyRecord: true
					});
				},
				autoInit: true
			},
			{
				methodName: 'initRelatedOrganizationsFirstEmptyStore',
				storeName: 'relatedOrganizationsFirstEmptyStore',
				constructor: function (onload) {
					return edi.stores.createInlineStore(edi.relations.getRelations(), 'ORGANIZATION', onload, {
						addEmptyRecord: true
					});
				}
			}
		]);
	};
})();
