import { createProxyConfig, createSimpleStore, createStore } from '@Components/storeComponents';

/**
 * @author Anatoly Deryshev
 * data stores
 */
Ext.namespace('edi.stores');
edi.stores = new (function () {
	var __self = this;
	var stores = {};
	this.data = {};
	this.dataLoaders = {};
	this.autoInitStores = [];

	/**
	 * Loads static data and creates auto stores
	 * @param    {Function}    callback     callback that will be called on initialisation finish
	 */
	this.init = function (callback) {
		loadStaticData(function () {
			initAutoStores(callback);
		});
	};
	/**
	 * Sets stores configs before initialisation
	 */
	this.beforeInit = function () {
		if ('function' == typeof __self.onBeforeInit) {
			__self.onBeforeInit();
		}
	};

	/**
	 * Creates empty store record with "Not selected" value
	 * @return {{id: string, name}}
	 */
	this.getEmptyRecord = function () {
		return {
			id: '',
			name: edi.i18n.getMessage('form.combo.not.selected')
		};
	};
	/**
	 * Creates store for direct data loading
	 * @param    {Array}       items        collection of items to load
	 * @param    {String}      [modelName]    name of the model
	 * @param    {Function}    [onload]       event to fire when data loaded
	 * @param    {Object}      [config]       store config properties
	 * @returns {*}
	 */
	this.createInlineStore = function (items, modelName, onload, config) {
		items = Ext.clone(items || []);
		config = config ? config : {};
		if (config && config.addObjEmptyRecord && 'object' === typeof config.addObjEmptyRecord) {
			delete config.addEmptyRecord;
			items = [config.addObjEmptyRecord].concat(items);
		}
		var defaults = {
			data: config.addEmptyRecord ? [__self.getEmptyRecord()].concat(items) : items,
			model: edi.models.getModel(modelName ? modelName : 'SIMPLE')
		};

		if ('function' == typeof onload) {
			defaults.listeners = {};
			defaults.listeners.load = onload;
		}
		Ext.applyIf(config, defaults);
		return createSimpleStore(config);
	};
	/**
	 * create memory store
	 *  @param      {Array}     items
	 *  @param      {String}    modelName
	 *  @param      {Boolean}   addEmptyRecord
	 *  @param      {Function}  onload
	 *  @returns    {object}
	 */
	this.createMemoryStore = function (items, modelName, addEmptyRecord, onload) {
		items = Ext.clone(items || []);
		var listeners = {};
		if ('function' == typeof onload) {
			listeners.load = onload;
		}

		//Для type: memory, все record phantom: false, а поэтому св-ву комбо понимает, что выбрано
		//значение "не выбрано". По умолчанию proxy type: "ajax", для record с id: '' phantom: true
		return createSimpleStore({
			//proxy: createProxyConfig({
			//	//type: "memory",
			//	//data: {
			//	//	items: addEmptyRecord
			//	//		? [__self.getEmptyRecord()].concat(items)
			//	//		: items
			//	//}
			//}),
			data: addEmptyRecord ? [__self.getEmptyRecord()].concat(items) : items,
			model: edi.models.getModel(modelName),
			listeners: listeners
		});
	};

	this.addDataToStoreWithTranslate = function (store, data) {
		for (var key in data) {
			store.add({
				id: key,
				name: edi.i18n.getMessage(data[key])
			});
		}
		return store;
	};

	/**
	 *  Create simple inline store with ids and names, using nameCreator fn to create name from id
	 * @param     {Array}         ids                 An ids array
	 * @param     {Function}      nameCreatorFn       A function used to create name based on id (manually)
	 * @param     {Boolean}       [addEmptyRecord]      True to add "Not selected" record to store
	 * @param     {Function}      [onload]              Callback to call when data loaded
	 * @returns   {Object}
	 */
	this.createSimpleInlineStore = function (ids, nameCreatorFn, addEmptyRecord, onload) {
		var emptyRecord = addEmptyRecord ? __self.getEmptyRecord() : null;
		return __self.createDisplayingStore(ids, 'SIMPLE', 'name', nameCreatorFn, emptyRecord, onload);
	};

	/**
	 *  Create inline store with id and display field, using displayFieldCreator fn to create displayField from id
	 * @param     {Array}         ids                    An ids array
	 * @param     {String}        model                  Store model name
	 * @param     {String}        displayField           Display field name
	 * @param     {Function}      displayFieldCreator    A function used to create displayField based on id (manually)
	 * @param     {Boolean}       emptyRecord            True to add "Not selected" record to store
	 * @param     {Function}      onload                 Callback to call when data loaded
	 * @returns   {Object}
	 */
	this.createDisplayingStore = function (ids, model, displayField, displayFieldCreator, emptyRecord, onload) {
		var data = emptyRecord ? [emptyRecord] : [],
			i,
			item;
		if (!displayField) {
			displayField = 'name';
		}
		var fieldFn =
			'function' == typeof displayFieldCreator
				? displayFieldCreator
				: function (id) {
						return id;
				  };

		for (i = 0; i < ids.length; i++) {
			item = {
				id: ids[i]
			};

			item[displayField] = fieldFn(ids[i]);
			data.push(item);
		}

		return __self.createInlineStore(data, model, onload);
	};
	/**
	 * Create remote store
	 * @param url
	 * @param modelName
	 * @param onload
	 * @param addEmpty
	 * @param proxyConfig
	 * @returns {Object}
	 */
	this.createRemoteStore = function (url, modelName, onload, addEmpty, proxyConfig, convertRecords) {
		proxyConfig = proxyConfig || {};
		var listeners = {};
		//if (addEmpty) {
		//	var afterLoad = onload;
		//	onload = function(store, records, successful, eOpts) {
		//		__self.addEmptyRecord(store);
		//		"function" == typeof afterLoad ? afterLoad(store, records, successful, eOpts) : null;
		//	};
		//}
		//if ("function" == typeof onload) {
		//	listeners.load = onload;
		//}
		listeners.load = function (self, records, successful, eOpts) {
			let recs = addEmpty
				? [
						{
							id: '',
							name: edi.i18n.getMessage('form.combo.not.selected')
						}
				  ]
				: [];
			let newRecords = convertRecords
				? records.map((r) => ({
						id: r.data,
						name: r.data
				  }))
				: records;
			recs = recs.concat(newRecords);
			self.loadData(recs);
			if ('function' == typeof onload) {
				onload(self, recs, successful, eOpts);
			}
		};
		var proxyConfigProp = Ext.merge(
			{
				type: 'ajax',
				url: url
			},
			proxyConfig
		);
		return createStore({
			proxy: createProxyConfig(proxyConfigProp),
			model: edi.models.getModel(modelName),
			listeners: listeners
		});
	};

	/**
	 * Returns array of *items* elements taken by *ids*
	 * @param      items       Source items array to search in
	 * @param      ids         Ids array
	 * @return     {Array}
	 */
	this.getStoreItemsByIds = function (items, ids) {
		/*		var newItems = [];
				for (var i = 0; i < items.length; i++) {
					if (ids.indexOf(items[i].id) > -1) {
						newItems.push(items[i]);
					}
				}
				return newItems;*/
		return (items || []).filter((it) => {
			return (ids || []).some((p) => String(it.id) === String(p));
		});
	};
	/**
	 * adds empty record to store "not selected" and sort store by id
	 * @param    {Object}    store
	 * @param    {String}    modelName
	 * @param    {String}    propertyName
	 * @param    {String}    sortProperty
	 */
	this.addEmptyRecord = function (store, modelName, propertyName, sortProperty) {
		propertyName = propertyName ? propertyName : 'name';
		sortProperty = sortProperty ? sortProperty : 'id';
		var data = {};
		data[propertyName] = edi.i18n.getMessage('form.combo.not.selected');
		var empty = edi.models.createInstance(modelName ? modelName : 'SIMPLE', data);
		store.sort(sortProperty);
		store.insert(0, empty);
	};
	/**
	 * Creates store init methods
	 * @param    {Array}    params    collection of store init config objects
	 */
	this.createInitStoreMethods = function (params) {
		var i,
			conf,
			processConf = function (conf) {
				__self[conf.methodName] = function (onload) {
					!stores[conf.storeName] ? (stores[conf.storeName] = conf.constructor(onload)) : null;
					return stores[conf.storeName];
				};
				if (conf.autoInit) {
					__self.autoInitStores.push(conf.name);
				}
			};
		if (params && params.length) {
			for (i = 0; i < params.length; i++) {
				conf = params[i];
				if (conf.constructor && conf.methodName && conf.storeName) {
					processConf(conf);
				}
			}
		}
	};
	/**
	 * Loads external static data for stores
	 * @param    {Function}    callback    function that will be called after finish of loading all registered store static data sources
	 */
	var loadStaticData = function (callback) {
		var getLoader = function (method, callback) {
			return function () {
				method(callback);
			};
		};
		var finishLoad = function () {
			'function' == typeof callback ? callback() : null;
		};
		var loader = null;
		for (var i in __self.dataLoaders) {
			if (__self.dataLoaders.hasOwnProperty(i) && 'function' == typeof __self.dataLoaders[i]) {
				loader = getLoader(__self.dataLoaders[i], 'function' == typeof loader ? loader : finishLoad);
			}
		}
		'function' == typeof loader ? loader() : finishLoad();
	};
	/**
	 * Creates chain of stores auto initialisation
	 * @param callback
	 */
	var initAutoStores = function (callback) {
		var getInit = function (method, callback) {
			return function () {
				method(callback);
			};
		};
		var finishInit = function () {
			'function' == typeof callback ? callback() : null;
		};
		var init = null;
		for (var i = 0; i < __self.autoInitStores.length; i++) {
			if ('function' == typeof __self[__self.autoInitStores[i]]) {
				init = getInit(__self[__self.autoInitStores[i]], 'function' == typeof init ? init : finishInit);
			}
		}
		'function' == typeof init ? init() : finishInit();
	};
})();
