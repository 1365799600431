import { createTwoColumnsLayout, getTwoColumnsFilterOptions } from '@Components/miscComponents';
import {
	createCheckbox,
	createCombo,
	createField,
	createNumberField,
	createSearchTypeSelectionFieldForGrid,
	createTextField
} from '@Components/fields';
import { createProxyConfig } from '@Components/storeComponents';
import { createAutocomplete } from './specialComponents/fields';
import { createPanel } from '@Components/panels';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';

/**
 * Created by Дарья on 28.02.2017.
 */
Ext.namespace('edi.filters');
Object.assign(edi.filters.config, {
	fileStorage: {
		createFormItems: function (documentTypes, type, contentTypes, isLikeBothFilter) {
			var processItems = [];
			var orgComboCont, partnerComboCont;
			for (let i in edi.constants.FILE_STORAGE.PROCESS_STATE) {
				if (edi.constants.FILE_STORAGE.PROCESS_STATE.hasOwnProperty(i)) {
					processItems.push(edi.constants.FILE_STORAGE.PROCESS_STATE[i]);
				}
			}
			var searchDatesNotLimited =
				type === 'admin' && edi.permissions.hasPermission('ADMIN_FILE_STORAGE_UNLIMITED_PERIOD');
			searchDatesNotLimited =
				searchDatesNotLimited ||
				(type !== 'admin' && edi.permissions.hasPermission('CLIENT_FILE_STORAGE_UNLIMITED_PERIOD'));
			var validateCondition = function (fieldName) {
				return function (combo, field) {
					return combo.getValue() === fieldName + 'LikeRight' && field.getValue().length > 16
						? edi.i18n.getMessage('filter.form.search.valifate.like.right', [16])
						: true;
				};
			};
			var validator = function () {
				let createFields, createHasValues, modifyFields, modifyHasValues;
				if (
					formItemsMap?.[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE] &&
					formItemsMap?.[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
				) {
					createFields = formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE].getFields();
					modifyFields = formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE].getFields();
					createHasValues = createFields.dateTo.getValue() || createFields.dateFrom.getValue();
					modifyHasValues = modifyFields.dateTo.getValue() || modifyFields.dateFrom.getValue();
					if ((createHasValues && modifyHasValues) || (!createHasValues && !modifyHasValues)) {
						createFields.dateTo.allowBlank = false;
						createFields.dateFrom.allowBlank = false;
						modifyFields.dateTo.allowBlank = false;
						modifyFields.dateFrom.allowBlank = false;
					} else if (createHasValues) {
						createFields.dateTo.allowBlank = false;
						createFields.dateFrom.allowBlank = false;
						modifyFields.dateTo.allowBlank = true;
						modifyFields.dateFrom.allowBlank = true;
					} else if (modifyHasValues) {
						createFields.dateTo.allowBlank = true;
						createFields.dateFrom.allowBlank = true;
						modifyFields.dateTo.allowBlank = false;
						modifyFields.dateFrom.allowBlank = false;
					}
				}
				return true;
			};
			var updateOrgPartner = function (cmp, newValue) {
				const otherCombo = cmp.name === 'byOrgId' ? partnerComboCont : orgComboCont;
				const otherStore = otherCombo.getStore();
				const otherPrevValue = otherCombo.getValue();
				if (newValue) {
					otherStore.setProxy(
						createProxyConfig({
							type: 'ajax',
							url: edi.utils.formatString(edi.rest.services.ADMIN.PARTNER.FILTER.GET, {
								orgId: newValue
							})
						})
					);
				} else {
					otherStore.setProxy(
						createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.ADMIN.ORGS.FILTER.GET, {
								gridName: 'all'
							}),
							enablePaging: false
						})
					);
				}
				otherStore.load({
					limit: 100000000,
					start: 0,
					callback: function (records) {
						if (!records || records.length === 0) {
							otherStore.removeAll();
							otherCombo.setValue('');
						} else {
							if (otherPrevValue) {
								otherCombo.setValue(otherPrevValue);
							}
						}
						// setReadOnly почему-то приводит к пропаданию полей выбора дат
						otherCombo.setDisabled(!records || records.length === 0);
					}
				});
			};
			var orgConfig = {
				columnWidth: 1,
				showQtips: false,
				tpl: Ext.create(
					'Ext.XTemplate',
					'<tpl for=".">',
					'<tpl if="name">{name}</tpl>',
					'<tpl if="iln"> - {iln}</tpl>',
					'</tpl>'
				),
				displayTpl: Ext.create(
					'Ext.XTemplate',
					'<tpl for=".">',
					'<tpl if="name">{name}</tpl>',
					'<tpl if="iln"> - {iln}</tpl>',
					'</tpl>'
				),
				listeners: {
					change: function (cmp, newValue, oldValue) {
						if (null === newValue || cmp.getStore().find('id', newValue) !== -1) {
							updateOrgPartner(cmp, newValue, oldValue);
						}
					}
				},
				disabled: !edi.permissions.hasPermission('ADMIN_ORGANIZATIONS_READ')
			};

			let formItemsMap = {
				docName: createSearchTypeSelectionFieldForGrid(
					{
						fieldLabel: edi.i18n.getMessage('column.doc.name')
					},
					['exact', 'LikeRight', isLikeBothFilter ? 'LikeBoth' : undefined],
					undefined,
					!isLikeBothFilter ? validateCondition('docName') : undefined
				),
				byOrgId:
					type === 'admin'
						? (orgComboCont = createAutocomplete(
								Ext.merge(
									{
										name: 'byOrgId',
										fieldLabel: edi.i18n.getMessage('column.org')
									},
									orgConfig
								),
								!edi.permissions.hasPermission('ADMIN_ORGANIZATIONS_READ')
									? {
											proxy: createProxyConfig({
												type: 'memory',
												data: []
											})
									  }
									: {
											proxy: createProxyConfig({
												type: 'ajax',
												url: edi.utils.compileURL(edi.rest.services.ADMIN.ORGS.FILTER.GET, {
													gridName: 'all'
												})
											}),
											model: edi.models.getModel('ORGANIZATIONS')
									  }
						  ))
						: null,
				toOrgId:
					type === 'admin'
						? (partnerComboCont = createAutocomplete(
								Ext.merge(
									{
										name: 'toOrgId',
										fieldLabel: edi.i18n.getMessage('documents.form.partner')
									},
									orgConfig
								),
								!edi.permissions.hasPermission('ADMIN_ORGANIZATIONS_READ')
									? {
											proxy: createProxyConfig({
												type: 'memory',
												data: []
											})
									  }
									: {
											proxy: createProxyConfig({
												type: 'ajax',
												url: edi.utils.compileURL(edi.rest.services.ADMIN.ORGS.FILTER.GET, {
													gridName: 'all'
												})
											}),
											model: edi.models.getModel('ORGANIZATIONS')
									  }
						  ))
						: null,
				errorMessage:
					type === 'admin'
						? createSearchTypeSelectionFieldForGrid(
								{
									fieldLabel: edi.i18n.getMessage('column.error.message'),
									fieldConf: {
										name: 'errorMessage'
									}
								},
								[isLikeBothFilter ? 'LikeBoth' : undefined, 'LikeRight'],
								undefined,
								!isLikeBothFilter ? validateCondition('errorMessage') : undefined
						  )
						: null,
				docType: createCombo({
					fieldLabel: edi.i18n.getMessage('column.doc.type'),
					store: edi.stores.createInlineStore(documentTypes, 'SIMPLE', undefined, {
						addEmptyRecord: true
					}),
					name: 'docType',
					allowBlank: true,
					forceSelection: false,
					anyMatch: true
				}),
				contentType: createCombo({
					store: edi.stores.createInlineStore(contentTypes, 'SIMPLE', undefined, {
						addEmptyRecord: true
					}),
					name: 'contentType',
					forceSelection: false,
					allowBlank: true,
					anyMatch: true,
					fieldLabel: edi.i18n.getMessage('column.content.type')
				}),
				byUserId:
					type === 'admin'
						? createAutocomplete(
								{
									name: 'byUserId',
									fieldLabel: edi.i18n.getMessage('column.user'),
									disabled: !edi.permissions.hasPermission('ADMIN_USERS_READ')
								},
								!edi.permissions.hasPermission('ADMIN_USERS_READ')
									? {
											proxy: createProxyConfig({
												type: 'memory',
												data: []
											})
									  }
									: {
											proxy: createProxyConfig({
												type: 'ajax',
												url: edi.utils.compileURL(edi.rest.services.ADMIN.USERS.FILTER.GET, {
													gridName: 'user'
												})
											}),
											model: edi.models.getModel('USER_AUTOCOMPLETE'),
											sorters: {
												property: 'lastName',
												direction: 'ASC'
											}
									  }
						  )
						: null,
				processed: createCombo({
					name: 'processed',
					fieldLabel: edi.i18n.getMessage('column.processed'),
					store: edi.stores.createSimpleInlineStore(
						processItems,
						function (id) {
							let key =
								window.buildVersion.isAdmin !== true && id === 'DRAFT'
									? 'file.processed.user.'
									: 'file.processed.';
							return edi.i18n.getMessage(key + id);
						},
						true
					),
					forceSelection: true
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: searchDatesNotLimited
						? edi.utils.getUserDefaultSearchPeriod(edi.constants.DEFAULT.FILTER.PERIODS.TODAY)
						: edi.constants.DEFAULT.FILTER.PERIODS.LAST7DAYS,
					maxSearchPeriodDays: searchDatesNotLimited ? null : 31,
					validator,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
					period: searchDatesNotLimited
						? edi.utils.getUserDefaultSearchPeriod(edi.constants.DEFAULT.FILTER.PERIODS.TODAY)
						: null,
					validator,
					maxSearchPeriodDays: searchDatesNotLimited ? null : 31,
					hideAdditionalButtons: ['quarter'],
					allowBlank: true,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify') + ': ',
							fieldLabel:
								type === 'admin'
									? edi.i18n.getMessage('document.changed.from')
									: edi.i18n.getMessage('document.date.modify.from')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};

			let items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [
							[6, 6],
							[6, 6],
							[6, 6],
							[6, 6],
							[6, 6]
						]
					},
					items: [
						formItemsMap.docName,
						formItemsMap.docType,
						formItemsMap.byOrgId,
						formItemsMap.contentType,
						formItemsMap.toOrgId,
						formItemsMap.byUserId,
						formItemsMap.errorMessage,
						formItemsMap.processed,
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
					]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.errorMessage) {
				values.errorMessage = Ext.String.trim(values.errorMessage);
			}
			if (values.errorMessage) {
				values.gridName = 'filter_by_error_text';
			}
			values = edi.filters.config.generic.convertFieldName(values, 'docName');
			values = edi.filters.config.generic.convertFieldName(values, 'errorMessage');
			return values;
		}
	},
	users: {
		createFormItems: function (type, options) {
			let formItemsMap = {
				firstName: createTextField({
					fieldLabel: edi.i18n.getMessage('first.name'),
					name: 'firstName'
				}),
				lastName: createTextField({
					fieldLabel: edi.i18n.getMessage('last.name'),
					name: 'lastName'
				}),
				login: createTextField({
					fieldLabel: edi.i18n.getMessage('column.crd.login'),
					name: 'login'
				}),
				eMail: createTextField({
					fieldLabel: edi.i18n.getMessage('general.email'),
					name: 'eMail'
				}),
				id: createTextField({
					fieldLabel: edi.i18n.getMessage('column.user.id'),
					maskRe: edi.constants.VALIDATORS.INT,
					maxLength: 19,
					name: 'id',
					validator: function (val) {
						return (val.length <= 19 && edi.constants.VALIDATORS.INT.test(val)) || val === ''
							? true
							: edi.i18n.getMessage('invalid.field.value');
					}
				}),
				status:
					type === 'registration'
						? createCombo({
								fieldLabel: edi.i18n.getMessage('column.status'),
								store: edi.stores.createSimpleInlineStore(
									[
										edi.constants.STATE.DRAFT,
										edi.constants.STATE.SENT,
										edi.constants.STATE.READ,
										edi.constants.STATE.ADMIN_REVIEW
									],
									function (id) {
										return edi.i18n.getTr(['STATE', id]);
									},
									true
								),
								name: 'status'
						  })
						: null,
				withoutEkbId: options?.addWithoutEkbId
					? createCheckbox({
							text: edi.i18n.getMessage('column.without.ekb.id'),
							fieldLabel: edi.i18n.getMessage('column.without.ekb.id'),
							getChipValue(fld) {
								return (fld.getValue() && fld.fieldLabel) || '';
							},
							name: 'withoutEkbId',
							inputValue: true
					  })
					: null,
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					hideFromPanel: true,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
					hideFromPanel: true,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify') + ': ',
							fieldLabel: edi.i18n.getMessage('document.modify.date.from'),
							name: 'modifyDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'modifyDateTo'
						}
					}
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [[6, 6], [6, 6], [6, 6], 6]
					},
					items: [
						formItemsMap.firstName,
						formItemsMap.eMail,
						formItemsMap.lastName,
						formItemsMap.id,
						formItemsMap.login,
						formItemsMap.status,
						formItemsMap.withoutEkbId,
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
					]
				})
			];

			return {
				formItemsMap,
				items
			};
		}
	},
	organizations: {
		createFormItems: function (props) {
			let params = String(props);
			let checkParams = function (name) {
				return params.indexOf(name) !== -1;
			};

			let formItemsMap = {
				name: createTextField({
					fieldLabel: edi.i18n.getMessage('organization'),
					name: 'name'
				}),
				iln: createNumberField({
					fieldLabel: edi.i18n.getMessage('company.gln.short'),
					name: 'iln'
				}),
				inn: createNumberField({
					fieldLabel: edi.i18n.getMessage('company.inn.short'),
					name: 'inn'
				}),
				groupId: checkParams('groupId')
					? createCombo({
							name: 'groupId',
							fieldLabel: edi.i18n.getMessage('organizations.group.details'),
							store: edi.stores.createRemoteStore(
								edi.utils.compileURL(edi.rest.services.ADMIN.ORGANIZATION_GROUPS.FILTER.GET, {
									type: 'PARTNERS_GROUP'
								}),
								'SIMPLE',
								null,
								true
							)
					  })
					: null,
				fnsId: !checkParams('withFnsId')
					? null
					: createTextField({
							fieldLabel: edi.i18n.getMessage('company.fns.id'),
							name: 'fnsId'
					  }),
				regNr: checkParams('withoutRegNr')
					? null
					: createTextField({
							fieldLabel: edi.i18n.getMessage('column.org.regNr'),
							name: 'regNr',
							maskRe: /[\da-zA-Z]/,
							regex: /^[\da-zA-Z]+$/
					  }),
				id: checkParams('withoutId')
					? null
					: createTextField({
							fieldLabel: edi.i18n.getMessage('column.permission.group.id'),
							maskRe: edi.constants.VALIDATORS.INT,
							name: 'id',
							validator: function (val) {
								return edi.constants.VALIDATORS.INT.test(val) || val === ''
									? true
									: edi.i18n.getMessage('invalid.field.value');
							}
					  }),
				okvedName: !checkParams('withOkvedName')
					? null
					: createTextField({
							fieldLabel: edi.i18n.getMessage('column.org.okvedName'),
							name: 'okvedName'
					  })
			};

			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [
							[6, 6],
							[6, 6],
							[6, 6],
							[6, 6]
						]
					},
					items: [
						formItemsMap.name,
						formItemsMap.iln,
						formItemsMap.inn,
						formItemsMap.groupId,
						formItemsMap.fnsId,
						formItemsMap.regNr,
						formItemsMap.id,
						formItemsMap.okvedName
					]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			var orgDataParams = [],
				result = {};
			var headerOrgModel = Ext.ClassManager.get(edi.models.getModel('ORGANIZATION_HEADER'));
			var headerOrgProps = headerOrgModel.getFields().map(function (field) {
				return field.name;
			});
			headerOrgProps.push('groupId');
			for (var key in values) {
				if (values.hasOwnProperty(key) && values[key]) {
					if (headerOrgProps.some((it) => it === key)) {
						result[key] = values[key];
					} else {
						var addParamObject = {};
						addParamObject[key] = values[key];
						orgDataParams.push(addParamObject);
					}
				}
			}
			if (orgDataParams.length) {
				result.orgDataParams = Ext.encode(orgDataParams);
			}
			return result;
		}
	},
	reading_from_ehd: {
		createFormItems: function () {
			const formItemsMap = {
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.changed'),
					period: edi.utils.getUserDefaultSearchPeriod('month'),
					fieldsConfig: {
						common: {
							invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
							format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.changed') + ': ',
							fieldLabel: edi.i18n.getMessage('document.changed.from')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [[6]]
					},
					items: [formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values['dateFrom']) {
				values.dateFrom = edi.utils.getSearchTime(
					values['dateFrom'],
					edi.constants.DATE_FORMAT.SERVER,
					0,
					edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S
				);
			}
			if (values['dateTo']) {
				values.dateTo = edi.utils.getSearchTime(
					values['dateTo'],
					edi.constants.DATE_FORMAT.SERVER,
					59000,
					edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S
				);
			}
			return values;
		}
	},
	generic: {
		createFormItems: function () {
			const formItemsMap = {
				name: createTextField({
					fieldLabel: edi.i18n.getMessage('column.name'),
					name: 'name'
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [formItemsMap.name]
				})
			];

			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			return values;
		},
		/**
		 * Convert dateFrom/dateTo fields in values using getSearchTime function
		 * @param      values      Values JSON
		 * @returns    {*}          JSON with converted dateFrom/dateTo fields
		 */
		convertSearchDateTimeArgs: function (values) {
			if (values.dateFrom) {
				values.dateFrom = edi.utils.getSearchTime(values.dateFrom, edi.constants.DATE_FORMAT.SERVER);
			}
			if (values.dateTo) {
				values.dateTo = edi.utils.getSearchTime(
					values.dateTo,
					edi.constants.DATE_FORMAT.SERVER,
					edi.constants.SEARCH_DAY_IN_MS
				);
			}
			return values;
		},
		/**
		 * Converts field name value to support different types of search by this field
		 * @param    {Object}    values       form field values object
		 * @param    {Object}    fieldName    name of the field
		 * @returns {*}
		 */
		convertFieldName: function (values, fieldName) {
			var fieldVal = fieldName + 'Val';
			if (values[fieldVal]) {
				if (values[fieldName] && fieldName != values[fieldVal]) {
					values[values[fieldVal]] = values[fieldName];
					delete values[fieldName];
				}

				delete values[fieldVal];
			}
			return values;
		}
	}
});
